import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import usePermissions from '../hooks/usePermissions';
import { USER_PERMISSIONS, GLOBAL_PERMISSIONS } from '../constants/permissions';

interface ProtectedRouteProps {
  redirectPath?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
  redirectPath = '/splash'
}) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { hasAnyPermission } = usePermissions();
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  // Get needed permissions based on route
  const requiredPermissions = React.useMemo(() => {
    if (location.pathname.startsWith('/admin/users/add')) {
      return [USER_PERMISSIONS.CREATE_USER];
    } else if (location.pathname.startsWith('/admin/users')) {
      return [USER_PERMISSIONS.VIEW_USERS, USER_PERMISSIONS.EDIT_USER];
    } else if (location.pathname.startsWith('/admin/invite')) {
      return [USER_PERMISSIONS.SEND_INVITATIONS];
    } else if (location.pathname.startsWith('/admin/activities')) {
      return [USER_PERMISSIONS.VIEW_ACTIVITY_LOGS];
    }
    return [GLOBAL_PERMISSIONS.ACCESS_ADMIN_PANEL]; // Default for any admin route
  }, [location.pathname]);

  // While checking authentication status, show loading
  if (isLoading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        Loading...
      </div>
    );
  }
  
  // If not authenticated, redirect to splash page
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  // For admin routes, check if user has admin role or required permissions
  if (isAdminRoute && user?.role !== 'admin' && !hasAnyPermission(requiredPermissions)) {
    return <Navigate to="/" replace />;
  }

  // If authenticated and has required role/permissions, render the children routes
  return <Outlet />;
};

export default ProtectedRoute; 