import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import usePermissions from '../hooks/usePermissions';
import { USER_PERMISSIONS, GLOBAL_PERMISSIONS, PAGE_PERMISSIONS } from '../constants/permissions';
import { usePage } from '../context/PageContext';
import DeletePageModal from './DeletePageModal';
import '../App.css';
import LockIcon from './ComponentSystem/LockIcon';
import { sharedPageApi } from '../services/api';

// Custom hook to safely use PageContext
const useSafePageContext = () => {
  const location = useLocation();
  const isDashboardPage = location.pathname === '/' || location.pathname === '/dashboard';
  
  // Always call usePage, but catch any errors
  try {
    const pageContext = usePage();
    return {
      isAvailable: true,
      ...pageContext
    };
  } catch (error) {
    console.log('PageContext not available in SideMenu:', error);
    return {
      isAvailable: false,
      pages: [],
      currentPage: null,
      isLoading: false,
      error: null,
      addPage: () => {},
      setCurrentPage: () => {},
      updatePage: () => {},
      deletePage: () => {},
      addComponent: () => {},
      updateComponent: () => {},
      removeComponent: () => {}
    };
  }
};

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose }) => {
  const { user, isAuthenticated } = useAuth();
  const { hasAnyPermission } = usePermissions();
  const [newPageName, setNewPageName] = useState('');
  const [showComponentLibrary, setShowComponentLibrary] = useState(false);
  const location = useLocation();
  
  // Add states for collapsible sections
  const [adminSectionCollapsed, setAdminSectionCollapsed] = useState(true);
  const [pagesSectionCollapsed, setPagesSectionCollapsed] = useState(false);
  
  // States for delete page modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState<{ id: string, name: string } | null>(null);
  
  // States for saving pages to shared library
  const [savingPage, setSavingPage] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  
  // Use our safe wrapper for PageContext
  const { isAvailable, pages, currentPage, addPage, setCurrentPage, deletePage, isLoading, updatePage } = useSafePageContext();
  
  // Check if we're on the dashboard page
  const isDashboardPage = location.pathname === '/' || location.pathname === '/dashboard';

  // Check if the user has any permission that would require admin menu access
  const hasAdminAccess = 
    user?.role === 'admin' || 
    hasAnyPermission([
      USER_PERMISSIONS.VIEW_USERS,
      USER_PERMISSIONS.CREATE_USER,
      USER_PERMISSIONS.EDIT_USER, 
      USER_PERMISSIONS.MANAGE_PERMISSIONS,
      USER_PERMISSIONS.SEND_INVITATIONS,
      USER_PERMISSIONS.VIEW_ACTIVITY_LOGS,
      GLOBAL_PERMISSIONS.ACCESS_ADMIN_PANEL
    ]);

  // Check permissions
  const { hasPermission } = usePermissions();
  const canSavePage = hasPermission(PAGE_PERMISSIONS.SAVE_PAGE);

  // States for page refresh indicator
  const [pagesRefreshed, setPagesRefreshed] = useState(false);

  // Listen for page refresh events to provide visual feedback
  useEffect(() => {
    const handlePagesRefreshed = () => {
      // Show a brief visual feedback that pages were refreshed
      setPagesRefreshed(true);
      
      // Reset the state after a short delay
      setTimeout(() => {
        setPagesRefreshed(false);
      }, 2000);
    };
    
    // Add event listener
    document.addEventListener('refresh-pages', handlePagesRefreshed);
    
    // Clean up
    return () => {
      document.removeEventListener('refresh-pages', handlePagesRefreshed);
    };
  }, []);

  // Handle adding a new page
  const handleAddPage = () => {
    if (newPageName.trim() && isAvailable) {
      addPage(newPageName.trim());
      setNewPageName('');
    }
  };

  // Handle page selection
  const handlePageSelect = (pageId: string) => {
    if (isAvailable) {
      setCurrentPage(pageId);
      // Close the menu on mobile after selecting a page
      if (window.innerWidth < 768) {
        onClose();
      }
    }
  };

  // Handle opening delete page modal
  const handleOpenDeleteModal = (e: React.MouseEvent, pageId: string, pageName: string) => {
    e.stopPropagation(); // Prevent the page from being selected
    setPageToDelete({ id: pageId, name: pageName });
    setDeleteModalOpen(true);
  };

  // Handle closing delete page modal
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setPageToDelete(null);
  };

  // Handle confirming page deletion
  const handleConfirmDelete = () => {
    if (pageToDelete && isAvailable) {
      deletePage(pageToDelete.id);
      handleCloseDeleteModal();
    }
  };

  // Toggle component library visibility
  const toggleComponentLibrary = () => {
    // Always set to true when toggling from the side menu
    // This ensures we're opening the library
    const shouldShow = true;
    setShowComponentLibrary(shouldShow);
    
    // Emit a custom event that PageView can listen for
    const event = new CustomEvent('toggle-component-library', { 
      detail: { show: shouldShow } 
    });
    document.dispatchEvent(event);
    
    // Close the side menu
    onClose();
  };

  // Toggle section collapse state
  const toggleAdminSection = () => {
    setAdminSectionCollapsed(!adminSectionCollapsed);
  };

  const togglePagesSection = () => {
    setPagesSectionCollapsed(!pagesSectionCollapsed);
  };

  // Handle saving the current page to shared pages
  const handleSaveCurrentPage = async () => {
    if (!currentPage) return;
    
    // Prompt user for page name
    const pageName = window.prompt(
      'Enter a name for the shared page:',
      currentPage.name
    );
    
    // If user cancels or enters empty name, abort
    if (!pageName || pageName.trim() === '') return;
    
    try {
      setSavingPage(true);
      setSaveError(null);
      setSaveSuccess(null);
      
      // Create a shared page from the current page
      const response = await sharedPageApi.createSharedPage({
        name: pageName.trim(), // Use custom name
        description: currentPage.description || '',
        pageConfig: {
          ...currentPage,
          name: pageName.trim() // Also update name in the config
        },
        isPublic: true, // Default to public
        tags: [] // No tags by default
      });
      
      setSaveSuccess(`Page "${pageName.trim()}" was successfully saved to the shared library`);
      
      // Hide success message after 3 seconds
      setTimeout(() => {
        setSaveSuccess(null);
      }, 3000);
      
    } catch (error) {
      console.error('Error saving page to shared library:', error);
      setSaveError('Failed to save page. Please try again.');
      
      // Hide error message after 5 seconds
      setTimeout(() => {
        setSaveError(null);
      }, 5000);
    } finally {
      setSavingPage(false);
    }
  };

  // Function to render the Pages section
  const renderPagesSection = () => {
    return (
      <div className="side-menu-section" style={{ position: 'relative', zIndex: 999999 }}>
        <div className="section-header" onClick={togglePagesSection}>
          <h4>Pages {pagesRefreshed && <span className="pages-refreshed-indicator">●</span>}</h4>
          <span className={`collapse-icon ${pagesSectionCollapsed ? 'collapsed' : ''}`}>
            {pagesSectionCollapsed ? '▶' : '▼'}
          </span>
        </div>
        
        {!pagesSectionCollapsed && (
          <div className={`section-content ${pagesRefreshed ? 'pages-refreshed' : ''}`}>
            {/* Status messages */}
            {saveSuccess && (
              <div className="status-message success">
                {saveSuccess}
              </div>
            )}
            
            {saveError && (
              <div className="status-message error">
                {saveError}
              </div>
            )}
          
            {/* Save current page button - only show if user has permission */}
            {canSavePage && currentPage && (
              <button 
                className="save-page-button"
                onClick={handleSaveCurrentPage}
                disabled={savingPage}
                style={{
                  display: 'block',
                  width: '100%',
                  padding: '8px 12px',
                  marginBottom: '10px',
                  backgroundColor: '#e4c87e',
                  color: '#333',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: savingPage ? 'default' : 'pointer',
                  opacity: savingPage ? 0.7 : 1
                }}
              >
                {savingPage ? 'Saving...' : 'Save Page to Library'}
              </button>
            )}
            
            {isLoading ? (
              <div className="loading-indicator">Loading pages...</div>
            ) : pages.length > 0 ? (
              <div className="page-icons">
                {pages.map(page => (
                  <div 
                    key={page.id}
                    className={`page-icon ${currentPage?.id === page.id ? 'active' : ''} ${page.isLocked ? 'locked' : ''}`}
                    onClick={() => handlePageSelect(page.id)}
                  >
                    <span className="page-icon-symbol">📄</span>
                    <span className="page-icon-name">{page.name}</span>
                    <div className="page-icon-controls">
                      <LockIcon
                        isLocked={!!page.isLocked}
                        onClick={(e) => handleTogglePageLock(e, page.id, !!page.isLocked)}
                        className="page-lock-icon"
                        title={page.isLocked ? 'Unlock page' : 'Lock page'}
                      />
                      <button 
                        className="delete-page-btn"
                        onClick={(e) => handleOpenDeleteModal(e, page.id, page.name)}
                        title="Delete page"
                        aria-label="Delete page"
                      >
                        🗑️
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-pages-message">No pages available</div>
            )}
            
            <div className="add-page-form">
              <input
                type="text"
                value={newPageName}
                onChange={(e) => setNewPageName(e.target.value)}
                placeholder="New page name"
                className="add-page-input"
              />
              <button 
                onClick={handleAddPage}
                disabled={!newPageName.trim()}
                className="add-page-button"
              >
                Add
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Handle toggling page lock
  const handleTogglePageLock = (e: React.MouseEvent, pageId: string, isCurrentlyLocked: boolean) => {
    e.stopPropagation(); // Prevent the page from being selected
    
    if (isAvailable) {
      // Update the page's lock state
      updatePage(pageId, {
        isLocked: !isCurrentlyLocked
      });
    }
  };

  return (
    <>
      <div className={`side-menu ${isOpen ? 'open' : ''}`} style={{ zIndex: 999999 }}>
        <div className="side-menu-header">
          <button className="close-menu-btn" onClick={onClose}>×</button>
          <h3>Menu</h3>
        </div>

        <div className="side-menu-content" style={{ position: 'relative', zIndex: 999999 }}>
            <>
              {/* Main Navigation Links */}
              <div className="side-menu-section">
                <h4>Navigation</h4>
                <ul className="nav-links">
                  <li>
                    <Link to="/dashboard" onClick={onClose}>Dashboard</Link>
                  </li>
                </ul>
              </div>

              {/* Pages section */}
              {renderPagesSection()}

              {/* Only show Components section if we're on the dashboard */}
              {isDashboardPage && (
                <div className="side-menu-section" style={{ position: 'relative', zIndex: 999999 }}>
                  <h4>Components</h4>
                  <button 
                    className="component-library-button"
                    onClick={toggleComponentLibrary}
                  >
                    Component Library
                  </button>
                </div>
              )}

              {hasAdminAccess && (
                <div className="side-menu-section" style={{ position: 'relative', zIndex: 999999 }}>
                  <div className="section-header" onClick={toggleAdminSection}>
                    <h4>Admin</h4>
                    <span className={`collapse-icon ${adminSectionCollapsed ? 'collapsed' : ''}`}>
                      {adminSectionCollapsed ? '▶' : '▼'}
                    </span>
                  </div>
                  
                  {!adminSectionCollapsed && (
                    <div className="section-content">
                      {(user?.role === 'admin' || hasAnyPermission([USER_PERMISSIONS.VIEW_USERS, USER_PERMISSIONS.EDIT_USER])) && (
                        <Link to="/admin/users" className="side-menu-link" onClick={onClose}>
                          Users
                        </Link>
                      )}
                      
                      {(user?.role === 'admin' || hasAnyPermission([USER_PERMISSIONS.SEND_INVITATIONS])) && (
                        <Link to="/admin/invite" className="side-menu-link" onClick={onClose}>
                          Invite User
                        </Link>
                      )}
                      
                      {(user?.role === 'admin' || hasAnyPermission([USER_PERMISSIONS.VIEW_ACTIVITY_LOGS])) && (
                        <Link to="/admin/activities" className="side-menu-link" onClick={onClose}>
                          Activity Logs
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              )}

              {/* If not on dashboard, show a link to go to dashboard */}
              {!isDashboardPage && (
                <div className="side-menu-section" style={{ position: 'relative', zIndex: 999999 }}>
                  <h4>Dashboard</h4>
                  <Link to="/" className="side-menu-link" onClick={onClose}>
                    Go to Dashboard
                  </Link>
                </div>
              )}
            </>
        </div>
      </div>

      {/* Delete Page Modal */}
      {pageToDelete && (
        <DeletePageModal 
          isOpen={deleteModalOpen}
          pageName={pageToDelete.name}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default SideMenu; 