import React, { useState } from 'react';
import { ConfigPanelProps } from '../../ComponentRegistry';
import { defaultSettings } from './NotificationMessagesComponent';
import { notificationApi } from '../../../../services/api';

const NotificationMessagesConfig: React.FC<ConfigPanelProps> = ({ config, onConfigChange, onClose }) => {
  // Create a local copy of settings for editing
  const [settings, setSettings] = useState({ ...defaultSettings, ...config.settings });
  
  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target as HTMLInputElement;
    
    // Handle different input types
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setSettings(prev => ({
        ...prev,
        [name]: checked
      }));
    } else if (type === 'number') {
      setSettings(prev => ({
        ...prev,
        [name]: parseInt(value) || 0
      }));
    } else {
      setSettings(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };
  
  // Handle column toggle
  const handleColumnToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      columns: {
        ...prev.columns,
        [name]: checked
      }
    }));
  };
  
  // Save settings
  const handleSave = () => {
    // Update component config with new settings
    onConfigChange({
      ...config,
      title: settings.title,
      settings
    });
    
    // Close config panel
    onClose();
  };
  
  // Reset to defaults
  const handleReset = () => {
    setSettings(defaultSettings);
  };
  
  // Handle close button
  const handleClose = () => {
    // Just close without saving
    onClose();
  };
  
  return (
    <div className="config-panel">
      <div className="config-panel-header">
        <h2 className="config-panel-title">Notification Messages Settings</h2>
        <button className="config-panel-close" onClick={handleClose}>×</button>
      </div>
      
      <div className="config-panel-content">
        {/* General Settings */}
        <div className="config-panel-section">
          <h3 className="config-panel-section-title">General Settings</h3>
          
          <div className="form-group">
            <label className="form-label" htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={settings.title}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="refreshInterval">
              Auto-refresh Interval (seconds, 0 for manual refresh)
            </label>
            <input
              type="number"
              id="refreshInterval"
              name="refreshInterval"
              value={settings.refreshInterval}
              onChange={handleInputChange}
              min="0"
              className="form-control"
            />
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="limit">Items Per Page</label>
            <input
              type="number"
              id="limit"
              name="limit"
              value={settings.limit}
              onChange={handleInputChange}
              min="1"
              max="100"
              className="form-control"
            />
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="messagePreviewLength">Message Preview Length</label>
            <input
              type="number"
              id="messagePreviewLength"
              name="messagePreviewLength"
              value={settings.messagePreviewLength}
              onChange={handleInputChange}
              min="10"
              max="500"
              className="form-control"
            />
          </div>
          
          <div className="form-group">
            <label className="form-check">
              <input
                type="checkbox"
                id="showFilters"
                name="showFilters"
                checked={settings.showFilters}
                onChange={handleInputChange}
              />
              <span className="form-check-label">Show Filters</span>
            </label>
          </div>
        </div>
        
        {/* Column Settings */}
        <div className="config-panel-section">
          <h3 className="config-panel-section-title">Visible Columns</h3>
          
          <div className="form-group">
            <label className="form-check">
              <input
                type="checkbox"
                name="sent_at"
                checked={settings.columns.sent_at}
                onChange={handleColumnToggle}
              />
              <span className="form-check-label">Date/Time</span>
            </label>
          </div>
          
          <div className="form-group">
            <label className="form-check">
              <input
                type="checkbox"
                name="channel"
                checked={settings.columns.channel}
                onChange={handleColumnToggle}
              />
              <span className="form-check-label">Channel</span>
            </label>
          </div>
          
          <div className="form-group">
            <label className="form-check">
              <input
                type="checkbox"
                name="recipient"
                checked={settings.columns.recipient}
                onChange={handleColumnToggle}
              />
              <span className="form-check-label">Recipient</span>
            </label>
          </div>
          
          <div className="form-group">
            <label className="form-check">
              <input
                type="checkbox"
                name="subject"
                checked={settings.columns.subject}
                onChange={handleColumnToggle}
              />
              <span className="form-check-label">Subject</span>
            </label>
          </div>
          
          <div className="form-group">
            <label className="form-check">
              <input
                type="checkbox"
                name="message"
                checked={settings.columns.message}
                onChange={handleColumnToggle}
              />
              <span className="form-check-label">Message</span>
            </label>
          </div>
        </div>
        
        {/* Default Filters */}
        <div className="config-panel-section">
          <h3 className="config-panel-section-title">Default Filters</h3>
          
          <div className="form-group">
            <label className="form-label" htmlFor="defaultChannel">Default Channel</label>
            <select
              id="defaultChannel"
              name="filters.channel"
              value={settings.filters.channel}
              onChange={handleInputChange}
              className="form-control"
            >
              <option value="">All Channels</option>
              {notificationApi.getNotificationChannels().map(channel => (
                <option key={channel.value} value={channel.value}>
                  {channel.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        {/* Actions */}
        <div className="config-panel-actions">
          <button className="btn btn-primary" onClick={handleSave}>Save Changes</button>
          <button className="btn btn-secondary" onClick={handleReset}>Reset to Defaults</button>
          <button className="btn btn-secondary" onClick={handleClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default NotificationMessagesConfig; 