import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { usePage } from '../../context/PageContext';
import { ComponentRegistry, createComponentConfig } from './ComponentRegistry';
import './ComponentStyles.css';
import ComponentWrapper from './ComponentWrapper';
import { ComponentConfig, Position } from './types';
import './ComponentDebugStyles.css'; // Import debug styles
import usePermissions from '../../hooks/usePermissions';
import SharedPagesLibrary from './SharedPagesLibrary';

const PageView: React.FC = () => {
  const { 
    currentPage,
    addComponent,
    updateComponent,
    removeComponent
  } = usePage();
  
  const [showLibrary, setShowLibrary] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const pageContainerRef = useRef<HTMLDivElement>(null);
  const [activeLibraryTab, setActiveLibraryTab] = useState<'components' | 'pages'>('components');
  
  // Grid layout helper for positioning components
  const calculateGridPosition = useCallback(
    (
      index: number, 
      containerWidth: number, 
      containerHeight: number,
      componentWidth: number = 400,
      componentHeight: number = 300
    ) => {
      // Calculate how many components can fit in a row
      const componentsPerRow = Math.max(1, Math.floor(containerWidth / (componentWidth + 20)));
      
      // Calculate row and column for this component
      const row = Math.floor(index / componentsPerRow);
      const col = index % componentsPerRow;
      
      // Calculate position with spacing
      const x = col * (componentWidth + 20) + 20;
      const y = row * (componentHeight + 20) + 20;
      
      return { x, y };
    },
    []
  );
  
  // Check if components overlap significantly
  const checkComponentsOverlap = useCallback((components: any[]) => {
    for (let i = 0; i < components.length; i++) {
      for (let j = i + 1; j < components.length; j++) {
        const a = components[i].position;
        const b = components[j].position;
        
        // Calculate overlap area
        const xOverlap = Math.max(0, Math.min(a.x + a.width, b.x + b.width) - Math.max(a.x, b.x));
        const yOverlap = Math.max(0, Math.min(a.y + a.height, b.y + b.height) - Math.max(a.y, b.y));
        const overlapArea = xOverlap * yOverlap;
        
        // Calculate smaller component area
        const areaA = a.width * a.height;
        const areaB = b.width * b.height;
        const smallerArea = Math.min(areaA, areaB);
        
        // If overlap is more than 30% of the smaller component, consider it significant
        if (overlapArea > 0.3 * smallerArea) {
          return true;
        }
      }
    }
    return false;
  }, []);
  
  // Reposition all components in a grid layout
  const repositionComponents = useCallback((containerWidth: number, containerHeight: number) => {
    if (!currentPage) return;
    
    // Don't reposition if the page is locked
    if (currentPage.isLocked) {
      // Dispatch a notification event
      const event = new CustomEvent('page-lock-notification', {
        detail: {
          message: 'Cannot reorganize components on a locked page',
          type: 'warning'
        }
      });
      document.dispatchEvent(event);
      return;
    }
    
    // Calculate appropriate component size based on container
    const componentWidth = Math.min(400, Math.max(200, containerWidth / 2 - 40));
    const componentHeight = Math.min(300, Math.max(150, containerHeight / 2 - 40));
    
    // Update each component's position
    currentPage.components.forEach((component, index) => {
      const { x, y } = calculateGridPosition(
        index, 
        containerWidth, 
        containerHeight,
        componentWidth,
        componentHeight
      );
      
      // Update component position and size
      updateComponent(currentPage.id, component.id, {
        position: {
          ...component.position,
          x,
          y,
          width: componentWidth,
          height: componentHeight
        }
      });
    });
  }, [currentPage, updateComponent, calculateGridPosition]);
  
  // Check if we're in mobile view
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    
    // Initial check
    checkMobileView();
    
    // Add event listener for window resize
    window.addEventListener('resize', checkMobileView);
    
    // Clean up
    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);
  
  // Trigger a resize event when the component mounts to ensure proper scaling
  useEffect(() => {
    // Dispatch a custom resize event to ensure all components scale properly on initial load
    const dispatchCustomResize = () => {
      // Use a small delay to ensure DOM is fully rendered
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
      
      // Fire another one with a longer delay to catch potential maximize events
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    };
    
    // Call immediately on mount
    dispatchCustomResize();
    
    // Also call when current page changes
    if (currentPage) {
      dispatchCustomResize();
    }
    
    // Add a handler for browser maximize events specifically
    const handleMaximizeEvent = () => {
      // Check if window dimensions match screen dimensions (indicating maximize)
      const isMaximized = 
        window.innerWidth >= window.screen.availWidth - 20 &&
        window.innerHeight >= window.screen.availHeight - 60;
      
      if (isMaximized) {
        console.log('Detected browser maximize in PageView - firing resize events');
        // Fire multiple resize events with different delays
        setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
        setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
        setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
      }
    };
    
    // Listen for resize events that might be maximize events
    window.addEventListener('resize', handleMaximizeEvent);
    
    return () => {
      window.removeEventListener('resize', handleMaximizeEvent);
    };
  }, [currentPage]);
  
  // Reposition components when container size changes
  useEffect(() => {
    if (!currentPage || !pageContainerRef.current || isMobileView) return;
    
    const handleContainerResize = () => {
      const containerRect = pageContainerRef.current?.getBoundingClientRect();
      if (!containerRect || containerRect.width === 0) return;
      
      // Only reposition if container is significantly smaller than before
      // and components might be overlapping
      const containerWidth = containerRect.width;
      const containerHeight = containerRect.height;
      
      // Check if any components are outside the container bounds
      const componentsOutOfBounds = currentPage.components.some(comp => {
        return (
          comp.position.x + comp.position.width > containerWidth ||
          comp.position.y + comp.position.height > containerHeight
        );
      });
      
      // Check if components are overlapping too much
      const hasOverlappingComponents = checkComponentsOverlap(currentPage.components);
      
      // If components are out of bounds or overlapping, reposition them
      if (componentsOutOfBounds || hasOverlappingComponents) {
        repositionComponents(containerWidth, containerHeight);
      }
    };
    
    // Add event listener for window resize
    window.addEventListener('resize', handleContainerResize);
    
    // Clean up
    return () => {
      window.removeEventListener('resize', handleContainerResize);
    };
  }, [currentPage, isMobileView, repositionComponents, checkComponentsOverlap]);
  
  // Listen for manual reorganize event
  useEffect(() => {
    const handleReorganize = () => {
      if (!currentPage || !pageContainerRef.current || isMobileView) return;
      
      const containerRect = pageContainerRef.current.getBoundingClientRect();
      if (containerRect && containerRect.width > 0) {
        repositionComponents(containerRect.width, containerRect.height);
      }
    };
    
    // Add event listener for reorganize event
    document.addEventListener('reorganize-components', handleReorganize as EventListener);
    
    // Clean up
    return () => {
      document.removeEventListener('reorganize-components', handleReorganize as EventListener);
    };
  }, [currentPage, isMobileView, repositionComponents]);
  
  // Listen for custom event from SideMenu
  useEffect(() => {
    const handleToggleLibrary = (event: CustomEvent) => {
      setShowLibrary(event.detail.show);
    };
    
    // Add event listener
    document.addEventListener('toggle-component-library', handleToggleLibrary as EventListener);
    
    // Clean up
    return () => {
      document.removeEventListener('toggle-component-library', handleToggleLibrary as EventListener);
    };
  }, []);
  
  // Add permissions hook
  const { hasAnyPermission } = usePermissions();
  
  // Handle adding a component to the current page
  const handleAddComponent = (componentType: string) => {
    if (!currentPage) return;
    
    // Don't add components if the page is locked
    if (currentPage.isLocked) {
      // Dispatch a notification event
      const event = new CustomEvent('page-lock-notification', {
        detail: {
          message: 'Cannot add components to a locked page',
          type: 'warning'
        }
      });
      document.dispatchEvent(event);
      
      // Close the component library
      setShowLibrary(false);
      return;
    }
    
    const componentDef = ComponentRegistry.get(componentType);
    if (!componentDef) return;
    
    // Create a new component config
    const newComponent = createComponentConfig(componentType);
    
    // For mobile view, position doesn't matter as much since components will stack
    if (isMobileView) {
      newComponent.position = {
        ...newComponent.position,
        x: 0,
        y: 0,
        width: pageContainerRef.current?.clientWidth || 300,
        height: 300
      };
    } else {
      // For desktop view, position the component in a grid layout
      const containerWidth = pageContainerRef.current?.clientWidth || window.innerWidth;
      const containerHeight = pageContainerRef.current?.clientHeight || window.innerHeight;
      
      // Calculate appropriate component size based on container
      const componentWidth = Math.min(400, Math.max(200, containerWidth / 2 - 40));
      const componentHeight = Math.min(300, Math.max(150, containerHeight / 2 - 40));
      
      // Calculate position based on grid layout
      const { x, y } = calculateGridPosition(
        currentPage.components.length,
        containerWidth,
        containerHeight,
        componentWidth,
        componentHeight
      );
      
      newComponent.position = {
        ...newComponent.position,
        x,
        y,
        width: componentWidth,
        height: componentHeight,
        zIndex: Date.now() // Ensure new component is on top
      };
    }
    
    // Add to current page
    addComponent(currentPage.id, newComponent);
  };
  
  // Handle component config change
  const handleComponentConfigChange = (componentId: string, newConfig: any) => {
    if (!currentPage) return;
    
    // If page is locked, only allow toggling the component's own lock state
    // This ensures users can still lock/unlock components on a locked page
    if (currentPage.isLocked) {
      const component = currentPage.components.find(comp => comp.id === componentId);
      if (!component) return;
      
      // If only the isLocked property is changing, allow it
      if (Object.keys(newConfig).length === 1 && 'isLocked' in newConfig) {
        updateComponent(currentPage.id, componentId, { isLocked: newConfig.isLocked });
        return;
      }
      
      // If trying to change isLocked along with other properties
      if ('isLocked' in newConfig && component.isLocked !== newConfig.isLocked) {
        // Only update the isLocked property
        updateComponent(currentPage.id, componentId, { isLocked: newConfig.isLocked });
        
        // Show notification about other changes being blocked
        const event = new CustomEvent('page-lock-notification', {
          detail: {
            message: 'Only lock status can be changed on a locked page',
            type: 'info'
          }
        });
        document.dispatchEvent(event);
        return;
      }
      
      // For all other config changes, block and notify
      const event = new CustomEvent('page-lock-notification', {
        detail: {
          message: 'Cannot change component settings on a locked page',
          type: 'warning'
        }
      });
      document.dispatchEvent(event);
      return;
    }
    
    updateComponent(currentPage.id, componentId, newConfig);
  };
  
  // Handle component removal
  const handleRemoveComponent = (componentId: string) => {
    if (!currentPage) return;
    
    // Prevent removing components if the page is locked
    if (currentPage.isLocked) {
      // Dispatch a notification event
      const event = new CustomEvent('page-lock-notification', {
        detail: {
          message: 'Cannot remove components from a locked page',
          type: 'warning'
        }
      });
      document.dispatchEvent(event);
      return;
    }
    
    removeComponent(currentPage.id, componentId);
  };
  
  // Get all available components
  const availableComponents = ComponentRegistry.getAll();
  
  // Filter components by permission
  const filteredComponents = availableComponents.filter(component => {
    // If no permissions required, show the component
    if (!component.requiredPermissions || component.requiredPermissions.length === 0) {
      return true;
    }
    
    // Check if user has any of the required permissions
    return hasAnyPermission(component.requiredPermissions);
  });
  
  // Debug - log available components
  console.log('Available components:', availableComponents.map(c => ({ 
    type: c.type, 
    name: c.name,
    permissions: c.requiredPermissions 
  })));
  
  console.log('Filtered components:', filteredComponents.map(c => c.name));
  
  // Debug state
  const [showSystemDebug, setShowSystemDebug] = useState(false);
  const [systemDebugInfo, setSystemDebugInfo] = useState({
    lastUpdateTime: 0,
    updateCount: 0,
    updateFrequency: 0,
    componentCount: currentPage?.components.length || 0,
    activeComponent: null as string | null,
    lastOperation: '',
    performanceMetrics: {
      repositionTime: 0,
      renderTime: 0
    }
  });
  
  // Update debug info when components change
  useEffect(() => {
    const now = performance.now();
    const timeSinceLastUpdate = now - systemDebugInfo.lastUpdateTime;
    
    setSystemDebugInfo(prev => ({
      ...prev,
      lastUpdateTime: now,
      updateCount: prev.updateCount + 1,
      updateFrequency: prev.lastUpdateTime > 0 ? timeSinceLastUpdate : 0,
      componentCount: currentPage?.components.length || 0
    }));
  }, [currentPage]);
  
  // Performance monitoring for reposition function
  const monitoredRepositionComponents = useCallback((
    draggedId: string,
    newPosition: { x: number, y: number }
  ) => {
    const startTime = performance.now();
    
    // Call the actual repositionComponents function
    repositionComponents(newPosition.x, newPosition.y);
    
    const endTime = performance.now();
    
    setSystemDebugInfo(prev => ({
      ...prev,
      lastOperation: 'reposition',
      activeComponent: draggedId,
      performanceMetrics: {
        ...prev.performanceMetrics,
        repositionTime: endTime - startTime
      }
    }));
  }, [repositionComponents]);
  
  // Update component position
  const updateComponentPosition = useCallback((componentId: string, position: { x: number, y: number }) => {
    if (!currentPage) return;
    
    // Prevent moving components if the page is locked
    if (currentPage.isLocked) {
      // Dispatch a notification event
      const event = new CustomEvent('page-lock-notification', {
        detail: {
          message: 'Cannot move components on a locked page',
          type: 'warning'
        }
      });
      document.dispatchEvent(event);
      return;
    }
    
    // Find the component
    const component = currentPage.components.find(comp => comp.id === componentId);
    if (!component) return;
    
    // Update the component position
    updateComponent(currentPage.id, componentId, {
      position: {
        ...component.position,
        x: position.x,
        y: position.y
      }
    });
  }, [currentPage, updateComponent]);
  
  // Use the monitored version in handleDrag
  const handleDrag = useCallback((id: string, position: { x: number, y: number }) => {
    if (!currentPage) return;
    
    // Update debug info
    setSystemDebugInfo(prev => ({
      ...prev,
      lastOperation: 'drag',
      activeComponent: id
    }));
    
    // Update the component position
    updateComponentPosition(id, position);
  }, [currentPage, updateComponentPosition, setSystemDebugInfo]);
  
  // Toggle system debug panel
  const toggleSystemDebug = useCallback(() => {
    setShowSystemDebug(prev => !prev);
  }, []);
  
  return (
    <div className="page-view">
      {/* Component library modal */}
      {showLibrary && (
        <>
          {/* Overlay */}
          <div className="component-library-overlay" onClick={() => setShowLibrary(false)}></div>
          
          {/* Modal */}
          <div className="component-library-modal">
            <div className="component-library-modal-header">
              <h3>Component Library</h3>
              <button className="component-library-close-btn" onClick={() => setShowLibrary(false)}>×</button>
            </div>
            
            {/* Tabs for Components and Shared Pages */}
            <div className="component-library-tabs">
              <button 
                className={`component-library-tab ${activeLibraryTab === 'components' ? 'active' : ''}`}
                onClick={() => setActiveLibraryTab('components')}
              >
                Components
              </button>
              <button 
                className={`component-library-tab ${activeLibraryTab === 'pages' ? 'active' : ''}`}
                onClick={() => setActiveLibraryTab('pages')}
              >
                Shared Pages
              </button>
            </div>
            
            {/* Components Tab Content */}
            {activeLibraryTab === 'components' && (
              <div className="component-library-items">
                {filteredComponents.map(component => (
                  <div 
                    key={component.type}
                    className="component-library-item"
                    onClick={() => {
                      handleAddComponent(component.type);
                      setShowLibrary(false); // Close after adding
                    }}
                  >
                    <div className="component-library-item-icon">{component.icon}</div>
                    <div className="component-library-item-name">{component.name}</div>
                  </div>
                ))}
              </div>
            )}
            
            {/* Shared Pages Tab Content */}
            {activeLibraryTab === 'pages' && (
              <SharedPagesLibrary 
                onClose={() => setShowLibrary(false)}
                onPageSelected={(page) => {
                  // Page is already copied to user's profile at this point
                  // Just close the library
                  setShowLibrary(false);
                }}
              />
            )}
          </div>
        </>
      )}
      
      {/* Page container */}
      <div className="page-container" ref={pageContainerRef}>
        {currentPage?.components.map(componentConfig => {
          const ComponentType = ComponentRegistry.get(componentConfig.type)?.component;
          
          if (!ComponentType) return null;
          
          return (
            <ComponentType
              key={componentConfig.id}
              config={componentConfig}
              onConfigChange={(newConfig) => handleComponentConfigChange(componentConfig.id, newConfig)}
              onRemove={() => handleRemoveComponent(componentConfig.id)}
            />
          );
        })}
      </div>
      
      {/* System-wide debug button */}
      {process.env.NODE_ENV !== 'production' && (
        <button 
          className="system-debug-button"
          onClick={toggleSystemDebug}
          style={{
            position: 'fixed',
            bottom: '10px',
            right: '10px',
            zIndex: 10000,
            background: '#333',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            padding: '5px 10px',
            cursor: 'pointer'
          }}
        >
          {showSystemDebug ? 'Hide System Debug' : 'Show System Debug'}
        </button>
      )}
      
      {/* System-wide debug panel */}
      {showSystemDebug && (
        <div className="system-debug-panel">
          <h3>System Debug Information</h3>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Components:</span>
            <span className="debug-panel-value">{systemDebugInfo.componentCount}</span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Updates:</span>
            <span className="debug-panel-value">{systemDebugInfo.updateCount}</span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Update Frequency:</span>
            <span className="debug-panel-value">
              {systemDebugInfo.updateFrequency > 0 ? 
                `${Math.round(systemDebugInfo.updateFrequency)}ms` : 'N/A'}
            </span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Last Operation:</span>
            <span className="debug-panel-value">{systemDebugInfo.lastOperation}</span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Active Component:</span>
            <span className="debug-panel-value">
              {systemDebugInfo.activeComponent ? 
                systemDebugInfo.activeComponent.substring(0, 8) + '...' : 'None'}
            </span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Reposition Time:</span>
            <span className="debug-panel-value">
              {systemDebugInfo.performanceMetrics.repositionTime.toFixed(2)}ms
            </span>
          </div>
          <h4>Component List</h4>
          <div className="component-list">
            {currentPage?.components.map(comp => (
              <div key={comp.id} className="component-list-item">
                <span className="component-list-id">{comp.id.substring(0, 6)}...</span>
                <span className="component-list-type">{comp.type}</span>
                <span className="component-list-pos">
                  ({Math.round(comp.position.x)},{Math.round(comp.position.y)})
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PageView; 