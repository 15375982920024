import { useAuth } from '../context/AuthContext';

/**
 * Hook for checking user permissions
 * @returns Permission check functions
 */
const usePermissions = () => {
  const { user } = useAuth();

  /**
   * Check if the current user has a specific permission
   * @param permission - The permission to check
   * @returns True if user has permission
   */
  const hasPermission = (permission: string): boolean => {
    // Admin role always has all permissions
    if (user?.role === 'admin') return true;
    
    // Check for specific permission
    return user?.permissions?.includes(permission) || false;
  };

  /**
   * Check if the current user has any of the permissions
   * @param permissions - Array of permissions to check
   * @returns True if user has any permission
   */
  const hasAnyPermission = (permissions: string[]): boolean => {
    // Admin role always has all permissions
    if (user?.role === 'admin') return true;
    
    // If no permissions to check, return false
    if (!permissions.length) return false;
    
    // Check for any permission
    return permissions.some(p => user?.permissions?.includes(p)) || false;
  };

  /**
   * Check if the current user has all of the permissions
   * @param permissions - Array of permissions to check
   * @returns True if user has all permissions
   */
  const hasAllPermissions = (permissions: string[]): boolean => {
    // Admin role always has all permissions
    if (user?.role === 'admin') return true;
    
    // If no permissions to check, return true
    if (!permissions.length) return true;
    
    // Check for all permissions
    return permissions.every(p => user?.permissions?.includes(p)) || false;
  };

  return {
    hasPermission,
    hasAnyPermission,
    hasAllPermissions
  };
};

export default usePermissions; 