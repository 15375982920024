import axios from 'axios';

// Determine if we're in production based on hostname
// Replace this with proper env var loading when environment variables are working
const isProduction = typeof window !== 'undefined' && 
  (window.location.hostname !== 'localhost' && 
   !window.location.hostname.includes('127.0.0.1'));

export const API_URL = isProduction ? 'https://api.georgectrl.com' : 'http://localhost:5200';
// export const API_URL = process.env.REACT_APP_API_URL || 'https://api.georgectrl.com';
export const API_PREFIX = '/api/v1';

// Log API configuration on startup
console.log('🌐 API Configuration:');
console.log('🌐 Environment:', isProduction ? 'Production' : 'Development');
console.log('🌐 Configured API_URL:', API_URL);
console.log('🌐 API_PREFIX:', API_PREFIX);
console.log('🌐 Full API base path:', API_URL + API_PREFIX);

// Create axios instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// User API methods
export const userApi = {
  // Register a new user
  register: async (userData: {
    username: string;
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
  }) => {
    return api.post(`${API_PREFIX}/users/register`, userData);
  },

  // Create a new user (admin only)
  createUser: async (userData: {
    username: string;
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
    role?: string;
  }) => {
    return api.post(`${API_PREFIX}/users`, userData);
  },

  // Login user
  login: async (credentials: { email: string; password: string }) => {
    return api.post(`${API_PREFIX}/users/login`, credentials);
  },

  // Logout user
  logout: async () => {
    console.log('API: Calling logout endpoint');
    try {
      const response = await api.post(`${API_PREFIX}/users/logout`);
      console.log('API: Logout response received:', response.status);
      return response;
    } catch (error) {
      console.error('API: Error in logout call:', error);
      throw error;
    }
  },

  // Get current user profile
  getCurrentUser: async () => {
    return api.get(`${API_PREFIX}/users/me`);
  },

  // Update user
  updateUser: async (userId: string, userData: {
    firstName?: string;
    lastName?: string;
    email?: string;
    username?: string;
    role?: string;
  }) => {
    return api.put(`${API_PREFIX}/users/${userId}`, userData);
  },

  // Get user by ID
  getUserById: async (userId: string) => {
    return api.get(`${API_PREFIX}/users/${userId}`);
  },

  // Get all users (admin only)
  getAllUsers: async () => {
    return api.get(`${API_PREFIX}/users`);
  },
  
  // Request password reset
  forgotPassword: async (email: string) => {
    return api.post(`${API_PREFIX}/password/forgot`, { email });
  },
  
  // Verify reset token
  verifyResetToken: async (token: string) => {
    return api.get(`${API_PREFIX}/password/verify/${token}`);
  },
  
  // Reset password
  resetPassword: async (token: string, password: string) => {
    return api.post(`${API_PREFIX}/password/reset`, { token, password });
  },

  // Update user permissions
  updateUserPermissions: async (userId: string, permissions: string[]) => {
    const response = await api.put(`${API_PREFIX}/users/${userId}/permissions`, { permissions });
    return response.data;
  },
};

// Invitation API methods
export const invitationApi = {
  // Send a new invitation
  sendInvitation: async (invitationData: { email: string; role?: string }) => {
    return api.post(`${API_PREFIX}/invitations`, invitationData);
  },

  // Get all invitations (admin only)
  getAllInvitations: async () => {
    return api.get(`${API_PREFIX}/invitations`);
  },

  // Resend invitation
  resendInvitation: async (invitationId: string) => {
    return api.post(`${API_PREFIX}/invitations/${invitationId}/resend`);
  },

  // Cancel invitation
  cancelInvitation: async (invitationId: string) => {
    return api.delete(`${API_PREFIX}/invitations/${invitationId}`);
  },
};

// Activity Log API methods
export const activityApi = {
  // Get all activities with pagination and filtering
  getActivities: async (params?: {
    page?: number;
    limit?: number;
    action?: string;
    userId?: string;
    targetUserId?: string;
    startDate?: string;
    endDate?: string;
  }) => {
    return api.get(`${API_PREFIX}/activities`, { params });
  },

  // Get activities for a specific user
  getUserActivities: async (userId: string, params?: {
    page?: number;
    limit?: number;
    action?: string;
  }) => {
    return api.get(`${API_PREFIX}/activities/user/${userId}`, { params });
  },
  
  // Get activity types for filtering
  getActivityTypes: () => {
    return [
      { value: 'login', label: 'Login' },
      { value: 'logout', label: 'Logout' },
      { value: 'failed_login', label: 'Failed Login' },
      { value: 'password_change', label: 'Password Change' },
      { value: 'password_reset', label: 'Password Reset' },
      { value: 'user_created', label: 'User Created' },
      { value: 'user_updated', label: 'User Updated' },
      { value: 'permission_changed', label: 'Permission Changed' },
      { value: 'invitation_sent', label: 'Invitation Sent' },
      { value: 'invitation_accepted', label: 'Invitation Accepted' },
      { value: 'invitation_cancelled', label: 'Invitation Cancelled' },
      { value: 'profile_updated', label: 'Profile Updated' },
      { value: 'pages_updated', label: 'Pages Updated' }
    ];
  }
};

// Notification API methods
export const notificationApi = {
  // Get sent messages with pagination and filtering
  getSentMessages: async (params?: {
    page?: number;
    limit?: number;
    channel?: string;
    userId?: string;
    startDate?: string;
    endDate?: string;
  }) => {
    return api.get(`${API_PREFIX}/notifications/sent-messages`, { params });
  },
  
  // Get notification recipients
  getRecipients: async () => {
    return api.get(`${API_PREFIX}/notifications/recipients`);
  },
  
  // Get notification channels for filtering
  getNotificationChannels: () => {
    return [
      { value: 'email', label: 'Email' },
      { value: 'sms', label: 'SMS' }
    ];
  }
};

// Page API methods
export const pageApi = {
  // Get all pages for the current user
  getPages: async () => {
    return api.get(`${API_PREFIX}/pages`);
  },
  
  // Save pages for the current user
  savePages: async (pages: any[]) => {
    return api.post(`${API_PREFIX}/pages`, { pages });
  }
};

// Shared Page API methods
export const sharedPageApi = {
  // Get all shared pages with optional filtering
  getSharedPages: async (params?: { 
    page?: number;
    limit?: number;
    search?: string;
    tags?: string;
    createdBy?: string;
  }) => {
    return api.get(`${API_PREFIX}/shared-pages`, { params });
  },
  
  // Get a specific shared page
  getSharedPageById: async (id: string) => {
    return api.get(`${API_PREFIX}/shared-pages/${id}`);
  },
  
  // Create a new shared page
  createSharedPage: async (pageData: {
    name: string;
    description?: string;
    pageConfig: any;
    isPublic?: boolean;
    tags?: string[];
  }) => {
    return api.post(`${API_PREFIX}/shared-pages`, pageData);
  },
  
  // Update a shared page
  updateSharedPage: async (id: string, pageData: {
    name?: string;
    description?: string;
    pageConfig?: any;
    isPublic?: boolean;
    tags?: string[];
  }) => {
    return api.put(`${API_PREFIX}/shared-pages/${id}`, pageData);
  },
  
  // Delete a shared page
  deleteSharedPage: async (id: string) => {
    return api.delete(`${API_PREFIX}/shared-pages/${id}`);
  },
  
  // Copy a shared page to user's profile
  copySharedPageToUser: async (id: string, customName?: string) => {
    return api.post(`${API_PREFIX}/shared-pages/${id}/copy`, { customName });
  }
};

export default api;