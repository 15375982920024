import React, { useState, useEffect } from 'react';
import { ComponentProps } from '../../types';
import ComponentWrapper from '../../ComponentWrapper';
import waterStatsService from '../../../../services/waterStatsService';
import './WaterFlowStats.css';

// Default settings for the component
export const defaultSettings = {
  title: 'Water Flow Stats',
  refreshInterval: 0, // 0 means manual refresh only
};

interface WaterFlowData {
  timeRange: string;
  barn: number;
  well: number;
}

const WaterFlowStatsComponent: React.FC<ComponentProps> = ({ config, onConfigChange, onRemove }) => {
  // State for water flow data
  const [waterFlowData, setWaterFlowData] = useState<WaterFlowData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Handle config toggle - empty function to hide functionality
  const handleConfigToggle = () => {
    // No operation - this effectively disables the gear icon
    console.log('Config toggle disabled for Water Flow Stats');
  };

  // Initialize settings if they don't exist
  useEffect(() => {
    if (!config.settings) {
      onConfigChange({
        ...config,
        settings: defaultSettings
      });
    }
  }, [config, onConfigChange]);

  // Fetch water flow data
  const fetchWaterFlowData = async () => {
    try {
      setLoading(true);
      setError(null);

      console.log('Fetching water flow statistics...');
      
      // Get water flow statistics from the service
      const response = await waterStatsService.getWaterFlowStats();
      
      if (!response.success || !response.data) {
        throw new Error(response.error || 'Failed to fetch water flow data');
      }
      
      console.log('Water flow statistics:', response.data);
      
      // Format the data for display
      const processedData: WaterFlowData[] = [
        {
          timeRange: 'Last 24 Hours',
          barn: response.data.last24Hours.barn,
          well: response.data.last24Hours.well
        },
        {
          timeRange: 'Yesterday',
          barn: response.data.yesterday.barn,
          well: response.data.yesterday.well
        },
        {
          timeRange: 'Last 7 Days',
          barn: response.data.last7Days.barn,
          well: response.data.last7Days.well
        },
        {
          timeRange: 'Last 30 Days',
          barn: response.data.last30Days.barn,
          well: response.data.last30Days.well
        }
      ];

      setWaterFlowData(processedData);
    } catch (err) {
      console.error('Error fetching water flow data:', err);
      setError('Failed to load water flow data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchWaterFlowData();
  }, []);

  // Format number with commas and rounded to nearest integer
  const formatNumber = (num: number) => {
    return Math.round(num).toLocaleString('en-US');
  };

  return (
    <ComponentWrapper 
      config={config} 
      onConfigChange={onConfigChange} 
      onRemove={onRemove}
      onConfigToggle={handleConfigToggle}
      headerControls={
        <button 
          className="component-control"
          onClick={fetchWaterFlowData}
          disabled={loading}
          title="Refresh"
        >
          <span className={`refresh-icon ${loading ? 'rotating' : ''}`}>🔄</span>
        </button>
      }
    >
      <div className="water-flow-stats-component">
        {/* Error message */}
        {error && (
          <div className="error-message">{error}</div>
        )}

        {/* Loading state */}
        {loading ? (
          <div className="loading">Loading water flow data...</div>
        ) : (
          /* Water flow table */
          <div className="table-container">
            <table className="water-flow-table">
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Barn (gallons)</th>
                  <th>Well (gallons)</th>
                </tr>
              </thead>
              <tbody>
                {waterFlowData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.timeRange}</td>
                    <td>{formatNumber(data.barn)}</td>
                    <td>{formatNumber(data.well)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </ComponentWrapper>
  );
};

export default WaterFlowStatsComponent; 