import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { activityApi } from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import usePermissions from '../../hooks/usePermissions';
import { USER_PERMISSIONS } from '../../constants/permissions';
import '../../components/FormStyles.css';

// Activity type definition
interface Activity {
  _id: string;
  userId: string;
  targetUserId?: string;
  action: string;
  details: any;
  ipAddress?: string;
  userAgent?: string;
  timestamp: string;
  user?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
  };
  targetUser?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
  };
}

// User type
interface User {
  _id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  username: string;
}

// Pagination type
interface Pagination {
  total: number;
  page: number;
  limit: number;
  pages: number;
}

const UserActivities: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { isAuthenticated } = useAuth();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  
  // State for activities and pagination
  const [activities, setActivities] = useState<Activity[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    page: 1,
    limit: 20,
    pages: 0
  });
  
  // State for filters
  const [actionFilter, setActionFilter] = useState('');
  
  // State for loading
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Activity types for filtering
  const activityTypes = activityApi.getActivityTypes();
  
  // Check permissions and fetch data
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    
    if (!hasPermission(USER_PERMISSIONS.VIEW_ACTIVITY_LOGS)) {
      navigate('/');
      return;
    }
    
    if (!userId) {
      navigate('/admin/activities');
      return;
    }
    
    fetchUserActivities();
  }, [isAuthenticated, navigate, userId, pagination.page, actionFilter]);
  
  // Fetch user activities
  const fetchUserActivities = async () => {
    if (!userId) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await activityApi.getUserActivities(userId, {
        page: pagination.page,
        limit: pagination.limit,
        action: actionFilter || undefined
      });
      
      setActivities(response.data.activities);
      setUser(response.data.user);
      setPagination(response.data.pagination);
    } catch (err) {
      console.error('Error fetching user activities:', err);
      setError('Failed to load user activities. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Handle filter change
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActionFilter(e.target.value);
    setPagination(prev => ({ ...prev, page: 1 })); // Reset to first page
  };
  
  // Format date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Get user display name
  const getUserName = (user?: { firstName?: string; lastName?: string; username: string }) => {
    if (!user) return 'System';
    return user.firstName && user.lastName 
      ? `${user.firstName} ${user.lastName}` 
      : user.username;
  };
  
  // Format action for display
  const formatAction = (action: string) => {
    const activityType = activityTypes.find(type => type.value === action);
    if (activityType) {
      return activityType.label;
    }
    
    // Fallback to the original formatting if not found in activity types
    return action
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  
  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > pagination.pages) return;
    setPagination(prev => ({ ...prev, page: newPage }));
  };
  
  // View other user's activities
  const viewUserActivities = (otherUserId: string) => {
    if (otherUserId !== userId) {
      navigate(`/admin/activities/user/${otherUserId}`);
    }
  };
  
  return (
    <div className="container">
      <div className="breadcrumb">
        <Link to="/admin/activities">← Back to All Activities</Link>
      </div>
      
      <h1>
        {user ? (
          <>
            Activity Log: {getUserName(user)}
            <span className="user-email">({user.email})</span>
          </>
        ) : (
          'User Activities'
        )}
      </h1>
      
      {/* Filters */}
      <div className="filter-section">
        <div className="filter-row">
          <div className="filter-item">
            <label htmlFor="action">Action Type</label>
            <select 
              id="action" 
              value={actionFilter}
              onChange={handleFilterChange}
            >
              <option value="">All Actions</option>
              {activityTypes.map(type => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      
      {/* Error message */}
      {error && <div className="error-message">{error}</div>}
      
      {/* Loading indicator */}
      {loading ? (
        <div className="loading">Loading user activities...</div>
      ) : (
        <>
          {/* Activity table */}
          <div className="table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Action</th>
                  <th>Related User</th>
                  <th>IP Address</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {activities.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="no-data">No activities found for this user</td>
                  </tr>
                ) : (
                  activities.map(activity => {
                    // Determine if this user is the actor or the target
                    const isActor = activity.userId === userId;
                    const relatedUser = isActor ? activity.targetUser : activity.user;
                    
                    return (
                      <tr key={activity._id}>
                        <td>{formatDate(activity.timestamp)}</td>
                        <td>{formatAction(activity.action)}</td>
                        <td>
                          {relatedUser ? (
                            <button 
                              className="link-button"
                              onClick={() => viewUserActivities(isActor ? activity.targetUserId! : activity.userId)}
                            >
                              {getUserName(relatedUser)}
                            </button>
                          ) : (
                            isActor ? 'Self' : 'System'
                          )}
                        </td>
                        <td>{activity.ipAddress || '-'}</td>
                        <td>
                          {activity.details && Object.keys(activity.details).length > 0 ? (
                            <details>
                              <summary>View Details</summary>
                              <pre>{JSON.stringify(activity.details, null, 2)}</pre>
                            </details>
                          ) : (
                            '-'
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          
          {/* Pagination */}
          {pagination.pages > 1 && (
            <div className="pagination">
              <button 
                onClick={() => handlePageChange(pagination.page - 1)}
                disabled={pagination.page === 1}
              >
                Previous
              </button>
              
              <span>
                Page {pagination.page} of {pagination.pages}
              </span>
              
              <button 
                onClick={() => handlePageChange(pagination.page + 1)}
                disabled={pagination.page === pagination.pages}
              >
                Next
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserActivities; 