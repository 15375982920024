import React from 'react';

interface LockIconProps {
  isLocked: boolean;
  onClick: (e: React.MouseEvent) => void;
  title?: string;
  className?: string;
}

const LockIcon: React.FC<LockIconProps> = ({ 
  isLocked, 
  onClick, 
  title = isLocked ? 'Unlock' : 'Lock', 
  className = ''
}) => {
  return (
    <button 
      className={`lock-icon-button ${className}`}
      onClick={onClick}
      title={title}
      aria-label={title}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: '4px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px'
      }}
    >
      {isLocked ? '🔒' : '🔓'}
    </button>
  );
};

export default LockIcon; 