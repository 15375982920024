import React, { useState, useEffect } from 'react';
import { userApi } from '../services/api';
import { USER_PERMISSIONS, PERMISSION_GROUPS } from '../constants/permissions';

interface PermissionsManagerProps {
  userId: string;
  userPermissions: string[];
  onPermissionsUpdated: (newPermissions: string[]) => void;
  isAdmin: boolean;
}

const PermissionsManager: React.FC<PermissionsManagerProps> = ({
  userId,
  userPermissions = [],
  onPermissionsUpdated,
  isAdmin
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(userPermissions);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'individual' | 'groups'>('individual');

  // Reset selected permissions when userPermissions change
  useEffect(() => {
    setSelectedPermissions(userPermissions);
  }, [userPermissions]);

  // Handle permission checkbox change
  const handlePermissionChange = (permission: string) => {
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(p => p !== permission));
    } else {
      setSelectedPermissions([...selectedPermissions, permission]);
    }
  };

  // Apply a permission group
  const applyPermissionGroup = (groupPermissions: string[]) => {
    // Find which permissions in the group are not already selected
    const permissionsToAdd = groupPermissions.filter(p => !selectedPermissions.includes(p));
    
    if (permissionsToAdd.length === 0) {
      // If all permissions in the group are already selected, then remove them all
      setSelectedPermissions(selectedPermissions.filter(p => !groupPermissions.includes(p)));
    } else {
      // Otherwise, add the missing permissions
      setSelectedPermissions([...selectedPermissions, ...permissionsToAdd]);
    }
  };

  // Save permissions
  const savePermissions = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await userApi.updateUserPermissions(userId, selectedPermissions);
      
      setSuccessMessage('Permissions updated successfully');
      onPermissionsUpdated(selectedPermissions);
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (err) {
      console.error('Error updating permissions:', err);
      setError('Failed to update permissions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // If user is admin, show a message that permissions can't be modified
  if (isAdmin) {
    return (
      <div style={{ 
        padding: '15px', 
        backgroundColor: 'rgba(228, 200, 126, 0.1)', 
        borderRadius: '5px',
        marginBottom: '20px'
      }}>
        <p style={{ margin: 0, color: '#e4c87e' }}>
          Admin users have all permissions by default and cannot be modified.
        </p>
      </div>
    );
  }

  // Check if all permissions in a group are selected
  const isGroupSelected = (groupPermissions: string[]) => {
    return groupPermissions.every(p => selectedPermissions.includes(p));
  };

  // Check if some (but not all) permissions in a group are selected
  const isGroupPartiallySelected = (groupPermissions: string[]) => {
    return !isGroupSelected(groupPermissions) && 
           groupPermissions.some(p => selectedPermissions.includes(p));
  };

  return (
    <div style={{ marginBottom: '30px' }}>
      <h3 style={{ color: '#e4c87e', marginBottom: '15px' }}>User Permissions</h3>
      
      {error && (
        <div style={{ 
          color: 'red', 
          backgroundColor: 'rgba(255,0,0,0.1)', 
          padding: '10px', 
          borderRadius: '4px',
          marginBottom: '15px'
        }}>
          {error}
        </div>
      )}
      
      {successMessage && (
        <div style={{ 
          color: 'green', 
          backgroundColor: 'rgba(0,255,0,0.1)', 
          padding: '10px', 
          borderRadius: '4px',
          marginBottom: '15px'
        }}>
          {successMessage}
        </div>
      )}
      
      <div style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', borderBottom: '1px solid #333', marginBottom: '15px' }}>
          <button 
            onClick={() => setActiveTab('individual')}
            style={{
              background: 'transparent',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer',
              color: activeTab === 'individual' ? '#e4c87e' : '#999',
              borderBottom: activeTab === 'individual' ? '2px solid #e4c87e' : 'none',
              marginBottom: '-1px'
            }}
          >
            Individual Permissions
          </button>
          <button 
            onClick={() => setActiveTab('groups')}
            style={{
              background: 'transparent',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer',
              color: activeTab === 'groups' ? '#e4c87e' : '#999',
              borderBottom: activeTab === 'groups' ? '2px solid #e4c87e' : 'none',
              marginBottom: '-1px'
            }}
          >
            Permission Groups
          </button>
        </div>
        
        {activeTab === 'individual' ? (
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
            {Object.entries(USER_PERMISSIONS).map(([key, permission]) => (
              <div key={permission} style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  id={`perm-${permission}`}
                  checked={selectedPermissions.includes(permission)}
                  onChange={() => handlePermissionChange(permission)}
                  style={{ marginRight: '10px' }}
                />
                <label htmlFor={`perm-${permission}`} style={{ cursor: 'pointer' }}>
                  {key.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {Object.entries(PERMISSION_GROUPS).map(([groupName, permissions]) => (
              <div key={groupName} style={{ marginBottom: '15px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    id={`group-${groupName}`}
                    checked={isGroupSelected(permissions)}
                    ref={el => {
                      if (el) {
                        el.indeterminate = isGroupPartiallySelected(permissions);
                      }
                    }}
                    onChange={() => applyPermissionGroup(permissions)}
                    style={{ marginRight: '10px' }}
                  />
                  <label 
                    htmlFor={`group-${groupName}`} 
                    style={{ 
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      color: isGroupSelected(permissions) ? '#e4c87e' : '#ddd'
                    }}
                  >
                    {groupName.split('_').join(' ')}
                  </label>
                </div>
                <div style={{ paddingLeft: '25px', fontSize: '0.9rem', color: '#999', marginTop: '5px' }}>
                  Includes: {permissions.map(p => {
                    const key = Object.entries(USER_PERMISSIONS).find(([_, val]) => val === p)?.[0];
                    return key ? key.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ') : p;
                  }).join(', ')}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      
      <button
        onClick={savePermissions}
        disabled={loading}
        style={{
          background: '#e4c87e',
          border: 'none',
          borderRadius: '4px',
          padding: '0.5rem 1rem',
          color: '#1a1a1a',
          cursor: loading ? 'default' : 'pointer',
          fontWeight: '500',
          opacity: loading ? 0.7 : 1
        }}
      >
        {loading ? 'Saving...' : 'Save Permissions'}
      </button>
    </div>
  );
};

export default PermissionsManager; 