import React, { useState, FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { validateEmail, validateUsername, validateName } from '../utils/validation';
import '../components/FormStyles.css';

const EditProfile: React.FC = () => {
  const { user, updateUser, isLoading, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  // Form state
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: ''
  });

  // Error messages
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
  const [apiError, setApiError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Load user data when component mounts
  useEffect(() => {
    if (!isAuthenticated) {
      // Redirect to login if not authenticated
      navigate('/login');
      return;
    }

    if (user) {
      setFormData({
        username: user.username || '',
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || ''
      });
    }
  }, [user, isAuthenticated, navigate]);

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Clear error for this field
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    
    // Clear messages when user starts typing again
    if (apiError) setApiError(null);
    if (successMessage) setSuccessMessage(null);
  };

  // Validate form
  const validateForm = (): boolean => {
    const newErrors = {
      username: formData.username !== user?.username ? validateUsername(formData.username) : null,
      email: formData.email !== user?.email ? validateEmail(formData.email) : null,
      firstName: validateName(formData.firstName, 'First name'),
      lastName: validateName(formData.lastName, 'Last name'),
    };

    setErrors(newErrors);

    // Check if there are any errors
    return !Object.values(newErrors).some(error => error !== null);
  };

  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setApiError(null);
    setSuccessMessage(null);

    // Validate form
    if (!validateForm()) {
      return;
    }

    // Check if anything has changed
    const hasChanges = 
      formData.username !== user?.username ||
      formData.email !== user?.email ||
      formData.firstName !== user?.firstName ||
      formData.lastName !== user?.lastName;

    if (!hasChanges) {
      setSuccessMessage('No changes to save');
      return;
    }

    try {
      // Call updateUser function from context
      await updateUser({
        username: formData.username !== user?.username ? formData.username : undefined,
        email: formData.email !== user?.email ? formData.email : undefined,
        firstName: formData.firstName !== user?.firstName ? formData.firstName : undefined,
        lastName: formData.lastName !== user?.lastName ? formData.lastName : undefined
      });

      setSuccessMessage('Profile updated successfully');
    } catch (error: any) {
      // Display error message
      setApiError(error.response?.data?.message || 'Update failed. Please try again.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h1 className="auth-title">Edit Profile</h1>
        
        {apiError && <div className="error-message" style={{ marginBottom: '1rem' }}>{apiError}</div>}
        {successMessage && (
          <div style={{ color: 'green', marginBottom: '1rem', textAlign: 'center' }}>
            {successMessage}
          </div>
        )}
        
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label className="form-label" htmlFor="firstName">First Name</label>
              <input
                className="form-input"
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <div className="error-message">{errors.firstName}</div>}
            </div>
            
            <div className="form-group">
              <label className="form-label" htmlFor="lastName">Last Name</label>
              <input
                className="form-input"
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <div className="error-message">{errors.lastName}</div>}
            </div>
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="username">Username</label>
            <input
              className="form-input"
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
            {errors.username && <div className="error-message">{errors.username}</div>}
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="email">Email</label>
            <input
              className="form-input"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="error-message">{errors.email}</div>}
          </div>
          
          <div style={{ display: 'flex', gap: '1rem' }}>
            <button 
              className="form-button" 
              type="submit" 
              disabled={isLoading}
              style={{ flex: 1 }}
            >
              {isLoading ? 'Saving...' : 'Save Changes'}
            </button>
            
            <button 
              className="form-button" 
              type="button" 
              onClick={() => navigate('/')}
              style={{ flex: 1, backgroundColor: '#6c757d' }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile; 