/**
 * Permission Constants
 * 
 * This file contains all the permission constants used throughout the application.
 * These should match the permissions defined on the backend.
 */

// Global permissions
export const GLOBAL_PERMISSIONS = {
  ACCESS_ADMIN_PANEL: 'global:access_admin_panel',
};

// User management permissions
export const USER_PERMISSIONS = {
  // User listing permissions
  VIEW_USERS: 'users:view',
  
  // User management permissions
  CREATE_USER: 'users:create',
  EDIT_USER: 'users:edit',
  DELETE_USER: 'users:delete',
  
  // Invitation permissions
  VIEW_INVITATIONS: 'users:view_invitations',
  SEND_INVITATIONS: 'users:send_invitations',
  CANCEL_INVITATIONS: 'users:cancel_invitations',
  
  // Role management permissions
  MANAGE_ROLES: 'users:manage_roles',
  
  // Permission management
  MANAGE_PERMISSIONS: 'users:manage_permissions',
  
  // Activity logs permissions
  VIEW_ACTIVITY_LOGS: 'users:view_activity_logs',
  
  // Notification permissions
  VIEW_NOTIFICATIONS: 'users:view_notifications',

  // Water data permissions
  VIEW_WATER_DATA: 'users:view_water_data'
};

// Page management permissions
export const PAGE_PERMISSIONS = {
  // Page management permissions
  VIEW_PAGES: 'pages:view',
  
  // Shared page permissions
  SAVE_PAGE: 'pages:save',
  DELETE_PAGE: 'pages:delete',
  EDIT_PAGE: 'pages:edit',
  
  // Page sharing permissions
  SHARE_PAGE: 'pages:share',
  
  // Page organization permissions
  ORGANIZE_PAGES: 'pages:organize'
};

// Permission groups for common groupings
export const PERMISSION_GROUPS = {
  USER_MANAGER: [
    USER_PERMISSIONS.VIEW_USERS,
    USER_PERMISSIONS.EDIT_USER,
    USER_PERMISSIONS.CREATE_USER,
    USER_PERMISSIONS.DELETE_USER,
  ],
  INVITATION_MANAGER: [
    USER_PERMISSIONS.VIEW_INVITATIONS,
    USER_PERMISSIONS.SEND_INVITATIONS, 
    USER_PERMISSIONS.CANCEL_INVITATIONS
  ],
  ACTIVITY_LOG_VIEWER: [
    USER_PERMISSIONS.VIEW_ACTIVITY_LOGS
  ],
  NOTIFICATION_VIEWER: [
    USER_PERMISSIONS.VIEW_NOTIFICATIONS
  ],
  WATER_DATA_VIEWER: [
    USER_PERMISSIONS.VIEW_WATER_DATA
  ],
  PAGE_PUBLISHER: [
    PAGE_PERMISSIONS.SAVE_PAGE,
    PAGE_PERMISSIONS.SHARE_PAGE
  ],
  PAGE_MANAGER: [
    PAGE_PERMISSIONS.SAVE_PAGE,
    PAGE_PERMISSIONS.DELETE_PAGE,
    PAGE_PERMISSIONS.EDIT_PAGE,
    PAGE_PERMISSIONS.SHARE_PAGE,
    PAGE_PERMISSIONS.ORGANIZE_PAGES
  ]
}; 