import React, { useState } from 'react';
import '../App.css';

interface DeletePageModalProps {
  isOpen: boolean;
  pageName: string;
  onClose: () => void;
  onConfirm: () => void;
}

const DeletePageModal: React.FC<DeletePageModalProps> = ({ 
  isOpen, 
  pageName, 
  onClose, 
  onConfirm 
}) => {
  const [confirmText, setConfirmText] = useState('');
  const isConfirmationValid = confirmText.toLowerCase() === 'delete';

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="delete-page-modal">
        <div className="modal-header">
          <h3>Delete Page</h3>
          <button className="modal-close-btn" onClick={onClose}>×</button>
        </div>
        
        <div className="modal-content">
          <p className="delete-warning">
            <span className="warning-icon">⚠️</span>
            This action cannot be undone
          </p>
          
          <p>
            Are you sure you want to delete the page <strong>"{pageName}"</strong>?
          </p>
          
          <div className="confirmation-input">
            <label htmlFor="delete-confirmation">
              Type <strong>delete</strong> to confirm:
            </label>
            <input
              id="delete-confirmation"
              type="text"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              placeholder="delete"
              autoFocus
            />
          </div>
        </div>
        
        <div className="modal-footer">
          <button 
            className="cancel-button" 
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            className="delete-button" 
            onClick={onConfirm}
            disabled={!isConfirmationValid}
          >
            Delete Page
          </button>
        </div>
      </div>
    </>
  );
};

export default DeletePageModal; 