import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ComponentProps } from '../../types';
import ComponentWrapper from '../../ComponentWrapper';
import TimeChartConfig from './TimeChartConfig';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  AreaChart, Area, BarChart, Bar
} from 'recharts';
import moment from 'moment-timezone';
import timeSeriesService, { TimeSeriesData, SensorType } from '../../../../services/timeSeriesService';
import './TimeChartStyles.css';

// Define interfaces for data types
interface SeriesConfig {
  name: string;
  sensorType: SensorType;
  sensorId: string;
  valueField: string;
  chartType: 'line' | 'bar' | 'area';
  color?: string;
  yAxisPosition?: 'left' | 'right';
}

interface TimeDataPoint {
  timestamp: string;
  [key: string]: string | number;
}

interface SeriesData {
  name: string;
  type: 'line' | 'bar' | 'area';
  color?: string;
  yAxisPosition?: 'left' | 'right';
  data: TimeDataPoint[];
}

interface ChartData {
  timestamp: string;
  value: number;
  unit?: string;
}

export interface DataSourceConfig {
  timeRange: {
    type: 'preset' | 'custom';
    preset?: 'hour' | 'day' | 'week' | 'month' | 'year' | '24hours' | '7days' | '30days';
    custom?: {
      start: Date;
      end: Date;
    };
  };
  aggregation: {
    level: 'none' | 'minute' | '5minutes' | '15minutes' | '30minutes' | 'hour' | 'day' | 'week';
    method: 'avg' | 'sum' | 'min' | 'max';
  };
  series: SeriesConfig[];
  refresh: {
    autoRefresh: boolean;
    interval: number;
  };
}

// Default settings for the component
export const defaultSettings = {
  title: 'Time Chart',
  refreshInterval: 0, // 0 means manual refresh only
  chartType: 'line' as const, // 'line', 'area', or 'bar'
  showGrid: true,
  showTooltip: true,
  showLegend: true,
  colors: ['#8884d8', '#82ca9d', '#ffc658'], // Default colors for multiple series
  dateFormat: 'MM/DD/YYYY HH:mm', // Date format for display
  interpolation: true, // Enable interpolation by default
  yAxis: {
    left: {
      autoScale: true,
      min: undefined,
      max: undefined
    },
    right: {
      autoScale: true,
      min: undefined,
      max: undefined
    }
  },
  dataSourceConfig: {
    timeRange: {
      type: 'preset' as const,
      preset: '24hours'
    },
    aggregation: {
      level: 'none' as const,
      method: 'avg' as const
    },
    series: [{
      name: 'Water Level',
      sensorType: 'WATER_DEPTH' as SensorType,
      sensorId: 'depth-sensor-1',
      valueField: 'value',
      chartType: 'line' as const,
      yAxisPosition: 'left' as const,
      color: '#1f77b4'
    }],
    refresh: {
      autoRefresh: false,
      interval: 0
    }
  }
} as const;

// Generate sample data
const generateSampleData = (range: string, count = 20): TimeDataPoint[] => {
  const now = new Date();
  const data: TimeDataPoint[] = [];
  
  let timeStep: number;
  switch (range) {
    case 'hour':
      timeStep = 60 * 1000; // 1 minute in milliseconds
      break;
    case 'week':
      timeStep = 24 * 60 * 60 * 1000; // 1 day in milliseconds
      break;
    case 'month':
      timeStep = 24 * 60 * 60 * 1000; // 1 day in milliseconds
      break;
    case 'year':
      timeStep = 30 * 24 * 60 * 60 * 1000; // ~1 month in milliseconds
      break;
    case 'day':
    default:
      timeStep = 60 * 60 * 1000; // 1 hour in milliseconds
  }
  
  for (let i = count - 1; i >= 0; i--) {
    const timestamp = new Date(now.getTime() - (i * timeStep));
    const value = Math.floor(Math.random() * 100) + 20; // Random value between 20-120
    const secondValue = Math.floor(Math.random() * 80) + 10; // Second random value between 10-90
    
    data.push({
      timestamp: timestamp.toISOString(),
      value,
      secondValue,
      displayTime: timestamp.toLocaleTimeString(),
      displayDate: timestamp.toLocaleDateString()
    });
  }
  
  return data;
};

const TimeChartComponent: React.FC<ComponentProps> = ({ config, onConfigChange, onRemove }) => {
  // Initialize settings if they don't exist or if they're empty
  useEffect(() => {
    if (!config.settings || !config.settings.dataSourceConfig) {
      onConfigChange({
        ...config,
        settings: {
          ...defaultSettings,
          title: config.title || 'New Time Chart',
          dataSourceConfig: {
            timeRange: {
              type: 'preset',
              preset: '24hours'
            },
            aggregation: {
              level: 'none',
              method: 'avg'
            },
            series: [],
            refresh: {
              autoRefresh: false,
              interval: 0
            }
          }
        }
      });
    }
  }, [config, onConfigChange]);
  
  // Define state variables for the component
  const [chartData, setChartData] = useState<SeriesData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
  const [showDebugPanel, setShowDebugPanel] = useState<boolean>(false);
  const [debugInfo, setDebugInfo] = useState<any>(null);
  
  // Enable debug logging for troubleshooting
  const debugLogging = true;
  
  const processTimeSeriesData = (data: TimeSeriesData[], valueField: keyof TimeSeriesData): ChartData[] => {
    return data.map(point => ({
      timestamp: point.timestamp,
      value: typeof point[valueField] === 'string' ? parseFloat(point[valueField] as string) : (point[valueField] as number) ?? point.value,
      unit: point.unit
    }));
  };

  const fetchData = useCallback(async () => {
    console.log('Fetching data with config:', config.settings?.dataSourceConfig);
    const debugData: any = {
      config: config.settings?.dataSourceConfig,
      startTime: new Date().toISOString(),
      series: [],
      errors: []
    };
    
    try {
      setLoading(true);
      setError(null);

      // Use sample data if no configuration exists
      if (!config.settings?.dataSourceConfig || !config.settings?.dataSourceConfig.series || config.settings?.dataSourceConfig.series.length === 0) {
        console.log('No data source config found, using sample data');
        const data = generateSampleData(config.settings?.dataSourceConfig?.timeRange?.preset || 'day');
        const sampleSeries: SeriesData = {
          name: 'Sample Data',
          type: 'line',
          color: config.settings?.colors?.[0] || '#1f77b4',
          yAxisPosition: 'left',
          data: data
        };
        setChartData([sampleSeries]);
        return;
      }

      const { timeRange, series, aggregation } = config.settings.dataSourceConfig;
      console.log('Processing series:', JSON.stringify(series));
      
      // Process each series
      const seriesPromises = series.map(async (seriesConfig: SeriesConfig) => {
        const { sensorType, sensorId, valueField, name, chartType, color, yAxisPosition } = seriesConfig;
        console.log('Fetching data for series:', name, { sensorType, sensorId, valueField });
        
        // Calculate time range
        let startTime: Date;
        let endTime: Date | undefined = undefined;

        if (timeRange.type === 'preset') {
          // Handle the "Last X" presets that count from current time
          if (timeRange.preset === '24hours') {
            // Only set startTime, leave endTime undefined to use data-since endpoint
            startTime = moment().subtract(24, 'hours').toDate();
            // endTime stays undefined to trigger the data-since endpoint
            console.log('Last 24 Hours preset - Start time:', startTime.toISOString());
            console.log('Last 24 Hours preset - End time: <current time>');
          } else if (timeRange.preset === '7days') {
            // Only set startTime, leave endTime undefined to use data-since endpoint
            startTime = moment().subtract(7, 'days').toDate();
            // endTime stays undefined to trigger the data-since endpoint
            console.log('Last 7 Days preset - Start time:', startTime.toISOString());
            console.log('Last 7 Days preset - End time: <current time>');
          } else if (timeRange.preset === '30days') {
            // Only set startTime, leave endTime undefined to use data-since endpoint
            startTime = moment().subtract(30, 'days').toDate();
            // endTime stays undefined to trigger the data-since endpoint
            console.log('Last 30 Days preset - Start time:', startTime.toISOString());
            console.log('Last 30 Days preset - End time: <current time>');
          } else {
            // Handle the "Previous X" presets with Pacific timezone (midnight to midnight)
            // Create a new moment using moment-timezone specifically for Pacific time
            const pacificTimezone = 'America/Los_Angeles';
            const pacificNow = moment().tz(pacificTimezone);
            
            switch (timeRange.preset) {
              case 'hour':
                // Previous hour (last complete hour)
                startTime = pacificNow.clone().subtract(1, 'hour').startOf('hour').toDate();
                endTime = pacificNow.clone().subtract(1, 'hour').endOf('hour').toDate();
                break;
              case 'day':
                // Previous day (midnight to midnight Pacific time)
                startTime = pacificNow.clone().subtract(1, 'day').startOf('day').toDate();
                endTime = pacificNow.clone().subtract(1, 'day').endOf('day').toDate();
                break;
              case 'week':
                // Previous week (Sunday-Saturday Pacific time)
                startTime = pacificNow.clone().subtract(1, 'week').startOf('week').toDate();
                endTime = pacificNow.clone().subtract(1, 'week').endOf('week').toDate();
                break;
              case 'month':
                // Previous month (1st to last day of previous month Pacific time)
                startTime = pacificNow.clone().subtract(1, 'month').startOf('month').toDate();
                endTime = pacificNow.clone().subtract(1, 'month').endOf('month').toDate();
                break;
              case 'year':
                // Previous year (Jan 1 to Dec 31 of previous year Pacific time)
                startTime = pacificNow.clone().subtract(1, 'year').startOf('year').toDate();
                endTime = pacificNow.clone().subtract(1, 'year').endOf('year').toDate();
                break;
              default:
                // Default to previous day if preset not recognized
                console.warn('Unknown preset:', timeRange.preset, 'defaulting to previous day');
                startTime = pacificNow.clone().subtract(1, 'day').startOf('day').toDate();
                endTime = pacificNow.clone().subtract(1, 'day').endOf('day').toDate();
            }
            
            console.log('Previous preset - Start time:', startTime.toISOString());
            console.log('Previous preset - End time:', endTime?.toISOString() || '<current time>');
          }
        } else if (timeRange.custom && timeRange.custom.start && timeRange.custom.end) {
          // Convert custom dates to Pacific timezone-aware dates
          const pacificTimezone = 'America/Los_Angeles';
          
          // When a user selects dates in the browser, they're in local time
          // We need to interpret these as Pacific time explicitly, then convert to UTC for the API
          const pacificStart = moment.tz(timeRange.custom.start, pacificTimezone);
          const pacificEnd = moment.tz(timeRange.custom.end, pacificTimezone);
          
          startTime = pacificStart.toDate();
          endTime = pacificEnd.toDate();
          
          console.log('Custom date range - Pacific start time:', pacificStart.format('YYYY-MM-DD HH:mm:ss'));
          console.log('Custom date range - Pacific end time:', pacificEnd.format('YYYY-MM-DD HH:mm:ss'));
          console.log('Custom date range - UTC start time:', startTime.toISOString());
          console.log('Custom date range - UTC end time:', endTime.toISOString());
        } else {
          // Default to last 24 hours if configuration is invalid
          console.warn('Invalid time range configuration, defaulting to last 24 hours');
          startTime = moment().subtract(24, 'hours').toDate();
        }

        console.log('Time range:', { 
          startTime: startTime.toISOString(), 
          endTime: endTime ? endTime.toISOString() : '<current time>'
        });

        try {
          // Debug logging for water depth sensor specifically
          if (sensorType === 'WATER_DEPTH' && sensorId === 'WaterMonitor/BB/water_depth_1') {
            console.log('⭐ Processing water depth sensor WaterMonitor/BB/water_depth_1');
          }
          
          // Only include endTime in the API call if it's defined (for "Previous X" presets)
          const apiParams: any = {
            sensorType: sensorType as SensorType,
            sensorId,
            startTime,
            aggregation: {
              level: aggregation.level,
              method: aggregation.method
            }
          };
          
          // Only add endTime if it's defined (for "Previous X" presets)
          if (endTime) {
            apiParams.endTime = endTime;
          }
          
          console.log('Calling timeSeriesService.getTimeSeriesData with params:', {
            sensorType,
            sensorId,
            startTime: startTime.toISOString(),
            endTime: endTime ? endTime.toISOString() : '<current time>',
            aggregation: {
              level: aggregation.level,
              method: aggregation.method
            }
          });
          
          const response = await timeSeriesService.getTimeSeriesData(apiParams);

          console.log(`Response from timeSeriesService for ${name}:`, {
            success: response.success,
            errorMessage: response.error,
            dataPoints: response.data?.length || 0
          });

          if (!response.success || !response.data) {
            console.error('Failed to fetch data for series:', name, response.error);
            return {
              name,
              type: chartType,
              color: color || config.settings?.colors?.[0] || '#1f77b4',
              yAxisPosition: yAxisPosition || 'left',
              data: [] // Return empty data array on error
            };
          }

          if (response.data.length === 0) {
            console.warn(`No data points returned for ${name} with the given parameters`);
          }

          // Process the time series data
          const processedData = response.data.map(point => {
            // Get the value from the requested field or fallback to 'value'
            const pointValue = point[valueField as keyof TimeSeriesData] !== undefined 
              ? point[valueField as keyof TimeSeriesData] 
              : point.value;

            // At this point the timestamp is already in Pacific time if it came from the frontend aggregation
            // Use it directly without additional conversion
            const timestamp = point.timestamp;
            
            // Create a base point with the timestamp
            const basePoint: TimeDataPoint = {
              timestamp: timestamp
            };
            
            // Add the value with the series name as the key
            basePoint[name] = pointValue !== undefined ? pointValue : 0;
            
            // Debug log timestamp processing if enabled
            if (debugLogging) {
              console.log(`Processing data point:`, {
                timestamp: timestamp,
                value: pointValue,
                seriesName: name,
                aggregation: aggregation.level
              });
            }
            
            return basePoint;
          });

          console.log(`Processed ${processedData.length} data points for ${name}`);
          if (processedData.length > 0) {
            console.log('First processed data point:', processedData[0]);
            console.log('Last processed data point:', processedData[processedData.length - 1]);
          }

          debugData.series.push({
            name,
            sensorType,
            sensorId,
            requestParams: {
              startTime: startTime.toISOString(),
              endTime: endTime ? endTime.toISOString() : '<current time>',
              aggregation: {
                level: aggregation.level,
                method: aggregation.method
              }
            },
            response: {
              success: response.success,
              dataPoints: response.data?.length || 0,
              error: response.error,
              sampleData: response.data?.slice(0, 2) || []
            }
          });

          return {
            name,
            type: chartType,
            color: color || config.settings?.colors?.[0] || '#1f77b4',
            yAxisPosition: yAxisPosition || 'left',
            data: processedData
          };
        } catch (error) {
          console.error('Error fetching data for series:', name, error);
          return {
            name,
            type: chartType,
            color: color || config.settings?.colors?.[0] || '#1f77b4',
            yAxisPosition: yAxisPosition || 'left',
            data: [] // Return empty data array on error
          };
        }
      });

      try {
        const seriesData = await Promise.all(seriesPromises);
        console.log('All series data fetched:', seriesData.map(s => ({
          name: s.name,
          type: s.type,
          dataPoints: s.data.length
        })));
        
        const filteredData = seriesData.filter(series => series.data.length > 0);
        console.log('Filtered series data (non-empty only):', filteredData.map(s => ({
          name: s.name,
          type: s.type,
          dataPoints: s.data.length
        })));
        
        setChartData(filteredData); // Filter out series with no data
      } catch (error) {
        console.error('Error processing series data:', error);
        setError(error instanceof Error ? error.message : 'Failed to fetch data');
      }

      // Add:
      debugData.endTime = new Date().toISOString();
      debugData.duration = new Date(debugData.endTime).getTime() - new Date(debugData.startTime).getTime();
      setDebugInfo(debugData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data');
      debugData.errors.push(err instanceof Error ? err.message : 'Unknown error');
      debugData.endTime = new Date().toISOString();
      debugData.duration = new Date(debugData.endTime).getTime() - new Date(debugData.startTime).getTime();
      setDebugInfo(debugData);
    } finally {
      setLoading(false);
    }
  }, [config.settings]);

  const mergeTimeSeriesData = (seriesData: SeriesData[]): TimeDataPoint[] => {
    console.log('Merging series data:', seriesData.map(s => ({name: s.name, dataPoints: s.data.length})));
    if (!seriesData || seriesData.length === 0) return [];

    const timestampMap = new Map<string, TimeDataPoint>();
    
    seriesData.forEach(series => {
      console.log(`Processing series ${series.name} with ${series.data.length} data points`);
      if (!series.data || !Array.isArray(series.data)) {
        console.warn('Invalid series data:', series);
        return;
      }

      series.data.forEach((point: TimeDataPoint) => {
        // Use the timestamp directly without applying additional timezone conversion
        // The timestamps from the API already have the correct time
        const timestamp = point.timestamp;
        
        if (!timestampMap.has(timestamp)) {
          timestampMap.set(timestamp, { timestamp });
        }
        
        const existingPoint = timestampMap.get(timestamp)!;
        
        // Instead of copying all properties, we specifically set the series name as the key
        // and use the value from the relevant field (typically 'value')
        const pointValue = Object.entries(point).find(([key, value]) => 
          key !== 'timestamp' && typeof value === 'number'
        );
        
        if (pointValue) {
          // Use the series name as the property key for the value
          existingPoint[series.name] = pointValue[1];
          
          // Only log if we're merging value for water flow series for debugging
          if (series.name.toLowerCase().includes('water') && series.name.toLowerCase().includes('flow')) {
            console.log(`🔍 Setting ${series.name} = ${pointValue[1]} for timestamp ${timestamp}`);
          }
        }
      });
    });

    // Convert map to array and sort by timestamp
    // Timestamps are already in Pacific time, so sort them directly
    const mergedData = Array.from(timestampMap.values())
      .sort((a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf());
    
    console.log(`Final merged data: ${mergedData.length} points`);
    console.log('Sample of merged data:', mergedData.slice(0, 2));
    
    // Check if our series are in the data
    const seriesNames = seriesData.map(s => s.name);
    seriesNames.forEach(name => {
      const hasSeries = mergedData.some(point => name in point);
      console.log(`Data contains ${name} data points: ${hasSeries}`);
    });
    
    // Analyze how many points have all series vs. just one series
    analyzeDataCompleteness(mergedData, seriesNames);
    
    // If interpolation is enabled in settings, fill in missing values
    const settings = config.settings || defaultSettings;
    if (settings.interpolation && seriesNames.length > 1) {
      const interpolatedData = interpolateDataPoints(mergedData, seriesNames);
      console.log('Data after interpolation:');
      analyzeDataCompleteness(interpolatedData, seriesNames);
      return interpolatedData;
    }
    
    return mergedData;
  };
  
  /**
   * Analyze the merged data to see how many points have data for all series vs just some series
   */
  const analyzeDataCompleteness = (mergedData: TimeDataPoint[], seriesNames: string[]) => {
    // Count how many data points have all series
    const pointsWithAllSeries = mergedData.filter(point => 
      seriesNames.every(name => name in point)
    ).length;
    
    // Count points with only some series
    const pointsWithSomeSeries = mergedData.length - pointsWithAllSeries;
    
    console.log(`Data points with ALL series: ${pointsWithAllSeries} (${((pointsWithAllSeries/mergedData.length)*100).toFixed(1)}%)`);
    console.log(`Data points with SOME series: ${pointsWithSomeSeries} (${((pointsWithSomeSeries/mergedData.length)*100).toFixed(1)}%)`);
    
    // Count per series
    seriesNames.forEach(name => {
      const pointsWithThisSeries = mergedData.filter(point => name in point).length;
      console.log(`Points with ${name}: ${pointsWithThisSeries} (${((pointsWithThisSeries/mergedData.length)*100).toFixed(1)}%)`);
    });
    
    // Show 5 sample data points for in-depth examination
    console.log("Sample of 5 merged data points:");
    mergedData.slice(0, 5).forEach((point, index) => {
      const seriesPresent = seriesNames.filter(name => name in point);
      const seriesMissing = seriesNames.filter(name => !(name in point));
      console.log(`Point ${index+1} - ${point.timestamp}`);
      console.log(`  Series present: ${seriesPresent.join(', ')}`);
      console.log(`  Series missing: ${seriesMissing.join(', ')}`);
      console.log(`  Values: `, seriesPresent.map(name => `${name}: ${point[name]}`));
    });
  };
  
  /**
   * Interpolate missing values in data points to improve chart rendering
   * This will fill in gaps where one series has data but others don't
   */
  const interpolateDataPoints = (mergedData: TimeDataPoint[], seriesNames: string[]): TimeDataPoint[] => {
    if (mergedData.length <= 1) return mergedData;
    
    console.log('Interpolating missing values in data points...');
    
    // Create a copy of the data to work with
    const interpolatedData = [...mergedData];
    
    // For each series, look for gaps and interpolate values
    seriesNames.forEach(seriesName => {
      // Find all points that have this series
      const pointsWithSeries = interpolatedData
        .map((point, index) => ({point, index, hasValue: seriesName in point}))
        .filter(item => item.hasValue);
      
      if (pointsWithSeries.length <= 1) {
        console.log(`Not enough points for interpolation in series ${seriesName}`);
        return; // Not enough points to interpolate
      }
      
      // Look through the points that don't have this series
      interpolatedData.forEach((point, pointIndex) => {
        if (seriesName in point) return; // Already has a value
        
        // Find the closest points before and after that have values
        const previousPoint = pointsWithSeries
          .filter(p => p.index < pointIndex)
          .sort((a, b) => b.index - a.index)[0]; // Closest before
          
        const nextPoint = pointsWithSeries
          .filter(p => p.index > pointIndex)
          .sort((a, b) => a.index - b.index)[0]; // Closest after
        
        // Only interpolate if we have points both before and after
        if (previousPoint && nextPoint) {
          const prevValue = previousPoint.point[seriesName] as number;
          const nextValue = nextPoint.point[seriesName] as number;
          // Use moment to parse timestamps - they are already in Pacific time
          const prevTime = moment(previousPoint.point.timestamp).valueOf();
          const currTime = moment(point.timestamp).valueOf();
          const nextTime = moment(nextPoint.point.timestamp).valueOf();
          
          // Linear interpolation formula: y = y1 + (x - x1) * (y2 - y1) / (x2 - x1)
          const interpolatedValue = prevValue + 
            (currTime - prevTime) * (nextValue - prevValue) / (nextTime - prevTime);
          
          // Add the interpolated value to the point
          point[seriesName] = Number(interpolatedValue.toFixed(2));
        }
      });
    });
    
    return interpolatedData;
  };
  
  // Initial data fetch and auto-refresh setup
  useEffect(() => {
    // Fetch data initially
    fetchData();
    
    // Set up auto-refresh if configured
    if (config.settings?.dataSourceConfig?.refresh?.autoRefresh && 
        config.settings?.dataSourceConfig?.refresh?.interval > 0) {
      console.log(`Setting up auto-refresh every ${config.settings.dataSourceConfig.refresh.interval} seconds`);
      const intervalId = setInterval(fetchData, config.settings.dataSourceConfig.refresh.interval * 1000);
      
      // Clean up interval on unmount or config change
      return () => {
        console.log('Clearing auto-refresh interval');
        clearInterval(intervalId);
      };
    }
  }, [fetchData, config.settings?.dataSourceConfig?.refresh]);
  
  // Format timestamp for display
  const formatTimestamp = (timestamp: string | number): string => {
    // All timestamps are now consistently in Pacific time from the frontend aggregation
    const pacificTime = moment(timestamp);
    
    // Format based on the range setting
    const range = config.settings?.range || 
                 config.settings?.dataSourceConfig?.timeRange?.preset;
    
    // Also check aggregation level - especially for 'day' level
    const aggregationLevel = config.settings?.dataSourceConfig?.aggregation?.level;
    
    // If aggregation level is 'day', format as date regardless of range setting
    if (aggregationLevel === 'day') {
      return pacificTime.format('MMM D, YYYY');
    }
    
    switch (range) {
      case 'hour':
        return pacificTime.format('h:mm A');
      case 'day':
        return pacificTime.format('h:mm A');
      case 'week':
      case 'month':
        return pacificTime.format('MMM D');
      case 'year':
        return pacificTime.format('MMM YY');
      case '24hours':
        return pacificTime.format('MM/DD h:mm A');
      case '7days':
      case '30days':
        return pacificTime.format('MM/DD');
      default:
        return pacificTime.format('MM/DD/YYYY, h:mm A');
    }
  };
  
  // Enhanced renderChart function to handle all series types and configurations
  const renderChart = () => {
    console.log('Rendering chart with data:', chartData.map(series => ({
      name: series.name,
      type: series.type,
      yAxisPosition: series.yAxisPosition,
      dataPoints: series.data.length
    })));

    // Handle loading and error states
    if (loading) {
      return (
        <div className="chart-loading">
          <div className="loading-spinner"></div>
          <div>Loading chart data...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="chart-error">
          <div>Error loading data: {error}</div>
          <button onClick={fetchData} className="refresh-button">Retry</button>
        </div>
      );
    }

    // If no data or no series configured
    if (chartData.length === 0) {
      return (
        <div className="chart-no-data">
          No data available. Please configure data sources.
        </div>
      );
    }
    
    // For multiple series, merge the data by timestamp
    const mergedData = mergeTimeSeriesData(chartData);
    console.log('Merged data for chart has properties:', 
      mergedData.length > 0 
        ? Object.keys(mergedData[0]).filter(k => k !== 'timestamp')
        : 'No merged data points');

    // If no merged data, show no data message
    if (!mergedData || mergedData.length === 0) {
      return (
        <div className="chart-no-data">
          No data points available for the selected time range.
        </div>
      );
    }

    // Determine if we need a mixed chart (combination of line, bar, area)
    const chartTypes = new Set(chartData.map(series => series.type));
    console.log('Chart types in use:', Array.from(chartTypes));
    
    // Show what series names we're displaying
    const seriesNames = chartData.map(s => s.name);
    console.log('Series names to display:', seriesNames);
    
    // Default chart is LineChart
    const ChartComponent = 
      chartTypes.size > 1 ? LineChart :
      chartTypes.has('bar') ? BarChart :
      chartTypes.has('area') ? AreaChart : 
      LineChart;

    // Determine which series component to use for each series
    const getSeriesComponent = (type: string) => {
      switch (type) {
        case 'bar': return Bar;
        case 'area': return Area;
        case 'line':
        default: return Line;
      }
    };

    const settings = config.settings || defaultSettings;
    
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight={200}>
        <ChartComponent
          data={mergedData}
          margin={{ top: 5, right: 10, left: 5, bottom: 15 }}
        >
          {settings.showGrid && (
            <CartesianGrid strokeDasharray="3 3" />
          )}
          
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={formatTimestamp}
            minTickGap={50}
            height={30}
            tick={{fontSize: 10}}
          />
          
          {/* Create Y-Axes for each series - grouped by position */}
          {['left', 'right'].map(position => {
            const seriesWithPosition = chartData.filter(
              s => (s.yAxisPosition || 'left') === position
            );
            
            if (seriesWithPosition.length === 0) return null;
            
            // Get Y-axis configuration for this position
            const axisConfig = settings.yAxis?.[position as 'left' | 'right'];
            
            // Determine domain values based on configuration
            let domain: [number | 'auto', number | 'auto'] = ['auto', 'auto'];
            
            if (axisConfig && !axisConfig.autoScale) {
              // If custom scale is set, use the configured min/max values
              // Convert to numbers explicitly to ensure recharts handles them correctly
              const minValue = axisConfig.min !== undefined ? Number(axisConfig.min) : 'auto';
              const maxValue = axisConfig.max !== undefined ? Number(axisConfig.max) : 'auto';
              domain = [minValue, maxValue];
            }
            
            console.log(`Y-axis ${position} domain:`, domain);
            
            return (
              <YAxis 
                key={`yAxis-${position}`}
                yAxisId={position}
                orientation={position as 'left' | 'right'}
                domain={domain}
                allowDataOverflow={!axisConfig?.autoScale}
                scale="linear"
                padding={{ top: 5, bottom: 5 }}
                tickFormatter={value => typeof value === 'number' ? value.toFixed(1) : value.toString()}
                tick={{fontSize: 10}}
                width={30}
                tickCount={5}
              />
            );
          })}
          
          {/* Render each series with the appropriate component */}
          {chartData.map((series, index) => {
            const SeriesComponent = getSeriesComponent(series.type);
            return (
              <SeriesComponent
                key={`series-${series.name}`}
                type="monotone"
                dataKey={series.name}
                name={series.name}
                stroke={series.color || settings.colors[index % settings.colors.length]}
                fill={series.color || settings.colors[index % settings.colors.length]}
                yAxisId={series.yAxisPosition || 'left'}
                isAnimationActive={false}
                dot={false}
              />
            );
          })}
          
          {settings.showTooltip && (
            <Tooltip
              labelFormatter={formatTimestamp}
              formatter={(value) => [parseFloat(value as string).toFixed(2)]}
              wrapperStyle={{ fontSize: '11px' }}
              itemStyle={{ padding: '2px 0' }}
            />
          )}
          
          {settings.showLegend && (
            <Legend 
              wrapperStyle={{ fontSize: '10px', marginTop: '5px' }}
              iconSize={8}
            />
          )}
        </ChartComponent>
      </ResponsiveContainer>
    );
  };
  
  // Refresh data manually
  const handleRefresh = () => {
    fetchData();
  };
  
  // Handle config toggle
  const handleConfigToggle = () => {
    console.log('TimeChartComponent handleConfigToggle called');
    setShowConfigModal(!showConfigModal);
  };

  // Handle config change
  const handleConfigChange = (newConfig: any) => {
    console.log('TimeChartComponent handleConfigChange called with:', newConfig);
    console.log('DataSourceConfig before update:', config.settings?.dataSourceConfig);
    console.log('DataSourceConfig from new config:', newConfig.settings?.dataSourceConfig);
    
    // Apply the new configuration
    onConfigChange(newConfig);
    
    // Close the modal
    setShowConfigModal(false);
    
    // Fetch data with the new configuration after a short delay to ensure state is updated
    setTimeout(() => {
      console.log('Fetching data after config change with:', newConfig.settings?.dataSourceConfig);
      fetchData();
    }, 100);
  };
  
  // Add a toggle debug panel function
  const toggleDebugPanel = () => {
    // Disabled debug panel functionality
    setShowDebugPanel(false);
  };
  
  // Render error message if there's an error
  if (error) {
    return (
      <ComponentWrapper
        config={{
          ...config,
          title: config.settings?.title || defaultSettings.title, // Use chart title in window header
        }}
        onConfigChange={onConfigChange}
        onRemove={onRemove}
        onConfigToggle={handleConfigToggle}
        headerControls={
          <button 
            className="component-control" 
            onClick={handleRefresh}
            disabled={loading}
            title="Refresh"
          >
            🔄
          </button>
        }
      >
        <div className="time-chart-error">
          <p>{error}</p>
        </div>
      </ComponentWrapper>
    );
  }
  
  return (
    <ComponentWrapper
      config={{
        ...config,
        title: config.settings?.title || defaultSettings.title, // Use chart title in window header
      }}
      onConfigChange={onConfigChange}
      onRemove={onRemove}
      onConfigToggle={handleConfigToggle}
      headerControls={
        <button 
          className="component-control" 
          onClick={handleRefresh}
          disabled={loading}
          title="Refresh"
        >
          🔄
        </button>
      }
    >
      <div className="time-chart-container full-width" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className="time-chart-content full-width" style={{ flex: '1 1 auto', minHeight: '300px', position: 'relative' }}>
          {renderChart()}
          
          {showDebugPanel && debugInfo && (
            <div className="debug-panel" style={{
              padding: '10px',
              margin: '10px 0',
              border: '1px solid #ddd',
              borderRadius: '4px',
              backgroundColor: '#f7f7f7',
              fontSize: '12px',
              maxHeight: '300px',
              overflow: 'auto',
              wordBreak: 'break-word',
              color: '#000'
            }}>
              <h4 style={{ margin: '0 0 10px 0', color: '#000', fontWeight: 'bold' }}>Debug Information</h4>
              <p style={{ color: '#000' }}><strong>Request Time:</strong> {new Date(debugInfo.startTime).toLocaleString()}</p>
              <p style={{ color: '#000' }}><strong>Duration:</strong> {debugInfo.duration}ms</p>
              
              <h5 style={{ margin: '10px 0 5px 0', color: '#000', fontWeight: 'bold' }}>Series Data</h5>
              {debugInfo.series.length === 0 ? (
                <p style={{ color: '#000' }}>No series configured</p>
              ) : (
                debugInfo.series.map((series: any, index: number) => (
                  <div key={index} style={{ 
                    marginBottom: '10px', 
                    padding: '5px', 
                    backgroundColor: series.response.success ? '#e0ffe0' : '#ffe0e0',
                    borderRadius: '4px',
                    color: '#000',
                    border: '1px solid ' + (series.response.success ? '#a0d0a0' : '#d0a0a0')
                  }}>
                    <p style={{ color: '#000', fontWeight: 'bold' }}><strong>{series.name}</strong> ({series.sensorType}: {series.sensorId})</p>
                    <p style={{ color: '#000' }}>Data points: {series.response.dataPoints}</p>
                    {series.response.error && <p style={{ color: '#cc0000', fontWeight: 'bold' }}>Error: {series.response.error}</p>}
                    {series.response.dataPoints > 0 && (
                      <div>
                        <p style={{ color: '#000', fontWeight: 'bold' }}>Sample data:</p>
                        <pre style={{ 
                          fontSize: '11px', 
                          maxWidth: '100%', 
                          overflow: 'auto', 
                          backgroundColor: '#fff',
                          padding: '5px',
                          border: '1px solid #ccc',
                          color: '#222' 
                        }}>
                          {JSON.stringify(series.response.sampleData, null, 2)}
                        </pre>
                      </div>
                    )}
                  </div>
                ))
              )}
              
              {debugInfo.errors.length > 0 && (
                <div>
                  <h5 style={{ margin: '10px 0 5px 0', color: '#000', fontWeight: 'bold' }}>Errors</h5>
                  <ul>
                    {debugInfo.errors.map((error: string, index: number) => (
                      <li key={index} style={{ color: '#cc0000', fontWeight: 'bold' }}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showConfigModal && (
        <TimeChartConfig
          config={config}
          onClose={() => setShowConfigModal(false)}
          onConfigChange={handleConfigChange}
        />
      )}
    </ComponentWrapper>
  );
};

export default TimeChartComponent; 