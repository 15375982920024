import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { usePage } from '../context/PageContext';
import SideMenu from './SideMenu';
import '../App.css';

const Navbar: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPage } = usePage();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  // Handle logout
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // Toggle side menu
  const toggleSideMenu = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  // Close side menu
  const closeSideMenu = () => {
    setSideMenuOpen(false);
  };

  // Navigate to profile
  const goToProfile = () => {
    navigate('/profile/edit');
  };

  // Get current page title based on route
  const getPageTitle = () => {
    const path = location.pathname;
    
    if (currentPage && (path === '/' || path === '/dashboard')) {
      return currentPage.name;
    }
    
    if (path === '/profile/edit') return 'Profile';
    if (path === '/login') return 'Login';
    if (path === '/register') return 'Register';
    if (path === '/forgot-password') return 'Forgot Password';
    if (path === '/reset-password') return 'Reset Password';
    if (path === '/admin/users') return 'Users Management';
    if (path === '/admin/users/add') return 'Add User';
    if (path === '/admin/invite') return 'Invite User';
    if (path === '/admin/activities') return 'Activity Logs';
    if (path.startsWith('/admin/activities/user/')) return 'User Activities';
    
    return '';
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-left">
          {isAuthenticated && (
            <button 
              className="hamburger-button" 
              onClick={toggleSideMenu}
              aria-label="Menu"
            >
              <span className="hamburger-line"></span>
              <span className="hamburger-line"></span>
              <span className="hamburger-line"></span>
            </button>
          )}
          <Link to="/" style={{ 
            color: '#e4c87e', 
            textDecoration: 'none', 
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginLeft: isAuthenticated ? '1rem' : '0'
          }}>
            George Ranch
          </Link>
        </div>

        {/* Centered Page Title */}
        <div style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center'
        }}>
          {getPageTitle() && (
            <span style={{
              color: '#ffffff',
              fontSize: '1.1rem',
              fontWeight: '500',
              padding: '0.3rem 0.8rem',
              backgroundColor: 'rgba(228, 200, 126, 0.2)',
              borderRadius: '4px',
              display: 'inline-block'
            }}>
              {getPageTitle()}
            </span>
          )}
        </div>
        
        <div className="navbar-right">
          {isAuthenticated ? (
            <>
              <span 
                style={{ 
                  fontWeight: '500', 
                  color: '#e4c87e',
                  cursor: 'pointer',
                  textDecoration: 'none'
                }}
                onClick={goToProfile}
                className="user-profile-link"
              >
                Welcome, {user?.firstName || user?.username}
              </span>
              
              <button 
                onClick={handleLogout}
                style={{
                  background: 'transparent',
                  border: '1px solid #e4c87e',
                  borderRadius: '4px',
                  padding: '0.4rem 0.8rem',
                  color: '#e4c87e',
                  cursor: 'pointer',
                  fontWeight: '500',
                  fontSize: '0.9rem'
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" style={{ 
                color: '#e4c87e', 
                textDecoration: 'none',
                fontWeight: '500' 
              }}>
                Login
              </Link>
              
              <Link to="/register" style={{ 
                color: '#e4c87e', 
                backgroundColor: 'rgba(228, 200, 126, 0.2)',
                textDecoration: 'none',
                padding: '0.4rem 0.8rem',
                borderRadius: '4px',
                fontWeight: '500'
              }}>
                Register
              </Link>
            </>
          )}
        </div>
      </nav>

      {/* Side Menu */}
      <SideMenu isOpen={sideMenuOpen} onClose={closeSideMenu} />
      
      {/* Overlay to capture clicks outside the menu */}
      {sideMenuOpen && (
        <div 
          className="side-menu-overlay"
          onClick={closeSideMenu}
          style={{ position: 'fixed', inset: 0, zIndex: 999998 }}
        />
      )}
    </>
  );
};

export default Navbar; 