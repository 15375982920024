import axios from 'axios';
import { getToken } from '../utils/auth';
import { API_PREFIX } from './api';

// Determine if we're in production based on hostname
const isProduction = typeof window !== 'undefined' && 
  (window.location.hostname !== 'localhost' && 
   !window.location.hostname.includes('127.0.0.1'));

const API_BASE_URL = isProduction ? 'https://api.georgectrl.com' : 'http://localhost:5200';

// Create axios instance with auth headers
const api = axios.create({
  baseURL: API_BASE_URL
});

// Add request interceptor to include auth token
api.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Interface for water flow statistics
export interface WaterFlowStats {
  last24Hours: {
    barn: number;
    well: number;
  };
  yesterday: {
    barn: number;
    well: number;
  };
  last7Days: {
    barn: number;
    well: number;
  };
  last30Days: {
    barn: number;
    well: number;
  };
}

// Interface for response
export interface WaterStatsResponse {
  success: boolean;
  data?: WaterFlowStats;
  error?: string;
  diagnostics?: any;
}

/**
 * Service for interacting with the water statistics API
 */
const waterStatsService = {
  /**
   * Get water flow statistics
   * @returns {Promise<WaterStatsResponse>} Water flow statistics
   */
  async getWaterFlowStats(): Promise<WaterStatsResponse> {
    try {
      console.log('Fetching water flow statistics');
      
      // Return empty data if no authentication
      if (!getToken()) {
        console.log('No authentication token found, returning empty data');
        return {
          success: true,
          data: {
            last24Hours: { barn: 0, well: 0 },
            yesterday: { barn: 0, well: 0 },
            last7Days: { barn: 0, well: 0 },
            last30Days: { barn: 0, well: 0 }
          }
        };
      }
      
      // Make API request
      try {
        console.log('Making API request to', `${API_PREFIX}/water-stats/flow`);
        const response = await api.get(`${API_PREFIX}/water-stats/flow`);
        
        if (!response.data.success) {
          console.log('API returned error:', response.data.error);
          throw new Error(response.data.error || 'Failed to fetch water flow statistics');
        }
        
        console.log('Water flow statistics response:', response.data);
        
        // Log diagnostics if available (helpful for debugging)
        if (response.data.diagnostics) {
          console.log('%c Water Statistics Diagnostics', 'background: #222; color: #bada55; font-size: 16px;');
          console.log('Topic Information:', response.data.diagnostics.topicInfo);
          
          console.log('Barn Sensor Data:');
          console.log('- Last 24h:', response.data.diagnostics.barn.last24h);
          console.log('- Last 7d:', response.data.diagnostics.barn.last7d);
          console.log('- Last 30d:', response.data.diagnostics.barn.last30d);
          
          console.log('Well Sensor Data:');
          console.log('- Last 24h:', response.data.diagnostics.well.last24h);
          console.log('- Last 7d:', response.data.diagnostics.well.last7d);
          console.log('- Last 30d:', response.data.diagnostics.well.last30d);
          
          if (!response.data.diagnostics.barn.last24h.recordsFound && 
              !response.data.diagnostics.barn.last7d.recordsFound && 
              !response.data.diagnostics.barn.last30d.recordsFound &&
              !response.data.diagnostics.well.last24h.recordsFound && 
              !response.data.diagnostics.well.last7d.recordsFound && 
              !response.data.diagnostics.well.last30d.recordsFound) {
            console.error('NO DATA FOUND FOR ANY TIME PERIOD. Please check if:');
            console.error('1. The topic names are correct (check logs for available topics)');
            console.error('2. There is data in the date ranges specified');
            console.error('3. The data format matches what the aggregation expects');
          }
        }
        
        // Return the data
        return {
          success: true,
          data: response.data.data,
          diagnostics: response.data.diagnostics
        };
      } catch (error) {
        console.error('Error communicating with API:', error);
        
        // Return empty data instead of an error for improved UX
        console.log('Returning empty data due to API error');
        return {
          success: true,
          data: {
            last24Hours: { barn: 0, well: 0 },
            yesterday: { barn: 0, well: 0 },
            last7Days: { barn: 0, well: 0 },
            last30Days: { barn: 0, well: 0 }
          }
        };
      }
    } catch (error) {
      console.error('Unexpected error fetching water flow statistics:', error);
      
      // Return empty data for any client-side errors
      return {
        success: true,
        data: {
          last24Hours: { barn: 0, well: 0 },
          yesterday: { barn: 0, well: 0 },
          last7Days: { barn: 0, well: 0 },
          last30Days: { barn: 0, well: 0 }
        }
      };
    }
  }
};

export default waterStatsService; 