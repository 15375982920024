import React, { useState, useEffect, FormEvent } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { validatePassword } from '../utils/validation';
import '../components/FormStyles.css';

const ResetPassword: React.FC = () => {
  const { verifyResetToken, resetPassword, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get token from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  
  // Form state
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  
  // Token verification state
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);
  const [isVerifying, setIsVerifying] = useState(true);
  
  // Error and success states
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
  const [apiError, setApiError] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  // Verify token on component mount
  useEffect(() => {
    const checkToken = async () => {
      if (!token) {
        setIsTokenValid(false);
        setIsVerifying(false);
        return;
      }
      
      try {
        const isValid = await verifyResetToken(token);
        setIsTokenValid(isValid);
      } catch (error) {
        setIsTokenValid(false);
      } finally {
        setIsVerifying(false);
      }
    };
    
    // Only check token if we haven't already submitted a password reset
    if (!isSubmitted) {
      checkToken();
    }
  }, [token, verifyResetToken, isSubmitted]);
  
  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Clear error for this field
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    
    // Clear API error when user starts typing again
    if (apiError) {
      setApiError(null);
    }
  };
  
  // Validate form
  const validateForm = (): boolean => {
    const newErrors = {
      password: validatePassword(formData.password),
      confirmPassword: !formData.confirmPassword 
        ? 'Please confirm your password' 
        : formData.password !== formData.confirmPassword 
          ? 'Passwords do not match' 
          : null
    };
    
    setErrors(newErrors);
    
    // Check if there are any errors
    return !Object.values(newErrors).some(error => error !== null);
  };
  
  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setApiError(null);
    
    // Validate form
    if (!validateForm() || !token) {
      return;
    }
    
    try {
      // Call reset password function from context
      await resetPassword(token, formData.password);
      
      // Set submitted state to true and immediately navigate to login
      setIsSubmitted(true);
      // Immediate redirect to login after successful reset
      navigate('/login', { 
        state: { 
          message: 'Your password has been reset successfully. You can now login with your new password.' 
        } 
      });
    } catch (error: any) {
      // Display error message
      setApiError(error.response?.data?.message || 'Failed to reset password. Please try again.');
    }
  };
  
  // Loading state
  if (isVerifying) {
    return (
      <div className="auth-container">
        <div className="auth-card">
          <h1 className="auth-title">Reset Password</h1>
          <p className="text-center">Verifying your reset link...</p>
        </div>
      </div>
    );
  }
  
  // Invalid token state
  if (isTokenValid === false && !isSubmitted) {
    return (
      <div className="auth-container">
        <div className="auth-card">
          <h1 className="auth-title">Invalid Link</h1>
          <p className="text-center">
            The password reset link is invalid or has expired.
          </p>
          <div className="auth-links">
            <Link to="/forgot-password">Request a new reset link</Link>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="auth-container">
      <div className="auth-card">
        <h1 className="auth-title">Reset Password</h1>
        
        {isSubmitted ? (
          <div className="success-message">
            <p>Your password has been reset successfully!</p>
            <p>Redirecting to login page...</p>
          </div>
        ) : (
          <>
            {apiError && <div className="error-message" style={{ marginBottom: '1rem' }}>{apiError}</div>}
            
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="password">New Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your new password"
                  required
                  className={errors.password ? 'error' : ''}
                />
                {errors.password && <div className="input-error">{errors.password}</div>}
              </div>
              
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm your new password"
                  required
                  className={errors.confirmPassword ? 'error' : ''}
                />
                {errors.confirmPassword && <div className="input-error">{errors.confirmPassword}</div>}
              </div>
              
              <button 
                type="submit" 
                className="auth-button" 
                disabled={isLoading}
              >
                {isLoading ? 'Resetting...' : 'Reset Password'}
              </button>
            </form>
            
            <div className="auth-links">
              <p>
                Remembered your password? <Link to="/login">Login here</Link>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword; 