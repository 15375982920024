import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ComponentProps } from '../../types';
import ComponentWrapper from '../../ComponentWrapper';
import { notificationApi, userApi } from '../../../../services/api';
import { useAuth } from '../../../../context/AuthContext';
import { debounce } from '../../../../utils/helpers';
import './NotificationMessages.css';
import MessageDetailModal from './MessageDetailModal';

// SentMessage type definition
interface SentMessage {
  _id: string;
  channel: string; // 'sms', 'email', etc.
  recipient: {
    user_id: string;
    phone?: string;
    email?: string;
  };
  message_text: string;
  html_content?: string | null;
  subject?: string;
  sent_at: string;
  created_at: string;
  recipientDetails?: Recipient; // Added field for recipient details
}

// Recipient type definition
interface Recipient {
  _id: string;
  user_id: string;
  name: string;
  email: string;
  phone: string;
  device_tokens: string[];
  notification_preferences: {
    high_priority: { channels: string[] };
    medium_priority: { channels: string[] };
    low_priority: { channels: string[] };
  };
  active: boolean;
}

// Pagination type
interface Pagination {
  total: number;
  page: number;
  limit: number;
  pages: number;
}

// Default settings for the component
export const defaultSettings = {
  title: 'Notification Messages',
  refreshInterval: 0, // 0 means manual refresh only
  limit: 10,
  showFilters: true,
  filters: {
    channel: '',
    userId: '',
    startDate: '',
    endDate: ''
  },
  columns: {
    sent_at: true,
    channel: true,
    recipient: true,
    subject: true,
    message: true
  },
  messagePreviewLength: 50 // Preview length for message text
};

const NotificationMessagesComponent: React.FC<ComponentProps> = ({ config, onConfigChange, onRemove }) => {
  const { isAuthenticated, user } = useAuth();
  
  // Initialize settings if they don't exist
  useEffect(() => {
    if (!config.settings) {
      onConfigChange({
        ...config,
        settings: defaultSettings
      });
    }
  }, [config, onConfigChange]);
  
  // State for messages and pagination
  const [messages, setMessages] = useState<SentMessage[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    page: 1,
    limit: config.settings?.limit || defaultSettings.limit,
    pages: 0
  });
  
  // Update pagination when config.settings.limit changes
  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      limit: config.settings?.limit || defaultSettings.limit
    }));
  }, [config.settings?.limit]);
  
  // Reference to store the previous filters
  const prevFiltersRef = useRef(config.settings?.filters || defaultSettings.filters);
  
  // State for filters
  const [filters, setFilters] = useState(config.settings?.filters || defaultSettings.filters);
  
  // State for filter visibility
  const [filterVisible, setFilterVisible] = useState(false);
  
  // Update filters when config.settings.filters changes
  useEffect(() => {
    const currentFilters = config.settings?.filters || defaultSettings.filters;
    // Only update if the filters have actually changed to avoid infinite loops
    if (JSON.stringify(prevFiltersRef.current) !== JSON.stringify(currentFilters)) {
      setFilters(currentFilters);
      prevFiltersRef.current = currentFilters;
    }
  }, [config.settings?.filters]);
  
  // State for recipients list
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  
  // State for loading
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Notification channels for filtering
  const notificationChannels = notificationApi.getNotificationChannels();
  
  // Reference to store the current config
  const configRef = useRef(config);
  
  // Update config ref when config changes
  useEffect(() => {
    configRef.current = config;
  }, [config]);
  
  // Debounced function to update config
  const debouncedConfigUpdate = useCallback(
    debounce((newFilters) => {
      onConfigChange({
        ...configRef.current,
        settings: {
          ...configRef.current.settings,
          filters: newFilters
        }
      });
    }, 500),
    [onConfigChange]
  );
  
  // Fetch messages with debounce
  const fetchMessages = useCallback(debounce(async () => {
    if (!isAuthenticated) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await notificationApi.getSentMessages({
        page: pagination.page,
        limit: pagination.limit,
        channel: filters.channel || undefined,
        userId: filters.userId || undefined,
        startDate: filters.startDate || undefined,
        endDate: filters.endDate || undefined
      });
      
      setMessages(response.data.data.messages);
      setPagination(response.data.data.pagination);
    } catch (err) {
      console.error('Error fetching notification messages:', err);
      setError('Failed to load notification messages. Please try again.');
    } finally {
      setLoading(false);
    }
  }, 300), [isAuthenticated, pagination.page, pagination.limit, filters]);
  
  // Fetch recipients
  const fetchRecipients = useCallback(async () => {
    if (!isAuthenticated) return;
    
    try {
      const response = await notificationApi.getRecipients();
      setRecipients(response.data.data.recipients);
    } catch (err) {
      console.error('Error fetching recipients:', err);
      // Don't set error state here to avoid blocking the messages display
    }
  }, [isAuthenticated]);
  
  // Initial data fetch
  useEffect(() => {
    fetchMessages();
    fetchRecipients();
  }, [fetchMessages, fetchRecipients]);
  
  // Set up refresh interval if configured
  useEffect(() => {
    if (config.settings?.refreshInterval && config.settings?.refreshInterval > 0) {
      const intervalId = setInterval(() => {
        fetchMessages();
      }, config.settings?.refreshInterval * 1000);
      
      return () => clearInterval(intervalId);
    }
  }, [config.settings?.refreshInterval, fetchMessages]);
  
  // Handle filter change
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFilters = { ...filters, [name]: value };
    
    setFilters(newFilters);
    
    // Update component config with debounce
    debouncedConfigUpdate(newFilters);
    
    // Reset to first page
    setPagination(prev => ({ ...prev, page: 1 }));
  };
  
  // Apply filters
  const applyFilters = (e: React.FormEvent) => {
    e.preventDefault();
    fetchMessages();
  };
  
  // Reset filters
  const resetFilters = () => {
    const defaultFilters = defaultSettings.filters;
    setFilters(defaultFilters);
    
    // Update component config using the debounced function
    debouncedConfigUpdate(defaultFilters);
    
    setPagination(prev => ({ ...prev, page: 1 }));
    fetchMessages();
  };
  
  // Format date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Format message preview
  const formatMessagePreview = (text: string) => {
    const maxLength = config.settings?.messagePreviewLength || defaultSettings.messagePreviewLength;
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };
  
  // Get recipient display
  const getRecipientDisplay = (message: SentMessage) => {
    if (message.channel === 'email' && message.recipient.email) {
      return message.recipient.email;
    }
    if (message.channel === 'sms' && message.recipient.phone) {
      return message.recipient.phone;
    }
    return message.recipient.user_id;
  };
  
  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > pagination.pages) return;
    
    setPagination(prev => ({ ...prev, page: newPage }));
  };
  
  // Find recipient by user_id
  const findRecipientByUserId = useCallback((userId: string) => {
    return recipients.find(r => r.user_id === userId);
  }, [recipients]);
  
  // Add a ref to track which message IDs have been processed
  const processedMessageIds = useRef<Set<string>>(new Set());
  
  // Enhance messages with recipient data
  useEffect(() => {
    const enhanceMessages = () => {
      if (messages.length === 0 || recipients.length === 0) return;
      
      // Check if we need to process any messages
      const unprocessedMessages = messages.filter(
        msg => !msg.recipientDetails && !processedMessageIds.current.has(msg._id)
      );
      
      if (unprocessedMessages.length === 0) return;
      
      const enhancedMessages = messages.map(message => {
        // Skip if message already has recipient data or has been processed
        if (message.recipientDetails || processedMessageIds.current.has(message._id)) return message;
        
        // Mark this message as processed
        processedMessageIds.current.add(message._id);
        
        const userId = message.recipient.user_id;
        const recipientData = findRecipientByUserId(userId);
        
        return {
          ...message,
          recipientDetails: recipientData
        };
      });
      
      setMessages(enhancedMessages);
    };
    
    enhanceMessages();
  }, [messages, recipients, findRecipientByUserId]);
  
  // Check if there are active filters
  const hasActiveFilters = () => {
    return !!(
      filters.channel || 
      filters.userId || 
      filters.startDate || 
      filters.endDate
    );
  };
  
  // Get applied filters summary text
  const getAppliedFiltersSummary = () => {
    const appliedFilters = [];
    
    if (filters.channel) {
      const channel = notificationChannels.find(c => c.value === filters.channel);
      appliedFilters.push(`Channel: ${channel?.label || filters.channel}`);
    }
    
    if (filters.userId) {
      const recipient = recipients.find(r => r.user_id === filters.userId);
      appliedFilters.push(`Recipient: ${recipient?.name || filters.userId}`);
    }
    
    if (filters.startDate) {
      appliedFilters.push(`From: ${new Date(filters.startDate).toLocaleDateString()}`);
    }
    
    if (filters.endDate) {
      appliedFilters.push(`To: ${new Date(filters.endDate).toLocaleDateString()}`);
    }
    
    return appliedFilters.join(' | ');
  };
  
  // Toggle filter visibility
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };
  
  // Format channel badge
  const formatChannelBadge = (channel: string) => {
    const channelInfo = notificationChannels.find(c => c.value === channel);
    const label = channelInfo?.label || channel;
    
    let badgeClass = 'channel-badge';
    if (channel === 'email') badgeClass += ' email-badge';
    if (channel === 'sms') badgeClass += ' sms-badge';
    
    return (
      <span className={badgeClass}>
        {label}
      </span>
    );
  };
  
  // Content to display when there are no messages
  const noMessagesContent = (
    <div className="no-messages">
      <p>No notification messages found.</p>
      {config.settings?.showFilters && (
        <p>Try adjusting your filters or refreshing the page.</p>
      )}
    </div>
  );
  
  // Handle config toggle
  const handleConfigToggle = useCallback(() => {
    // The ComponentWrapper will handle the config panel visibility internally
    // Do not add showConfig to the config object - it's not in the ComponentConfig type
  }, []);
  
  // State for selected message and modal visibility
  const [selectedMessage, setSelectedMessage] = useState<SentMessage | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Handle row click
  const handleRowClick = (message: SentMessage) => {
    setSelectedMessage(message);
    setIsModalOpen(true);
  };
  
  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <ComponentWrapper 
      config={config} 
      onConfigChange={onConfigChange} 
      onRemove={onRemove} 
      onConfigToggle={handleConfigToggle}
    >
      <div className="notification-messages-component">
        {/* Message Detail Modal */}
        <MessageDetailModal 
          isOpen={isModalOpen} 
          onClose={closeModal} 
          message={selectedMessage} 
        />
        
        {/* Filters */}
        {config.settings?.showFilters && (
          <div className="filter-container">
            <div className="filter-header" onClick={toggleFilterVisibility}>
              <h4>Filters {hasActiveFilters() && <span className="filter-badge">Active</span>}</h4>
              <button className="filter-toggle-button">
                {filterVisible ? '▲ Hide Filters' : '▼ Show Filters'}
              </button>
            </div>
            
            {/* Applied filters summary */}
            {!filterVisible && hasActiveFilters() && (
              <div className="applied-filters-summary">
                {getAppliedFiltersSummary()}
                <button 
                  className="reset-filters-button" 
                  onClick={(e) => {
                    e.stopPropagation();
                    resetFilters();
                  }}
                >
                  Clear
                </button>
              </div>
            )}
            
            {/* Filter section */}
            {filterVisible && (
              <div className="filter-section">
                <form onSubmit={applyFilters}>
                  <div className="filter-row">
                    <div className="filter-item">
                      <label htmlFor="channel">Channel</label>
                      <select
                        id="channel"
                        name="channel"
                        value={filters.channel}
                        onChange={handleFilterChange}
                        className="form-control"
                      >
                        <option value="">All Channels</option>
                        {notificationChannels.map(channel => (
                          <option key={channel.value} value={channel.value}>
                            {channel.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    
                    <div className="filter-item">
                      <label htmlFor="userId">Recipient</label>
                      <select
                        id="userId"
                        name="userId"
                        value={filters.userId}
                        onChange={handleFilterChange}
                        className="form-control"
                      >
                        <option value="">All Recipients</option>
                        {recipients.map(recipient => (
                          <option key={recipient._id} value={recipient.user_id}>
                            {recipient.name || recipient.user_id}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  <div className="filter-row">
                    <div className="filter-item">
                      <label htmlFor="startDate">From Date</label>
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={filters.startDate}
                        onChange={handleFilterChange}
                        className="form-control"
                      />
                    </div>
                    
                    <div className="filter-item">
                      <label htmlFor="endDate">To Date</label>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={filters.endDate}
                        onChange={handleFilterChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  
                  <div className="filter-actions">
                    <button type="submit" className="btn btn-primary">Apply Filters</button>
                    <button type="button" className="btn btn-secondary" onClick={resetFilters}>Reset</button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
        
        {/* Error Message */}
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
        {/* Messages Table */}
        <div className="table-container">
          {loading ? (
            <div className="loading">Loading notification messages...</div>
          ) : (
            <>
              <table className="data-table">
                <thead>
                  <tr>
                    {config.settings?.columns.sent_at && <th>Date/Time</th>}
                    {config.settings?.columns.channel && <th>Channel</th>}
                    {config.settings?.columns.recipient && <th>Recipient</th>}
                    {config.settings?.columns.subject && <th>Subject</th>}
                    {config.settings?.columns.message && <th>Message</th>}
                  </tr>
                </thead>
                <tbody>
                  {messages.length === 0 ? (
                    <tr>
                      <td colSpan={Object.values(config.settings?.columns || {}).filter(Boolean).length} className="no-data">
                        No notification messages found
                      </td>
                    </tr>
                  ) : (
                    messages.map(message => (
                      <tr key={message._id} onClick={() => handleRowClick(message)}>
                        {config.settings?.columns.sent_at && (
                          <td className="timestamp-cell">{formatDate(message.sent_at)}</td>
                        )}
                        {config.settings?.columns.channel && (
                          <td className="channel-cell">
                            {formatChannelBadge(message.channel)}
                          </td>
                        )}
                        {config.settings?.columns.recipient && (
                          <td className="recipient-cell">
                            <div className="recipient-info">
                              <div className="recipient-name">
                                {message.recipientDetails?.name || message.recipient.user_id}
                              </div>
                              <div className="recipient-contact">
                                {getRecipientDisplay(message)}
                              </div>
                            </div>
                          </td>
                        )}
                        {config.settings?.columns.subject && (
                          <td className="subject-cell">
                            {message.subject || '-'}
                          </td>
                        )}
                        {config.settings?.columns.message && (
                          <td className="message-cell">
                            {formatMessagePreview(message.message_text)}
                          </td>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              
              {/* Pagination Controls */}
              {pagination.pages > 1 && (
                <div className="pagination-controls">
                  <button
                    className="pagination-button"
                    onClick={() => handlePageChange(1)}
                    disabled={pagination.page === 1}
                  >
                    First
                  </button>
                  <button
                    className="pagination-button"
                    onClick={() => handlePageChange(pagination.page - 1)}
                    disabled={pagination.page === 1}
                  >
                    Previous
                  </button>
                  <span className="page-info">
                    Page {pagination.page} of {pagination.pages}
                  </span>
                  <button
                    className="pagination-button"
                    onClick={() => handlePageChange(pagination.page + 1)}
                    disabled={pagination.page === pagination.pages}
                  >
                    Next
                  </button>
                  <button
                    className="pagination-button"
                    onClick={() => handlePageChange(pagination.pages)}
                    disabled={pagination.page === pagination.pages}
                  >
                    Last
                  </button>
                </div>
              )}
              
              {/* Messages summary */}
              <div className="messages-summary">
                Total: {pagination.total} message{pagination.total !== 1 ? 's' : ''}
                {pagination.total > 0 && (
                  <button className="refresh-button" onClick={() => fetchMessages()}>
                    Refresh
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </ComponentWrapper>
  );
};

export default NotificationMessagesComponent; 