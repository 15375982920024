import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ComponentConfig, ComponentProps } from './types';
import './ComponentStyles.css';
import './ComponentDebugStyles.css';
import LockIcon from './LockIcon';
import { usePage } from '../../context/PageContext';

interface ComponentWrapperProps extends ComponentProps {
  children: React.ReactNode;
  onConfigToggle?: () => void;
  headerControls?: React.ReactNode;
}

// Constants for minimum window size
const MIN_WIDTH = 200;
const MIN_HEIGHT = 150;
const updateThreshold = 16; // ~60fps

// Throttle function to limit the rate of function calls
function throttle<T extends (...args: any[]) => any>(func: T, limit: number): (...args: Parameters<T>) => ReturnType<T> {
  let inThrottle: boolean = false;
  let lastResult: ReturnType<T>;
  
  return function(this: any, ...args: Parameters<T>): ReturnType<T> {
    if (!inThrottle) {
      inThrottle = true;
      lastResult = func.apply(this, args);
      
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
    
    return lastResult;
  };
}

// Define a type for position updates
type PositionUpdate = Partial<{
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
}>;

// Define a debug logger function
const debugLog = (message: string, data?: any) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`%c[DragDrop] ${message}`, 'color: #4CAF50; font-weight: bold;', data || '');
  }
};

// Performance monitoring
const perfLog = (label: string, startTime: number) => {
  if (process.env.NODE_ENV !== 'production') {
    const duration = performance.now() - startTime;
    console.log(`%c[Performance] ${label}: ${duration.toFixed(2)}ms`, 'color: #2196F3; font-weight: bold;');
  }
};

// Global z-index counter for all components - this ensures each newly focused window gets highest z-index
let globalZIndexCounter = 1000;

const ComponentWrapper: React.FC<ComponentWrapperProps> = ({
  config,
  onConfigChange,
  onRemove,
  children,
  onConfigToggle,
  headerControls
}) => {
  // State for dragging and resizing
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [showConfig, setShowConfig] = useState(false);
  
  // Add state variables to track previous drag/resize state
  const [prevIsDragging, setPrevIsDragging] = useState(false);
  const [prevIsResizing, setPrevIsResizing] = useState(false);
  
  // Add state for maximized and store original position before maximizing
  const [isMaximized, setIsMaximized] = useState(false);
  const [preMaximizePosition, setPreMaximizePosition] = useState({
    x: 0, y: 0, width: 0, height: 0, zIndex: 0
  });
  
  // Add a lock to prevent resize handler from triggering right after drag
  const [postDragLock, setPostDragLock] = useState(false);
  
  // Local position state to avoid excessive re-renders
  const [localPosition, setLocalPosition] = useState({
    x: config.position.x,
    y: config.position.y,
    width: config.position.width,
    height: config.position.height,
    zIndex: config.position.zIndex
  });
  
  // Refs
  const componentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const configRef = useRef(config);
  const positionRef = useRef(localPosition);
  
  // Refs for dragging and resizing state
  const isDraggingRef = useRef(isDragging);
  const isResizingRef = useRef(isResizing);
  
  // Ref for start positions
  const startMousePos = useRef({ x: 0, y: 0 });
  const startComponentPos = useRef({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    zIndex: 0
  });
  
  // Performance tracking refs
  const renderStartTime = useRef(performance.now());
  const moveStartTime = useRef(0);
  const moveCount = useRef(0);
  const lastMoveTime = useRef(0);
  
  // Log render performance
  useEffect(() => {
    perfLog('Component render', renderStartTime.current);
    renderStartTime.current = performance.now();
  });
  
  // Update refs when props change
  useEffect(() => {
    configRef.current = config;
    
    // Only update local position if not dragging/resizing
    if (!isDragging && !isResizing) {
      debugLog('Updating position from config', {
        from: positionRef.current,
        to: config.position
      });
      
      setLocalPosition({
        x: config.position.x,
        y: config.position.y,
        width: config.position.width,
        height: config.position.height,
        zIndex: config.position.zIndex
      });
      
      positionRef.current = {
        x: config.position.x,
        y: config.position.y,
        width: config.position.width,
        height: config.position.height,
        zIndex: config.position.zIndex
      };
    } else {
      debugLog('Skipping position update from config during drag/resize');
    }
  }, [config, isDragging, isResizing]);
  
  // Update position ref when local position changes
  useEffect(() => {
    positionRef.current = localPosition;
  }, [localPosition]);
  
  // Store original dimensions for proportional resizing during window resize
  const [originalDimensions, setOriginalDimensions] = useState({
    containerWidth: 0,
    containerHeight: 0,
    x: config.position.x,
    y: config.position.y,
    width: config.position.width,
    height: config.position.height,
    xRatio: 0,
    yRatio: 0,
    widthRatio: 0,
    heightRatio: 0
  });
  
  // Original dimensions ref to avoid dependency issues
  const originalDimensionsRef = useRef(originalDimensions);
  
  // Update original dimensions ref when state changes
  useEffect(() => {
    originalDimensionsRef.current = originalDimensions;
  }, [originalDimensions]);
  
  // Get reference to the container element and initialize dimensions
  useEffect(() => {
    containerRef.current = document.querySelector('.page-container');
    
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const containerHeight = containerRect.height;
      
      if (containerWidth > 0 && containerHeight > 0) {
        const newDimensions = {
          containerWidth,
          containerHeight,
          x: config.position.x,
          y: config.position.y,
          width: config.position.width,
          height: config.position.height,
          xRatio: config.position.x / containerWidth,
          yRatio: config.position.y / containerHeight,
          widthRatio: config.position.width / containerWidth,
          heightRatio: config.position.height / containerHeight
        };
        
        setOriginalDimensions(newDimensions);
        originalDimensionsRef.current = newDimensions;
      }
    }
  }, []);
  
  // Throttled function to update config
  const throttledConfigUpdate = useCallback(
    throttle((newPosition: PositionUpdate) => {
      debugLog('Throttled config update', newPosition);
      onConfigChange({
        ...configRef.current,
        position: {
          ...configRef.current.position,
          ...newPosition
        }
      });
    }, 50), // 50ms throttle
    [onConfigChange]
  );
  
  // Define window resize handler outside of useEffect
  const handleWindowResize = useCallback(() => {
    // Skip if dragging or resizing is in progress
    if (isDragging || isResizing) {
      debugLog('Window resize ignored during drag/resize');
      return;
    }
    
    debugLog('Window resize handler called');
    
    if (!componentRef.current || !containerRef.current) return;
    
    const containerRect = containerRef.current.getBoundingClientRect();
    const newContainerWidth = containerRect.width;
    const newContainerHeight = containerRect.height;
    
    // Skip if container dimensions are 0 (not fully rendered)
    if (newContainerWidth === 0 || newContainerHeight === 0) return;
    
    // Check if this might be a browser maximize event
    const isLikelyMaximizeEvent = 
      newContainerWidth >= window.screen.availWidth - 50 &&
      newContainerHeight >= window.screen.availHeight - 150;
    
    // If original dimensions are not set or are invalid, or this is a maximize event, initialize them
    const origDims = originalDimensionsRef.current;
    if (origDims.containerWidth === 0 || origDims.containerHeight === 0 || isLikelyMaximizeEvent) {
      // Initialize with current values
      const currentPosition = configRef.current.position;
      originalDimensionsRef.current = {
        containerWidth: newContainerWidth,
        containerHeight: newContainerHeight,
        x: currentPosition.x,
        y: currentPosition.y,
        width: currentPosition.width,
        height: currentPosition.height,
        xRatio: currentPosition.x / newContainerWidth,
        yRatio: currentPosition.y / newContainerHeight,
        widthRatio: currentPosition.width / newContainerWidth,
        heightRatio: currentPosition.height / newContainerHeight
      };
      
      if (isLikelyMaximizeEvent) {
        debugLog('Maximize event detected - reinitializing dimensions', originalDimensionsRef.current);
      } else {
        debugLog('Original dimensions initialized', originalDimensionsRef.current);
      }
      
      // Don't return immediately for maximize events - continue to resize
      if (!isLikelyMaximizeEvent) {
        return; // Return after initialization to avoid immediate resize for normal events
      }
    }
    
    // For significant size changes (like maximize/restore), we want to always recalculate
    // Lowered threshold for container size changes
    const containerWidthChanged = Math.abs(origDims.containerWidth - newContainerWidth) > 2;
    const containerHeightChanged = Math.abs(origDims.containerHeight - newContainerHeight) > 2;
    
    if (!containerWidthChanged && !containerHeightChanged) {
      debugLog('Window resize ignored - container size unchanged');
      return;
    }
    
    // Update the original dimensions reference to the new container size
    // This is especially important for maximize events
    origDims.containerWidth = newContainerWidth;
    origDims.containerHeight = newContainerHeight;
    
    // Calculate new dimensions proportionally based on container size change
    let newWidth = Math.max(
      MIN_WIDTH,
      Math.floor(origDims.widthRatio * newContainerWidth)
    );
    
    let newHeight = Math.max(
      MIN_HEIGHT,
      Math.floor(origDims.heightRatio * newContainerHeight)
    );
    
    // Calculate new position proportionally
    let newX = Math.floor(origDims.xRatio * newContainerWidth);
    let newY = Math.floor(origDims.yRatio * newContainerHeight);
    
    // Ensure component doesn't exceed container bounds
    if (newX + newWidth > newContainerWidth) {
      if (newWidth <= MIN_WIDTH) {
        newX = Math.max(0, newContainerWidth - newWidth);
      } else {
        newWidth = newContainerWidth - newX;
        
        if (newWidth < MIN_WIDTH) {
          newWidth = MIN_WIDTH;
          newX = Math.max(0, newContainerWidth - MIN_WIDTH);
        }
      }
    }
    
    // Same logic for height
    if (newY + newHeight > newContainerHeight) {
      if (newHeight <= MIN_HEIGHT) {
        newY = Math.max(0, newContainerHeight - newHeight);
      } else {
        newHeight = newContainerHeight - newY;
        
        if (newHeight < MIN_HEIGHT) {
          newHeight = MIN_HEIGHT;
          newY = Math.max(0, newContainerHeight - MIN_HEIGHT);
        }
      }
    }
    
    // Update component position and size if changed
    const currentConfig = configRef.current;
    if (
      newX !== currentConfig.position.x ||
      newY !== currentConfig.position.y ||
      newWidth !== currentConfig.position.width ||
      newHeight !== currentConfig.position.height
    ) {
      // Update local position first
      setLocalPosition({
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
        zIndex: currentConfig.position.zIndex
      });
      
      // Then update config with a flag indicating this is a scale operation (non-persistent)
      onConfigChange({
        ...currentConfig,
        position: {
          ...currentConfig.position,
          x: newX,
          y: newY,
          width: newWidth,
          height: newHeight,
          zIndex: currentConfig.position.zIndex
        },
        // Add flag to indicate this is a non-persistent scaling change
        _scaleChange: true
      });
    }
  }, [isDragging, isResizing, onConfigChange]);
  
  // Handle window resize - ONLY when not dragging or resizing
  useEffect(() => {
    // Throttled window resize handler
    const throttledResizeHandler = throttle(handleWindowResize, 100);
    
    // Add event listener for window resize
    window.addEventListener('resize', throttledResizeHandler);
    
    // Initial check to ensure component is within bounds
    // Call this unconditionally to ensure proper initialization
    handleWindowResize();
    
    // Clean up
    return () => {
      window.removeEventListener('resize', throttledResizeHandler);
    };
  }, [handleWindowResize]);
  
  // Check if the current page is locked
  const { currentPage } = usePage();
  const isPageLocked = currentPage?.isLocked;
  
  // Handle mouse down for dragging
  const handleMouseDown = (e: React.MouseEvent) => {
    // If component is locked, maximized, or page is locked, prevent dragging
    if (config.isLocked || isPageLocked || isMaximized) {
      // If maximized, don't show any notifications as this is expected behavior
      if (!isMaximized) {
        // If page is locked but component is not locked, show notification
        if (isPageLocked && !config.isLocked) {
          // Dispatch a notification event
          const event = new CustomEvent('page-lock-notification', {
            detail: {
              message: 'Cannot move components on a locked page',
              type: 'warning'
            }
          });
          document.dispatchEvent(event);
        }
      }
      return;
    }
    
    // Only handle left mouse button
    if (e.button !== 0) return;
    
    // Prevent default behavior
    e.preventDefault();
    e.stopPropagation();
    
    // Set dragging state
    setIsDragging(true);
    isDraggingRef.current = true;
    
    // Store starting positions
    startMousePos.current = {
      x: e.clientX,
      y: e.clientY
    };
    
    startComponentPos.current = {
      x: localPosition.x,
      y: localPosition.y,
      width: localPosition.width,
      height: localPosition.height,
      zIndex: localPosition.zIndex
    };
    
    // Increment global z-index counter to ensure this window gets the highest z-index
    globalZIndexCounter += 10;
    
    // Update local position z-index immediately when drag starts
    setLocalPosition(prev => ({
      ...prev,
      zIndex: globalZIndexCounter
    }));
    
    // Update DOM element z-index immediately
    if (componentRef.current) {
      componentRef.current.style.zIndex = `${globalZIndexCounter}`;
    }
    
    // Reset update time
    lastMoveTime.current = performance.now();
    moveStartTime.current = performance.now();
    moveCount.current = 0;
    
    // Bring component to front
    handleBringToFront();
    
    debugLog('Started dragging', {
      startMousePos: startMousePos.current,
      startComponentPos: startComponentPos.current
    });
  };
  
  // Handle mouse down for resizing
  const handleResizeMouseDown = (e: React.MouseEvent) => {
    // If component is locked, maximized, or page is locked, prevent resizing
    if (config.isLocked || isPageLocked || isMaximized) {
      // If maximized, don't show any notifications as this is expected behavior
      if (!isMaximized) {
        // If page is locked but component is not locked, show notification
        if (isPageLocked && !config.isLocked) {
          // Dispatch a notification event
          const event = new CustomEvent('page-lock-notification', {
            detail: {
              message: 'Cannot resize components on a locked page',
              type: 'warning'
            }
          });
          document.dispatchEvent(event);
        }
      }
      return;
    }
    
    // Only handle left mouse button
    if (e.button !== 0) return;
    
    // Prevent default behavior
    e.preventDefault();
    e.stopPropagation();
    
    // Set resizing state
    setIsResizing(true);
    isResizingRef.current = true;
    
    // Store starting positions
    startMousePos.current = {
      x: e.clientX,
      y: e.clientY
    };
    
    startComponentPos.current = {
      x: localPosition.x,
      y: localPosition.y,
      width: localPosition.width,
      height: localPosition.height,
      zIndex: localPosition.zIndex
    };
    
    // Increment global z-index counter to ensure this window gets the highest z-index
    globalZIndexCounter += 10;
    
    // Update local position z-index immediately when resize starts
    setLocalPosition(prev => ({
      ...prev,
      zIndex: globalZIndexCounter
    }));
    
    // Update DOM element z-index immediately
    if (componentRef.current) {
      componentRef.current.style.zIndex = `${globalZIndexCounter}`;
    }
    
    // Reset update time
    lastMoveTime.current = performance.now();
    moveStartTime.current = performance.now();
    moveCount.current = 0;
    
    debugLog('Started resizing', {
      startMousePos: startMousePos.current,
      startComponentPos: startComponentPos.current
    });
  };
  
  // Update refs when state changes
  useEffect(() => {
    isDraggingRef.current = isDragging;
    isResizingRef.current = isResizing;
    
    // Update previous state variables for next render
    setPrevIsDragging(isDragging);
    setPrevIsResizing(isResizing);
    
    if (isDragging) {
      debugLog('Dragging state updated', { isDragging });
    }
    if (isResizing) {
      debugLog('Resizing state updated', { isResizing });
    }
  }, [isDragging, isResizing]);
  
  // Handle mouse move during dragging or resizing
  const handleMouseMoveAction = useCallback((e: MouseEvent) => {
    if (!isDragging && !isResizing) return;
    
    const now = performance.now();
    
    // Calculate changes
    const deltaX = e.clientX - startMousePos.current.x;
    const deltaY = e.clientY - startMousePos.current.y;
    
    if (isDragging) {
      // Calculate new position within container bounds
      const containerRect = containerRef.current?.getBoundingClientRect();
      if (!containerRect) return;
      
      const newX = Math.max(0, Math.min(startComponentPos.current.x + deltaX, containerRect.width - startComponentPos.current.width));
      const newY = Math.max(0, Math.min(startComponentPos.current.y + deltaY, containerRect.height - startComponentPos.current.height));
      
      // Update local position for smoother UI updates
      setLocalPosition(prev => ({
        ...prev,
        x: newX,
        y: newY
      }));
      
      // Always keep positionRef updated with the latest position
      positionRef.current = {
        ...positionRef.current,
        x: newX,
        y: newY
      };
      
      // Update debug info
      setDebugInfo(prev => ({
        ...prev,
        dragCount: prev.dragCount + 1,
        lastUpdateTime: now,
        updateFrequency: prev.lastUpdateTime > 0 ? now - prev.lastUpdateTime : 0
      }));
      
      // Throttle the actual config updates for better performance
      if (now - lastMoveTime.current > updateThreshold) {
        // Send position update to parent component
        onConfigChange({
          ...configRef.current,
          position: {
            ...configRef.current.position,
            x: newX,
            y: newY,
            zIndex: Date.now() // Ensure dragged component is on top
          }
        });
        lastMoveTime.current = now;
      }
    } else if (isResizing) {
      // Update size for resizing
      const newWidth = Math.max(MIN_WIDTH, startComponentPos.current.width + deltaX);
      const newHeight = Math.max(MIN_HEIGHT, startComponentPos.current.height + deltaY);
      
      // Update local position for smoother UI updates
      setLocalPosition(prev => ({
        ...prev,
        width: newWidth,
        height: newHeight
      }));
      
      // Always keep positionRef updated with the latest size
      positionRef.current = {
        ...positionRef.current,
        width: newWidth,
        height: newHeight
      };
      
      // Update debug info
      setDebugInfo(prev => ({
        ...prev,
        resizeCount: prev.resizeCount + 1,
        lastUpdateTime: now,
        updateFrequency: prev.lastUpdateTime > 0 ? now - prev.lastUpdateTime : 0
      }));
      
      // Throttle the actual config updates for better performance
      if (now - lastMoveTime.current > updateThreshold) {
        onConfigChange({
          ...configRef.current,
          position: {
            ...configRef.current.position,
            width: newWidth,
            height: newHeight
          }
        });
        lastMoveTime.current = now;
      }
    }
  }, [isDragging, isResizing, onConfigChange, configRef]);
  
  // Add mouse event listeners when dragging or resizing starts
  useEffect(() => {
    if (isDragging || isResizing) {
      debugLog('Added mouse event listeners');
      
      const handleMouseMove = (e: MouseEvent) => {
        handleMouseMoveAction(e);
      };
      
      const handleMouseUp = () => {
        // Save the current state before resetting isDragging/isResizing
        const wasDragging = isDragging;
        const wasResizing = isResizing;
        const finalPosition = { ...localPosition };
        
        // Reset dragging/resizing state
        setIsDragging(false);
        setIsResizing(false);
        
        // When drag/resize ends, update component config with final position
        if (wasDragging || wasResizing) {
          debugLog('Drag/resize ended', {
            dragType: wasDragging ? 'drag' : 'resize',
            finalPosition
          });
          
          // Update config with final position - no _scaleChange flag since this is a user action that should be persisted
          onConfigChange({
            ...configRef.current,
            position: { ...finalPosition }
          });
          
          // Update original dimensions based on new position to ensure resize handler uses correct values
          if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const containerWidth = containerRect.width;
            const containerHeight = containerRect.height;
            
            if (containerWidth > 0 && containerHeight > 0) {
              originalDimensionsRef.current = {
                containerWidth,
                containerHeight,
                x: finalPosition.x,
                y: finalPosition.y,
                width: finalPosition.width,
                height: finalPosition.height,
                xRatio: containerWidth > 0 ? finalPosition.x / containerWidth : 0,
                yRatio: containerHeight > 0 ? finalPosition.y / containerHeight : 0,
                widthRatio: containerWidth > 0 ? finalPosition.width / containerWidth : 0,
                heightRatio: containerHeight > 0 ? finalPosition.height / containerHeight : 0
              };
              
              debugLog('Updated original dimensions after drag/resize', originalDimensionsRef.current);
            }
          }
          
          // Set post-drag lock with short timeout to reduce potential flicker
          // but allow maximize/restore window operations to work properly
          setPostDragLock(true);
          setTimeout(() => {
            setPostDragLock(false);
          }, 50); // Reduced from longer values to allow maximize events to be handled
        }
      };
      
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        debugLog('Removed mouse event listeners');
      };
    }
  }, [isDragging, isResizing, handleMouseMoveAction, onConfigChange, configRef, positionRef, localPosition]);
  
  // Handle minimize toggle - now handles maximize/restore
  const handleMinimize = (e: React.MouseEvent) => {
    // Stop event propagation so it doesn't trigger the drag handler
    e.stopPropagation();
    e.preventDefault();
    
    // Toggle between maximize and restore
    if (isMaximized) {
      // Restore to original size and position
      setIsMaximized(false);
      
      // Update local position for immediate visual response
      setLocalPosition({
        ...preMaximizePosition
      });
      
      // Increment global z-index counter
      globalZIndexCounter += 10;
      
      // Update component config - this is a user action that should be persisted
      onConfigChange({
        ...configRef.current,
        position: {
          ...configRef.current.position,
          ...preMaximizePosition,
          zIndex: globalZIndexCounter // Use the global counter for consistent z-index
        }
      });
    } else {
      // Save current position before maximizing
      setPreMaximizePosition({
        x: localPosition.x,
        y: localPosition.y,
        width: localPosition.width,
        height: localPosition.height,
        zIndex: localPosition.zIndex
      });
      
      // Maximize to full container size
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        
        // Get container dimensions for full-size expansion
        const padding = 0; // No padding for maximized view
        
        // Set to full container size
        const newWidth = containerRect.width - (padding * 2);
        const newHeight = containerRect.height - (padding * 2);
        
        // Position at top-left corner
        const newX = padding;
        const newY = padding;
        
        // Use a very high z-index value for maximized windows
        const maximizedZIndex = 9000000; // Higher than any normal component but still below side menu
        
        // Update local position for immediate visual response
        setLocalPosition({
          x: newX,
          y: newY,
          width: newWidth,
          height: newHeight,
          zIndex: maximizedZIndex
        });
        
        // Update component config - this is a user action that should be persisted
        onConfigChange({
          ...configRef.current,
          position: {
            ...configRef.current.position,
            x: newX,
            y: newY,
            width: newWidth,
            height: newHeight,
            zIndex: maximizedZIndex // Use a very high z-index for maximized windows
          }
        });
        
        // Set maximized state
        setIsMaximized(true);
      }
    }
  };
  
  // Handle actual window minimize (collapse to header only)
  const handleWindowMinimize = (e: React.MouseEvent) => {
    // Stop event propagation so it doesn't trigger the drag handler
    e.stopPropagation();
    e.preventDefault();
    
    // Toggle minimized state
    onConfigChange({
      ...configRef.current,
      isMinimized: !configRef.current.isMinimized
    });
  };
  
  // Handle config toggle
  const handleConfigToggle = () => {
    console.log('ComponentWrapper handleConfigToggle called');
    if (onConfigToggle) {
      onConfigToggle();
    } else {
      setShowConfig(!showConfig);
    }
  };
  
  // Handle bring to front
  const handleBringToFront = useCallback(() => {
    // Log what's happening
    console.log('Bringing to front:', config.title, 'Current zIndex:', config.position.zIndex);
    
    // Increment global z-index counter to ensure this window gets the highest z-index
    globalZIndexCounter += 100; // Increased increment to avoid potential conflicts
    
    // For maximized windows, use an even higher z-index value
    const newZIndex = isMaximized ? 9000000 : globalZIndexCounter;
    
    console.log('New zIndex will be:', newZIndex);
    
    // IMPORTANT: First directly manipulate the DOM for immediate visual change
    if (componentRef.current) {
      componentRef.current.style.zIndex = String(newZIndex);
      console.log('DOM zIndex directly set to:', newZIndex);
    }
    
    // Update local position state immediately
    setLocalPosition(prev => {
      const updated = {
        ...prev,
        zIndex: newZIndex
      };
      console.log('LocalPosition updated with zIndex:', newZIndex);
      return updated;
    });
    
    // Update component config
    const updatedConfig = {
      ...configRef.current,
      position: {
        ...configRef.current.position,
        zIndex: newZIndex
      }
    };
    
    onConfigChange(updatedConfig);
    console.log('Config updated with new zIndex:', newZIndex);
    
    debugLog('Brought component to front', {
      componentId: configRef.current.id,
      title: configRef.current.title,
      newZIndex
    });
  }, [isMaximized, onConfigChange, config.title, config.position.zIndex]);
  
  // Handle toggling lock state
  const handleToggleLock = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the header drag from triggering
    
    // Update component config with new lock state
    onConfigChange({
      ...config,
      isLocked: !config.isLocked
    });
  };
  
  // More aggressive click handling to ensure component comes to front when clicked
  useEffect(() => {
    if (!componentRef.current) return;
    
    const handleComponentClick = (e: MouseEvent) => {
      console.log('Component clicked:', config.title);
      // Force the component to the front immediately
      setTimeout(() => handleBringToFront(), 0);
    };
    
    // Add click listeners for both capture and bubbling phases to ensure we catch all clicks
    componentRef.current.addEventListener('mousedown', handleComponentClick, true);
    componentRef.current.addEventListener('click', handleComponentClick, true);
    
    // Clean up
    return () => {
      if (componentRef.current) {
        componentRef.current.removeEventListener('mousedown', handleComponentClick, true);
        componentRef.current.removeEventListener('click', handleComponentClick, true);
      }
    };
  }, [handleBringToFront, config.title]);
  
  // Debug state
  const [showDebugPanel, setShowDebugPanel] = useState(false);
  const [debugInfo, setDebugInfo] = useState({
    dragCount: 0,
    resizeCount: 0,
    lastUpdateTime: 0,
    updateFrequency: 0,
    throttledUpdates: 0,
    skippedUpdates: 0
  });
  
  // Update debug info
  useEffect(() => {
    if (isDragging || isResizing) {
      const now = performance.now();
      const timeSinceLastUpdate = now - debugInfo.lastUpdateTime;
      
      setDebugInfo(prev => ({
        ...prev,
        dragCount: isDragging ? prev.dragCount + 1 : prev.dragCount,
        resizeCount: isResizing ? prev.resizeCount + 1 : prev.resizeCount,
        lastUpdateTime: now,
        updateFrequency: prev.lastUpdateTime > 0 ? timeSinceLastUpdate : 0
      }));
    }
  }, [isDragging, isResizing, localPosition]);

  // Component style based on local position for smoother updates
  const componentStyle: React.CSSProperties = {
    left: isMaximized ? '0px' : `${localPosition.x}px`,
    top: isMaximized ? '0px' : `${localPosition.y}px`,
    width: isMaximized ? '100%' : `${localPosition.width}px`,
    height: config.isMinimized ? 'auto' : (isMaximized ? '100%' : `${localPosition.height}px`),
    // Use the zIndex from localPosition if available, otherwise fall back to config
    zIndex: localPosition.zIndex || config.position.zIndex,
    // Add CSS variables for position to use in our CSS selectors
    ['--drag-x' as any]: `${localPosition.x}px`,
    ['--drag-y' as any]: `${localPosition.y}px`,
    // Disable transitions during dragging, resizing, or after drag lock
    transition: isDragging || isResizing || postDragLock ? 'none !important' : undefined,
    // Add visual indicators for drag/resize state
    ...(isDragging ? { 
      boxShadow: '0 0 0 2px #ff5722, 0 8px 16px rgba(0,0,0,0.2)',
      opacity: 0.8,
      cursor: 'grabbing'
    } : {}),
    ...(isResizing ? { 
      boxShadow: '0 0 0 2px #2196f3, 0 8px 16px rgba(0,0,0,0.2)',
      opacity: 0.8
    } : {})
  };
  
  // Toggle debug panel
  const toggleDebugPanel = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDebugPanel(!showDebugPanel);
  };
  
  // For debugging - log when position in props is different from local position
  useEffect(() => {
    // Skip during active dragging or resizing
    if (isDragging || isResizing) return;
    
    const hasDiff = 
      Math.abs(localPosition.x - config.position.x) > 1 || 
      Math.abs(localPosition.y - config.position.y) > 1 ||
      Math.abs(localPosition.width - config.position.width) > 1 ||
      Math.abs(localPosition.height - config.position.height) > 1;
    
    if (hasDiff) {
      debugLog('Position diff detected', { 
        local: localPosition, 
        config: config.position,
        diff: {
          x: localPosition.x - config.position.x,
          y: localPosition.y - config.position.y,
          width: localPosition.width - config.position.width,
          height: localPosition.height - config.position.height
        }
      });
    }
  }, [localPosition, config.position, isDragging, isResizing]);

  // Handle header double-click for maximize/restore
  const handleHeaderDoubleClick = (e: React.MouseEvent) => {
    // Skip if target is a button
    if ((e.target as HTMLElement).tagName === 'BUTTON') {
      return;
    }
    // Use the same maximize/restore functionality
    handleMinimize(e);
  };
  
  // Bring component to front when first rendered
  useEffect(() => {
    // Small delay to ensure all components are rendered first
    const timer = setTimeout(() => {
      if (config.position.zIndex <= 1) {
        handleBringToFront();
      }
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  // Store original dimensions for proportional resizing during window resize
  useEffect(() => {
    if (!containerRef.current) return;
    
    const containerRect = containerRef.current.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;
    
    // Skip if container dimensions are 0 (not fully rendered)
    if (containerWidth === 0 || containerHeight === 0) return;
    
    // Initialize or update original dimensions
    const currentPosition = configRef.current.position;
    originalDimensionsRef.current = {
      containerWidth,
      containerHeight,
      x: currentPosition.x,
      y: currentPosition.y,
      width: currentPosition.width,
      height: currentPosition.height,
      xRatio: containerWidth > 0 ? currentPosition.x / containerWidth : 0,
      yRatio: containerHeight > 0 ? currentPosition.y / containerHeight : 0,
      widthRatio: containerWidth > 0 ? currentPosition.width / containerWidth : 0,
      heightRatio: containerHeight > 0 ? currentPosition.height / containerHeight : 0
    };
    
    debugLog('Original dimensions set', originalDimensionsRef.current);
  }, []);  // Empty dependency array to run only once on mount

  return (
    <div 
      className={`component-wrapper ${isDragging ? 'dragging' : ''} ${isResizing ? 'resizing' : ''} ${postDragLock ? 'post-drag-lock' : ''} ${config.isLocked ? 'locked' : ''} ${isMaximized ? 'maximized' : ''}`}
      style={componentStyle}
      ref={componentRef}
      data-component-id={config.id}
      data-component-type={config.type}
    >
      <div 
        className="component-header" 
        onMouseDown={(e) => {
          console.log('Header mousedown event for', config.title);
          
          // Ensure component comes to front first with direct DOM manipulation
          if (componentRef.current) {
            // Increment global z-index counter
            globalZIndexCounter += 100;
            const newZIndex = isMaximized ? 9000000 : globalZIndexCounter;
            
            // Set z-index directly on DOM element
            componentRef.current.style.zIndex = String(newZIndex);
            console.log('Header click: DOM zIndex set to:', newZIndex, 'for', config.title);
          }
          
          // Then handle dragging
          handleMouseDown(e);
        }}
        onDoubleClick={handleHeaderDoubleClick}
      >
        <div className="component-title">
          {config.title}
          {(isDragging || isResizing) && (
            <span className="component-state-indicator">
              {isDragging ? ' (Dragging)' : ''}
              {isResizing ? ' (Resizing)' : ''}
            </span>
          )}
        </div>
        <div 
          className="component-controls" 
          onMouseDown={(e) => {
            console.log('Controls mousedown event:', e.target);
            e.stopPropagation();
          }}
          onClick={(e) => {
            console.log('Controls click event:', e.target);
          }}
        >
          {headerControls}
          <LockIcon
            isLocked={config.isLocked}
            onClick={(e) => {
              e.stopPropagation();
              handleToggleLock(e);
            }}
            className="component-control"
          />
          <button 
            className="component-control" 
            onClick={(e) => {
              console.log('Gear icon clicked');
              handleConfigToggle();
            }}
            title="Settings"
          >
            ⚙️
          </button>
          <button 
            className="component-control" 
            onClick={handleMinimize}
            title={isMaximized ? "Restore" : "Maximize"}
          >
            {isMaximized ? '🗗' : '🗖'}
          </button>
          <button 
            className="component-control" 
            onClick={onRemove}
            title="Close"
          >
            ❌
          </button>
        </div>
      </div>
      
      {!config.isMinimized && (
        <div 
          className={`component-content ${config.type === 'chart' || config.type === 'timeChart' ? 'chart-content' : ''}`}
          onClick={(e) => {
            console.log('Content click for', config.title);
            e.stopPropagation();
            
            // Ensure we update z-index directly on DOM
            if (componentRef.current) {
              // Increment global z-index counter
              globalZIndexCounter += 100;
              const newZIndex = isMaximized ? 9000000 : globalZIndexCounter;
              
              // Set z-index directly on DOM element
              componentRef.current.style.zIndex = String(newZIndex);
              console.log('Content click: DOM zIndex set to:', newZIndex, 'for', config.title);
              
              // Also update through normal channels for state consistency
              handleBringToFront();
            }
          }}
        >
          {children}
        </div>
      )}
      
      {!config.isMinimized && !config.isLocked && (
        <div 
          className="component-resize-handle"
          onMouseDown={handleResizeMouseDown}
        />
      )}
      
      {/* Debug overlay */}
      {process.env.NODE_ENV !== 'production' && (isDragging || isResizing) && (
        <div className="debug-overlay">
          {isDragging ? `Dragging: x=${Math.round(localPosition.x)}, y=${Math.round(localPosition.y)}` : ''}
          {isResizing ? `Resizing: w=${Math.round(localPosition.width)}, h=${Math.round(localPosition.height)}` : ''}
        </div>
      )}
      
      {/* Debug panel */}
      {showDebugPanel && (
        <div className="debug-panel">
          <h4>Component Debug Info</h4>
          <div className="debug-panel-row">
            <span className="debug-panel-label">ID:</span>
            <span className="debug-panel-value">{config.id.substring(0, 8)}...</span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Position:</span>
            <span className="debug-panel-value">
              x:{Math.round(localPosition.x)}, y:{Math.round(localPosition.y)}
            </span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Size:</span>
            <span className="debug-panel-value">
              w:{Math.round(localPosition.width)}, h:{Math.round(localPosition.height)}
            </span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Drag events:</span>
            <span className="debug-panel-value">{debugInfo.dragCount}</span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Resize events:</span>
            <span className="debug-panel-value">{debugInfo.resizeCount}</span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">Update freq:</span>
            <span className="debug-panel-value">
              {debugInfo.updateFrequency > 0 ? `${Math.round(debugInfo.updateFrequency)}ms` : 'N/A'}
            </span>
          </div>
          <div className="debug-panel-row">
            <span className="debug-panel-label">State:</span>
            <span className="debug-panel-value">
              {isDragging ? 'Dragging' : isResizing ? 'Resizing' : 'Idle'}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComponentWrapper; 