import React, { useState, useEffect } from 'react';
import { sharedPageApi } from '../../services/api';
import { usePage } from '../../context/PageContext';
import { useAuth } from '../../context/AuthContext';
import usePermissions from '../../hooks/usePermissions';
import { PAGE_PERMISSIONS } from '../../constants/permissions';
import './ComponentStyles.css';

interface SharedPage {
  _id: string;
  name: string;
  description: string;
  pageConfig: any;
  createdBy: {
    _id: string;
    username: string;
    firstName?: string;
    lastName?: string;
  };
  isPublic: boolean;
  createdAt: string;
  updatedAt: string;
  tags: string[];
}

interface SharedPagesLibraryProps {
  onClose: () => void;
  onPageSelected?: (page: any) => void;
}

const SharedPagesLibrary: React.FC<SharedPagesLibraryProps> = ({ onClose, onPageSelected }) => {
  const [sharedPages, setSharedPages] = useState<SharedPage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [previewPage, setPreviewPage] = useState<SharedPage | null>(null);
  const [copying, setCopying] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { addPage } = usePage();
  const [deleting, setDeleting] = useState(false);
  const { user } = useAuth();
  const { hasPermission } = usePermissions();

  // Fetch shared pages when the component mounts
  useEffect(() => {
    const fetchSharedPages = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await sharedPageApi.getSharedPages();
        setSharedPages(response.data.sharedPages || []);
      } catch (err) {
        console.error('Error fetching shared pages:', err);
        setError('Failed to load shared pages. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchSharedPages();
  }, []);
  
  // Filter pages based on search term
  const filteredPages = sharedPages.filter(page => {
    if (!searchTerm) return true;
    
    const searchLower = searchTerm.toLowerCase();
    return (
      page.name.toLowerCase().includes(searchLower) ||
      page.description.toLowerCase().includes(searchLower) ||
      (page.tags && page.tags.some(tag => tag.toLowerCase().includes(searchLower)))
    );
  });
  
  // Handle selecting a page for preview
  const handlePageSelect = (page: SharedPage) => {
    setPreviewPage(page);
  };
  
  // Handle copying a page to user's profile
  const handleCopyPage = async (pageId: string, pageName: string) => {
    if (copying) return;
    
    // Prompt user for a custom name
    const customName = window.prompt(
      'Enter a name for your copy of this page:',
      pageName
    );
    
    // If user cancels or enters empty name, abort
    if (!customName || customName.trim() === '') return;
    
    try {
      setCopying(true);
      setError(null);
      
      // Call the API to copy the page
      const response = await sharedPageApi.copySharedPageToUser(pageId, customName.trim());
      
      // Dispatch a custom event to refresh pages in the side menu
      const refreshEvent = new CustomEvent('refresh-pages');
      document.dispatchEvent(refreshEvent);
      
      // Close the library
      onClose();
      
      // Notify parent if callback provided
      if (onPageSelected) {
        onPageSelected(response.data.page);
      }
    } catch (err) {
      console.error('Error copying shared page:', err);
      setError('Failed to copy page. Please try again.');
    } finally {
      setCopying(false);
    }
  };
  
  // Format created date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };
  
  // Get creator name
  const getCreatorName = (creator: SharedPage['createdBy']) => {
    if (creator.firstName && creator.lastName) {
      return `${creator.firstName} ${creator.lastName}`;
    }
    return creator.username;
  };

  // Check if user can delete the page
  const canDeletePage = (pageCreatorId: string): boolean => {
    // User can delete if they:
    // 1. Are the creator of the page, OR
    // 2. Have admin role, OR
    // 3. Have the specific delete page permission
    return (
      user?._id === pageCreatorId ||
      user?.role === 'admin' ||
      hasPermission(PAGE_PERMISSIONS.DELETE_PAGE)
    );
  };
  
  // Handle deleting a shared page
  const handleDeletePage = async (pageId: string, pageName: string) => {
    // Confirm deletion with the user
    const confirmed = window.confirm(
      `Are you sure you want to delete the shared page "${pageName}"? This cannot be undone.`
    );
    
    if (!confirmed) return;
    
    try {
      setDeleting(true);
      
      // Call the API to delete the page
      await sharedPageApi.deleteSharedPage(pageId);
      
      // Remove the page from the local state
      setSharedPages(prevPages => prevPages.filter(page => page._id !== pageId));
      
      // Close the preview panel
      setPreviewPage(null);
      
      // Show a brief notification (could implement this later)
      console.log(`Page "${pageName}" was deleted successfully`);
    } catch (err) {
      console.error('Error deleting shared page:', err);
      setError('Failed to delete page. Please try again.');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="shared-pages-library">
      {/* Search input */}
      <div className="shared-pages-search">
        <input
          type="text"
          placeholder="Search pages..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="shared-pages-search-input"
        />
      </div>
      
      {/* Loading and error states */}
      {loading && <div className="shared-pages-loading">Loading shared pages...</div>}
      {error && <div className="shared-pages-error">{error}</div>}
      
      {/* No results message */}
      {!loading && !error && filteredPages.length === 0 && (
        <div className="shared-pages-empty">
          {searchTerm ? 'No pages matching your search' : 'No shared pages available'}
        </div>
      )}
      
      {/* Preview panel */}
      {previewPage && (
        <div className="shared-page-preview">
          <div className="shared-page-preview-header">
            <h4>{previewPage.name}</h4>
            <button 
              className="shared-page-preview-close" 
              onClick={() => setPreviewPage(null)}
            >
              ×
            </button>
          </div>
          
          <div className="shared-page-preview-content">
            <p className="shared-page-description">
              {previewPage.description || 'No description provided'}
            </p>
            
            <div className="shared-page-metadata">
              <div className="shared-page-creator">
                <strong>Created by:</strong> {getCreatorName(previewPage.createdBy)}
              </div>
              <div className="shared-page-date">
                <strong>Date:</strong> {formatDate(previewPage.createdAt)}
              </div>
              {previewPage.tags && previewPage.tags.length > 0 && (
                <div className="shared-page-tags">
                  <strong>Tags:</strong> {previewPage.tags.join(', ')}
                </div>
              )}
            </div>
            
            <div className="shared-page-components">
              <strong>Components:</strong> {previewPage.pageConfig.components?.length || 0}
            </div>
            
            <div className="shared-page-actions">
              <button
                className="shared-page-copy-btn"
                onClick={() => handleCopyPage(previewPage._id, previewPage.name)}
                disabled={copying}
              >
                {copying ? 'Copying...' : 'Copy to My Pages'}
              </button>
              
              {/* Only show delete button to users with permission */}
              {canDeletePage(previewPage.createdBy._id) && (
                <button
                  className="shared-page-delete-btn"
                  onClick={() => handleDeletePage(previewPage._id, previewPage.name)}
                  disabled={deleting}
                >
                  {deleting ? 'Deleting...' : 'Delete Page'}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      
      {/* Shared pages grid */}
      <div className="shared-pages-grid">
        {filteredPages.map(page => (
          <div 
            key={page._id}
            className="shared-page-item"
            onClick={() => handlePageSelect(page)}
          >
            <div className="shared-page-item-icon">
              <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2Z" 
                      fill="#e4c87e" stroke="#e4c87e" strokeWidth="1"/>
                <path d="M14 2V8H20" stroke="#333" strokeWidth="1"/>
                <path d="M16 13H8" stroke="#333" strokeWidth="1"/>
                <path d="M16 17H8" stroke="#333" strokeWidth="1"/>
                <path d="M10 9H9H8" stroke="#333" strokeWidth="1"/>
              </svg>
            </div>
            <div className="shared-page-item-label">
              <div className="shared-page-item-name">{page.name}</div>
              <div className="shared-page-item-creator">
                By: {getCreatorName(page.createdBy)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedPagesLibrary; 