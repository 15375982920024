import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { Page, createPage } from '../components/ComponentSystem/ComponentRegistry';
import { ComponentConfig } from '../components/ComponentSystem/types';
import { useAuth } from './AuthContext';
import { pageApi } from '../services/api';

// Define PageContext interface
interface PageContextType {
  pages: Page[];
  currentPage: Page | null;
  isLoading: boolean;
  error: string | null;
  addPage: (name: string, description?: string) => void;
  updatePage: (pageId: string, updates: Partial<Omit<Page, 'id'>>) => void;
  deletePage: (pageId: string) => void;
  setCurrentPage: (pageId: string) => void;
  addComponent: (pageId: string, component: ComponentConfig) => void;
  updateComponent: (pageId: string, componentId: string, updates: Partial<ComponentConfig>) => void;
  removeComponent: (pageId: string, componentId: string) => void;
}

// Create Page Context
const PageContext = createContext<PageContextType | undefined>(undefined);

// Define Props interface for PageProvider
interface PageProviderProps {
  children: ReactNode;
}

// Page Provider component
export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const [pages, setPages] = useState<Page[]>([]);
  const [currentPage, setCurrentPageState] = useState<Page | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);
  // Add a flag to track if pages have changed and need saving
  const [needsSaving, setNeedsSaving] = useState<boolean>(false);

  // Function to fetch pages from the API
  const fetchPages = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await pageApi.getPages();
      const fetchedPages = response.data.pages;
      
      if (fetchedPages && fetchedPages.length > 0) {
        setPages(fetchedPages);
        
        // Set current page to the default page or the first page
        // Only set current page if none is selected or if the current page is not in the fetched pages
        if (!currentPage || !fetchedPages.find((p: Page) => p.id === currentPage.id)) {
          const defaultPage = fetchedPages.find((p: Page) => p.isDefault) || fetchedPages[0];
          if (defaultPage) {
            setCurrentPageState(defaultPage);
          }
        }
      } else {
        // Create a default page if none exists
        const defaultPage = createPage('Home', 'Default home page', [], true);
        setPages([defaultPage]);
        setCurrentPageState(defaultPage);
        
        // Save the default page to the backend
        await pageApi.savePages([defaultPage]);
      }
    } catch (err) {
      console.error('Error loading pages:', err);
      setError('Failed to load pages. Please try again.');
      
      // Fallback to a default page if API fails
      const defaultPage = createPage('Home', 'Default home page', [], true);
      setPages([defaultPage]);
      setCurrentPageState(defaultPage);
    } finally {
      setIsLoading(false);
    }
  };

  // Load pages from user profile
  useEffect(() => {
    if (user) {
      fetchPages();
    }
  }, [user]);

  // Listen for page refresh events
  useEffect(() => {
    const handleRefreshPages = () => {
      if (user) {
        fetchPages();
      }
    };
    
    // Add event listener
    document.addEventListener('refresh-pages', handleRefreshPages);
    
    // Clean up
    return () => {
      document.removeEventListener('refresh-pages', handleRefreshPages);
    };
  }, [user]);

  // Save pages when they change (with debounce)
  useEffect(() => {
    if (user && pages.length > 0 && needsSaving) {
      // Clear previous timeout
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
      
      // Set new timeout to save pages after 2 seconds of inactivity
      const timeoutId = setTimeout(async () => {
        try {
          await pageApi.savePages(pages);
          console.log('Pages saved successfully');
          // Reset the needs saving flag
          setNeedsSaving(false);
        } catch (err) {
          console.error('Error saving pages:', err);
          setError('Failed to save pages. Your changes may not be persisted.');
        }
      }, 2000);
      
      setSaveTimeout(timeoutId);
      
      // Clean up timeout on unmount
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, [user, pages, needsSaving]);

  // Add a new page
  const addPage = (name: string, description: string = '') => {
    const newPage = createPage(name, description);
    setPages(prevPages => [...prevPages, newPage]);
    // Mark as needing save
    setNeedsSaving(true);
    return newPage;
  };

  // Update a page
  const updatePage = (pageId: string, updates: Partial<Omit<Page, 'id'>>) => {
    setPages(prevPages => 
      prevPages.map(page => 
        page.id === pageId 
          ? { ...page, ...updates, updatedAt: new Date() } 
          : page
      )
    );
    
    // Update current page if it's the one being updated
    if (currentPage && currentPage.id === pageId) {
      setCurrentPageState(prev => prev ? { ...prev, ...updates, updatedAt: new Date() } : prev);
    }
    
    // Mark as needing save
    setNeedsSaving(true);
  };

  // Delete a page
  const deletePage = (pageId: string) => {
    // Don't delete the last page
    if (pages.length <= 1) {
      setError("Can't delete the last page");
      return;
    }
    
    setPages(prevPages => prevPages.filter(page => page.id !== pageId));
    
    // If deleting the current page, switch to another page
    if (currentPage && currentPage.id === pageId) {
      const newCurrentPage = pages.find(page => page.id !== pageId);
      if (newCurrentPage) {
        setCurrentPageState(newCurrentPage);
      }
    }
    
    // Mark as needing save
    setNeedsSaving(true);
  };

  // Set the current page
  const setCurrentPage = (pageId: string) => {
    const page = pages.find(p => p.id === pageId);
    if (page) {
      setCurrentPageState(page);
    } else {
      setError(`Page with ID ${pageId} not found`);
    }
  };

  // Add a component to a page
  const addComponent = (pageId: string, component: ComponentConfig) => {
    setPages(prevPages => 
      prevPages.map(page => 
        page.id === pageId 
          ? { 
              ...page, 
              components: [...page.components, component],
              updatedAt: new Date()
            } 
          : page
      )
    );
    
    // Update current page if it's the one being modified
    if (currentPage && currentPage.id === pageId) {
      setCurrentPageState(prev => 
        prev 
          ? { 
              ...prev, 
              components: [...prev.components, component],
              updatedAt: new Date()
            } 
          : prev
      );
    }
    
    // Mark as needing save
    setNeedsSaving(true);
  };

  // Update a component on a page
  const updateComponent = (pageId: string, componentId: string, updates: Partial<ComponentConfig>) => {
    console.log('[DEBUG PAGE] updateComponent called:', {
      pageId,
      componentId,
      updates
    });
    
    // Check if this is a scaling operation (non-persistent change)
    const isScaleChange = updates._scaleChange === true;
    
    // Remove the _scaleChange flag from updates before applying
    const cleanUpdates = { ...updates };
    if ('_scaleChange' in cleanUpdates) {
      delete cleanUpdates._scaleChange;
    }
    
    setPages(prevPages => {
      // For debugging
      const targetPage = prevPages.find(p => p.id === pageId);
      const targetComponent = targetPage?.components.find(c => c.id === componentId);
      
      console.log('[DEBUG PAGE] Current state before update:', {
        hasPage: !!targetPage,
        hasComponent: !!targetComponent,
        currentPosition: targetComponent?.position,
        isScaleChange
      });
      
      return prevPages.map(page => 
        page.id === pageId 
          ? { 
              ...page, 
              components: page.components.map(comp => 
                comp.id === componentId 
                  ? { ...comp, ...cleanUpdates } 
                  : comp
              ),
              // Only update the timestamp if it's not a scaling operation
              updatedAt: isScaleChange ? page.updatedAt : new Date()
            } 
          : page
      );
    });
    
    // Update current page if it's the one being modified
    if (currentPage && currentPage.id === pageId) {
      setCurrentPageState(prev => {
        if (!prev) return prev;
        
        const updatedComponents = prev.components.map(comp => 
          comp.id === componentId 
            ? { ...comp, ...cleanUpdates } 
            : comp
        );
        
        // For debugging
        const updatedComponent = updatedComponents.find(c => c.id === componentId);
        console.log('[DEBUG PAGE] Current page updated:', {
          componentId,
          updatedPosition: updatedComponent?.position,
          isScaleChange
        });
        
        return { 
          ...prev, 
          components: updatedComponents,
          // Only update the timestamp if it's not a scaling operation
          updatedAt: isScaleChange ? prev.updatedAt : new Date()
        };
      });
    }
    
    // Only mark as needing save if it's not a scaling operation
    if (!isScaleChange) {
      setNeedsSaving(true);
    }
  };

  // Remove a component from a page
  const removeComponent = (pageId: string, componentId: string) => {
    setPages(prevPages => 
      prevPages.map(page => 
        page.id === pageId 
          ? { 
              ...page, 
              components: page.components.filter(comp => comp.id !== componentId),
              updatedAt: new Date()
            } 
          : page
      )
    );
    
    // Update current page if it's the one being modified
    if (currentPage && currentPage.id === pageId) {
      setCurrentPageState(prev => 
        prev 
          ? { 
              ...prev, 
              components: prev.components.filter(comp => comp.id !== componentId),
              updatedAt: new Date()
            } 
          : prev
      );
    }
    
    // Mark as needing save
    setNeedsSaving(true);
  };

  return (
    <PageContext.Provider
      value={{
        pages,
        currentPage,
        isLoading,
        error,
        addPage,
        updatePage,
        deletePage,
        setCurrentPage,
        addComponent,
        updateComponent,
        removeComponent
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

// Custom hook to use the Page context
export const usePage = (): PageContextType => {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
}; 