import React, { useState, useEffect } from 'react';
import { ConfigPanelProps } from '../../ComponentRegistry';
import { defaultSettings } from './TimeChartComponent';
import { Form, Input, Select, Button, Checkbox, Modal, Alert, Spin } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import DataSourceConfigModal, { DataSourceConfig, SeriesConfig, SensorConfig } from './DataSourceConfigModal';
import timeSeriesService, { SensorType, Sensor } from '../../../../services/timeSeriesService';
import './TimeChartConfig.css';

const { Option } = Select;

interface TimeChartSettings {
  title: string;
  chartType: 'line' | 'bar' | 'area';
  refreshInterval: number;
  showGrid: boolean;
  showTooltip: boolean;
  showLegend: boolean;
  colors: string[];
  dateFormat: string;
  interpolation?: boolean;
  yAxis?: {
    left: {
      autoScale: boolean;
      min?: number;
      max?: number;
    };
    right: {
      autoScale: boolean;
      min?: number;
      max?: number;
    };
  };
  dataSourceConfig?: DataSourceConfig;
}

const TimeChartConfig: React.FC<ConfigPanelProps> = ({ config, onConfigChange, onClose }) => {
  const [form] = Form.useForm();
  const [showDataSourceModal, setShowDataSourceModal] = useState(false);
  const [dataSourceConfig, setDataSourceConfig] = useState<DataSourceConfig | null>(
    config.settings?.dataSourceConfig || null
  );
  const [sensors, setSensors] = useState<{ [key in SensorType]: SensorConfig[] } | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Get settings from config or use defaults
  const currentSettings = (config.settings || defaultSettings) as TimeChartSettings;

  // Add form field watchers to handle dynamic form behavior
  const [leftAutoScale, setLeftAutoScale] = useState(
    form.getFieldValue(['yAxis', 'left', 'autoScale']) !== false
  );
  const [rightAutoScale, setRightAutoScale] = useState(
    form.getFieldValue(['yAxis', 'right', 'autoScale']) !== false
  );

  // Watch form value changes
  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    // Check if the left axis autoScale has changed
    if (changedValues.yAxis?.left?.autoScale !== undefined) {
      setLeftAutoScale(changedValues.yAxis.left.autoScale);
    }
    
    // Check if the right axis autoScale has changed
    if (changedValues.yAxis?.right?.autoScale !== undefined) {
      setRightAutoScale(changedValues.yAxis.right.autoScale);
    }
  };

  // Fetch sensors when component mounts
  useEffect(() => {
    const fetchSensors = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await timeSeriesService.getSensors();
        if (response.success && response.data) {
          // Transform the data to match our SensorConfig interface
          const transformedSensors: { [key in SensorType]: SensorConfig[] } = {
            WATER_DEPTH: [],
            PRESSURE: [],
            WATER_FLOW: []
          };

          // Group sensors by event_type
          Object.entries(response.data).forEach(([type, sensorList]) => {
            if (transformedSensors[type as SensorType]) {
              transformedSensors[type as SensorType] = sensorList.map(sensor => ({
                topic_pattern: sensor.id,
                description: sensor.name,
                event_type: type as SensorType,
                value_path: 'value',
                value_type: 'float'
              }));
            }
          });

          setSensors(transformedSensors);
        } else {
          setError(response.error || 'Failed to fetch sensors');
        }
      } catch (err) {
        console.error('Error fetching sensors:', err);
        setError('Failed to connect to the server. Please check your connection and try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchSensors();
  }, []);

  // Initialize form with initial config or default values
  useEffect(() => {
    console.log('Initializing form with settings:', currentSettings);
    form.setFieldsValue({
      title: currentSettings.title || 'Time Chart',
      chartType: currentSettings.chartType || 'line',
      refreshInterval: currentSettings.refreshInterval || 0,
      showGrid: currentSettings.showGrid !== false,
      showTooltip: currentSettings.showTooltip !== false,
      showLegend: currentSettings.showLegend !== false,
      interpolation: currentSettings.interpolation !== false,
      colors: currentSettings.colors || ['#8884d8', '#82ca9d', '#ffc658'],
      yAxis: currentSettings.yAxis || {
        left: { autoScale: true },
        right: { autoScale: true }
      }
    });
    
    // Set data source config
    if (currentSettings.dataSourceConfig) {
      console.log('Setting data source config:', currentSettings.dataSourceConfig);
      setDataSourceConfig(currentSettings.dataSourceConfig);
    }
  }, [currentSettings, form]);

  const handleSubmit = async () => {
    try {
      console.log('Submitting form...');
      const values = await form.validateFields();
      console.log('Form values:', values);
      console.log('Data source config:', dataSourceConfig);
      
      const newSettings = {
        ...currentSettings,
        ...values,
        dataSourceConfig
      };
      console.log('New settings:', newSettings);
      
      onConfigChange({
        ...config,
        settings: newSettings
      });
      onClose();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };
  
  // Reset to defaults
  const handleReset = () => {
    console.log('Resetting to defaults...');
    
    // Reset form fields to default values
    form.setFieldsValue({
      title: defaultSettings.title,
      chartType: defaultSettings.chartType,
      refreshInterval: defaultSettings.refreshInterval,
      showGrid: defaultSettings.showGrid,
      showTooltip: defaultSettings.showTooltip,
      showLegend: defaultSettings.showLegend,
      interpolation: defaultSettings.interpolation,
      colors: [...defaultSettings.colors], // Create a new array to avoid reference issues
      yAxis: {
        left: { autoScale: true },
        right: { autoScale: true }
      }
    });

    // Create a mutable copy of the default data source config
    const mutableConfig = defaultSettings.dataSourceConfig ? 
      JSON.parse(JSON.stringify(defaultSettings.dataSourceConfig)) : 
      null;
    
    // Update data source config state
    setDataSourceConfig(mutableConfig);

    // Update parent component with default settings
    onConfigChange({
      ...config,
      settings: {
        ...JSON.parse(JSON.stringify(defaultSettings))
      }
    });
  };

  const handleDataSourceConfigApply = (newConfig: DataSourceConfig) => {
    console.log('Applying new data source config:', newConfig);
    setDataSourceConfig(newConfig);
    setShowDataSourceModal(false);
  };

  const handleDataSourceClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDataSourceModal(true);
  };

  const renderDataSourceButton = () => {
    if (!dataSourceConfig || !dataSourceConfig.series || dataSourceConfig.series.length === 0) {
      return 'Configure Data Source';
    }

    const seriesText = dataSourceConfig.series
      .map((s: SeriesConfig) => `${s.name} (${s.sensorType})`)
      .join(', ');
    
    return seriesText || 'Configure Data Source';
  };

  // Get sensor options from the fetched sensors data
  const getSensorOptions = (sensorType: SensorType) => {
    if (!sensors) return [];
    return sensors[sensorType] || [];
  };

  const getDataKeyOptions = (sensorType: SensorType) => {
    switch (sensorType) {
      case 'WATER_DEPTH':
        return [
          { value: 'value', label: 'Depth (m)' },
          { value: 'percent_full', label: 'Percent Full (%)' }
        ];
      case 'PRESSURE':
        return [
          { value: 'value', label: 'Pressure (kPa)' },
          { value: 'psi', label: 'PSI' }
        ];
      case 'WATER_FLOW':
        return [
          { value: 'value', label: 'Flow Rate (L/s)' },
          { value: 'gallons', label: 'Total Gallons' },
          { value: 'cuft', label: 'Cubic Feet' },
          { value: 'pulses', label: 'Pulses' }
        ];
      default:
        return [];
    }
  };

  const getChartTypeOptions = () => {
    return [
      { value: 'line', label: 'Line Chart' },
      { value: 'bar', label: 'Bar Chart' },
      { value: 'area', label: 'Area Chart' }
    ];
  };

  return (
    <>
      <Modal
        title="Chart Configuration"
        open={true}
        onCancel={onClose}
        footer={[
          <Button key="reset" onClick={handleReset}>
            Reset to Defaults
          </Button>,
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        ]}
        width={600}
        maskClosable={false}
        style={{ top: 20 }}
        zIndex={9500001}
        maskStyle={{ zIndex: 9500000 }}
      >
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            style={{ marginBottom: '20px' }}
          />
        )}

        {loading && (
          <div className="loading-overlay">
            <Spin size="large" />
          </div>
        )}

        <Form
          form={form}
          layout="vertical"
          onValuesChange={handleFormValuesChange}
          initialValues={{
            title: currentSettings.title || 'Time Chart',
            chartType: currentSettings.chartType || 'line',
            refreshInterval: currentSettings.refreshInterval || 0,
            showGrid: currentSettings.showGrid !== false,
            showTooltip: currentSettings.showTooltip !== false,
            showLegend: currentSettings.showLegend !== false,
            interpolation: currentSettings.interpolation !== false,
            colors: currentSettings.colors || ['#8884d8', '#82ca9d', '#ffc658'],
            yAxis: currentSettings.yAxis || {
              left: { autoScale: true },
              right: { autoScale: true }
            }
          }}
        >
          <div className="section">
            <h4>General Settings</h4>
            
            <div className="form-row">
              <label>Title</label>
              <Form.Item name="title" noStyle>
                <Input />
              </Form.Item>
            </div>
            
            <div className="form-row">
              <label>Chart Type</label>
              <Form.Item name="chartType" noStyle>
                <Select>
                  <Option value="line">Line Chart</Option>
                  <Option value="area">Area Chart</Option>
                  <Option value="bar">Bar Chart</Option>
                </Select>
              </Form.Item>
            </div>

            <div className="form-row">
              <label>Refresh Interval (seconds)</label>
              <Form.Item name="refreshInterval" noStyle>
                <Input type="number" min={0} />
              </Form.Item>
              <span className="help-text">0 means manual refresh only</span>
            </div>

            <div className="form-row">
              <label>Data Source Configuration</label>
              <Button 
                icon={<SettingOutlined />}
                onClick={handleDataSourceClick}
              >
                {renderDataSourceButton()}
              </Button>
            </div>
          </div>
          
          <div className="section">
            <h4>Display Settings</h4>
            
            <div className="form-row">
              <Form.Item name="showGrid" valuePropName="checked" noStyle>
                <Checkbox>Show Grid</Checkbox>
              </Form.Item>
            </div>
            
            <div className="form-row">
              <Form.Item name="showTooltip" valuePropName="checked" noStyle>
                <Checkbox>Show Tooltip</Checkbox>
              </Form.Item>
            </div>
            
            <div className="form-row">
              <Form.Item name="showLegend" valuePropName="checked" noStyle>
                <Checkbox>Show Legend</Checkbox>
              </Form.Item>
            </div>
            
            <div className="form-row">
              <Form.Item name="interpolation" valuePropName="checked" noStyle>
                <Checkbox>Interpolate Missing Data</Checkbox>
              </Form.Item>
              <span className="help-text">Fill gaps when series have different timestamps</span>
            </div>

            <div className="form-row">
              <label>Chart Colors</label>
              <div className="color-pickers">
                <Form.List name="colors">
                  {(fields) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item {...field} key={field.key} noStyle>
                          <Input type="color" className="color-input" />
                        </Form.Item>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>
            </div>
          </div>

          <div className="section">
            <h4>Y-Axis Settings</h4>
            
            <div className="axis-settings">
              <div className="axis-column">
                <h5>Left Axis</h5>
                <div className="form-row">
                  <Form.Item name={['yAxis', 'left', 'autoScale']} valuePropName="checked" noStyle>
                    <Checkbox>Auto Scale</Checkbox>
                  </Form.Item>
                </div>
                
                <div className="form-row">
                  <label>Min Value</label>
                  <Form.Item name={['yAxis', 'left', 'min']} noStyle>
                    <Input 
                      type="number" 
                      placeholder="Auto" 
                      disabled={leftAutoScale}
                    />
                  </Form.Item>
                </div>
                
                <div className="form-row">
                  <label>Max Value</label>
                  <Form.Item name={['yAxis', 'left', 'max']} noStyle>
                    <Input 
                      type="number" 
                      placeholder="Auto" 
                      disabled={leftAutoScale}
                    />
                  </Form.Item>
                </div>
              </div>
              
              <div className="axis-column">
                <h5>Right Axis</h5>
                <div className="form-row">
                  <Form.Item name={['yAxis', 'right', 'autoScale']} valuePropName="checked" noStyle>
                    <Checkbox>Auto Scale</Checkbox>
                  </Form.Item>
                </div>
                
                <div className="form-row">
                  <label>Min Value</label>
                  <Form.Item name={['yAxis', 'right', 'min']} noStyle>
                    <Input 
                      type="number" 
                      placeholder="Auto" 
                      disabled={rightAutoScale}
                    />
                  </Form.Item>
                </div>
                
                <div className="form-row">
                  <label>Max Value</label>
                  <Form.Item name={['yAxis', 'right', 'max']} noStyle>
                    <Input 
                      type="number" 
                      placeholder="Auto" 
                      disabled={rightAutoScale}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>

      <DataSourceConfigModal
        open={showDataSourceModal}
        onClose={() => setShowDataSourceModal(false)}
        onApply={handleDataSourceConfigApply}
        initialConfig={dataSourceConfig || undefined}
        sensors={sensors || undefined}
      />
    </>
  );
};

export default TimeChartConfig; 