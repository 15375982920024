import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { userApi, API_URL } from '../services/api';

// Define User interface
interface User {
  _id: string;
  username: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role: string;
  permissions?: string[];
}

// Define Auth Context interface
interface AuthContextType {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  register: (userData: { username: string; email: string; password: string; firstName?: string; lastName?: string }) => Promise<void>;
  logout: () => void;
  updateUser: (userData: { firstName?: string; lastName?: string; email?: string; username?: string }) => Promise<void>;
  setTokenAndLoadUser: (newToken: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  verifyResetToken: (token: string) => Promise<boolean>;
  resetPassword: (token: string, password: string) => Promise<void>;
}

// Create Auth Context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define Props interface for AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

// Auth Provider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Check if user is authenticated on mount
  useEffect(() => {
    const loadUser = async () => {
      if (token) {
        try {
          const response = await userApi.getCurrentUser();
          setUser(response.data.user);
        } catch (error) {
          // If token is invalid, clear it
          console.error('Failed to load user:', error);
          localStorage.removeItem('token');
          setToken(null);
        }
      }
      setIsLoading(false);
    };

    loadUser();
  }, [token]);

  // Login function
  const login = async (credentials: { email: string; password: string }) => {
    try {
      setIsLoading(true);
      const response = await userApi.login(credentials);
      const { user, token } = response.data;
      
      localStorage.setItem('token', token);
      setToken(token);
      setUser(user);
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Register function
  const register = async (userData: { username: string; email: string; password: string; firstName?: string; lastName?: string }) => {
    try {
      setIsLoading(true);
      const response = await userApi.register(userData);
      const { user, token } = response.data;
      
      localStorage.setItem('token', token);
      setToken(token);
      setUser(user);
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Logout function
  const logout = () => {
    // Call the logout API endpoint if user is authenticated
    if (token) {
      console.log('Logout initiated, token exists');
      
      // Create a copy of the token for the API call
      const currentToken = token;
      
      // Make the API call to log the logout BEFORE clearing the token
      console.log('Making logout API call');
      userApi.logout()
        .then(() => {
          console.log('Logout API call successful');
          // Clear local storage and state after successful API call
          localStorage.removeItem('token');
          setToken(null);
          setUser(null);
        })
        .catch(error => {
          console.error('Error during logout API call:', error);
          // Still clear the token and user state even if the API call fails
          localStorage.removeItem('token');
          setToken(null);
          setUser(null);
        });
    } else {
      console.log('No token found, skipping logout API call');
      // If no token, just clear state
      localStorage.removeItem('token');
      setToken(null);
      setUser(null);
    }
  };

  // Update user profile
  const updateUser = async (userData: { firstName?: string; lastName?: string; email?: string; username?: string }) => {
    if (!user) throw new Error('User not authenticated');
    
    try {
      setIsLoading(true);
      const response = await userApi.updateUser(user._id, userData);
      setUser(response.data.user);
    } catch (error) {
      console.error('Update failed:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  // Helper method to set token and load user
  const setTokenAndLoadUser = async (newToken: string) => {
    try {
      setIsLoading(true);
      localStorage.setItem('token', newToken);
      setToken(newToken);
      
      // Load user profile
      const response = await userApi.getCurrentUser();
      setUser(response.data.user);
    } catch (error) {
      console.error('Failed to load user after setting token:', error);
      localStorage.removeItem('token');
      setToken(null);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Forgot password function
  const forgotPassword = async (email: string) => {
    try {
      setIsLoading(true);
      await userApi.forgotPassword(email);
    } catch (error) {
      console.error('Forgot password request failed:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  
  // Verify reset token function
  const verifyResetToken = async (token: string) => {
    try {
      setIsLoading(true);
      const response = await userApi.verifyResetToken(token);
      return response.data.valid;
    } catch (error) {
      console.error('Token verification failed:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  
  // Reset password function
  const resetPassword = async (token: string, password: string) => {
    try {
      setIsLoading(true);
      await userApi.resetPassword(token, password);
    } catch (error) {
      console.error('Password reset failed:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    user,
    token,
    isAuthenticated: !!user,
    isLoading,
    login,
    register,
    logout,
    updateUser,
    setTokenAndLoadUser,
    forgotPassword,
    verifyResetToken,
    resetPassword
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook to use Auth Context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 