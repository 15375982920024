import React from 'react';
import { ConfigPanelProps } from '../../ComponentRegistry';

const WaterFlowStatsConfig: React.FC<ConfigPanelProps> = ({ config, onConfigChange, onClose }) => {
  return (
    <div className="config-panel">
      <h3>Water Flow Stats Configuration</h3>
      <p>No configuration options available.</p>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default WaterFlowStatsConfig; 