import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ComponentProps } from '../../types';
import ComponentWrapper from '../../ComponentWrapper';
import { activityApi, userApi } from '../../../../services/api';
import { useAuth } from '../../../../context/AuthContext';
import { debounce } from '../../../../utils/helpers';

// Activity type definition
interface Activity {
  _id: string;
  userId: string;
  targetUserId?: string;
  action: string;
  details: any;
  ipAddress?: string;
  userAgent?: string;
  timestamp: string;
  user?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
  };
  targetUser?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
  };
}

// User type definition
interface User {
  _id: string;
  username: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role: string;
}

// Pagination type
interface Pagination {
  total: number;
  page: number;
  limit: number;
  pages: number;
}

// Default settings for the component
export const defaultSettings = {
  title: 'Activity Log',
  refreshInterval: 0, // 0 means manual refresh only
  limit: 10,
  showFilters: true,
  filters: {
    action: '',
    userId: '',
    targetUserId: '',
    startDate: '',
    endDate: ''
  },
  columns: {
    timestamp: true,
    action: true,
    user: true,
    targetUser: true,
    ipAddress: true,
    details: true
  }
};

const ActivityLogComponent: React.FC<ComponentProps> = ({ config, onConfigChange, onRemove }) => {
  const { isAuthenticated, user } = useAuth();
  
  // Initialize settings if they don't exist
  useEffect(() => {
    if (!config.settings) {
      onConfigChange({
        ...config,
        settings: defaultSettings
      });
    }
  }, [config, onConfigChange]);
  
  // State for activities and pagination
  const [activities, setActivities] = useState<Activity[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    page: 1,
    limit: config.settings?.limit || defaultSettings.limit,
    pages: 0
  });
  
  // Update pagination when config.settings.limit changes
  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      limit: config.settings?.limit || defaultSettings.limit
    }));
  }, [config.settings?.limit]);
  
  // Reference to store the previous filters
  const prevFiltersRef = useRef(config.settings?.filters || defaultSettings.filters);
  
  // State for filters
  const [filters, setFilters] = useState(config.settings?.filters || defaultSettings.filters);
  
  // State for filter visibility
  const [filterVisible, setFilterVisible] = useState(false);
  
  // Update filters when config.settings.filters changes
  useEffect(() => {
    const currentFilters = config.settings?.filters || defaultSettings.filters;
    // Only update if the filters have actually changed to avoid infinite loops
    if (JSON.stringify(prevFiltersRef.current) !== JSON.stringify(currentFilters)) {
      setFilters(currentFilters);
      prevFiltersRef.current = currentFilters;
    }
  }, [config.settings?.filters]);
  
  // State for users list
  const [users, setUsers] = useState<User[]>([]);
  
  // State for loading
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Activity types for filtering
  const activityTypes = activityApi.getActivityTypes();
  
  // Reference to store the current config
  const configRef = useRef(config);
  
  // Update config ref when config changes
  useEffect(() => {
    configRef.current = config;
  }, [config]);
  
  // Debounced function to update config
  const debouncedConfigUpdate = useCallback(
    debounce((newFilters) => {
      onConfigChange({
        ...configRef.current,
        settings: {
          ...configRef.current.settings,
          filters: newFilters
        }
      });
    }, 500),
    [onConfigChange]
  );
  
  // Fetch activities with debounce
  const fetchActivities = useCallback(debounce(async () => {
    if (!isAuthenticated) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await activityApi.getActivities({
        page: pagination.page,
        limit: pagination.limit,
        action: filters.action || undefined,
        userId: filters.userId || undefined,
        targetUserId: filters.targetUserId || undefined,
        startDate: filters.startDate || undefined,
        endDate: filters.endDate || undefined
      });
      
      setActivities(response.data.activities);
      setPagination(response.data.pagination);
    } catch (err) {
      console.error('Error fetching activities:', err);
      setError('Failed to load activity logs. Please try again.');
    } finally {
      setLoading(false);
    }
  }, 300), [isAuthenticated, pagination.page, pagination.limit, filters]);
  
  // Fetch users for filter dropdown
  const fetchUsers = useCallback(async () => {
    if (!isAuthenticated) return;
    
    try {
      const response = await userApi.getAllUsers();
      setUsers(response.data.users);
    } catch (err) {
      console.error('Error fetching users:', err);
      // Don't set error state here to avoid blocking the activity logs display
    }
  }, [isAuthenticated]);
  
  // Initial data fetch
  useEffect(() => {
    fetchActivities();
    fetchUsers();
  }, [fetchActivities, fetchUsers]);
  
  // Set up refresh interval if configured
  useEffect(() => {
    if (config.settings?.refreshInterval && config.settings?.refreshInterval > 0) {
      const intervalId = setInterval(() => {
        fetchActivities();
      }, config.settings?.refreshInterval * 1000);
      
      return () => clearInterval(intervalId);
    }
  }, [config.settings?.refreshInterval, fetchActivities]);
  
  // Handle filter change
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFilters = { ...filters, [name]: value };
    
    setFilters(newFilters);
    
    // Update component config with debounce
    debouncedConfigUpdate(newFilters);
    
    // Reset to first page
    setPagination(prev => ({ ...prev, page: 1 }));
  };
  
  // Apply filters
  const applyFilters = (e: React.FormEvent) => {
    e.preventDefault();
    fetchActivities();
  };
  
  // Reset filters
  const resetFilters = () => {
    const defaultFilters = defaultSettings.filters;
    setFilters(defaultFilters);
    
    // Update component config using the debounced function
    debouncedConfigUpdate(defaultFilters);
    
    setPagination(prev => ({ ...prev, page: 1 }));
    fetchActivities();
  };
  
  // Format date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Get user display name
  const formatUserName = (user?: { firstName?: string; lastName?: string; username: string }) => {
    if (!user) return 'System';
    return user.firstName && user.lastName 
      ? `${user.firstName} ${user.lastName} (${user.username})` 
      : user.username;
  };
  
  // Format action for display
  const formatAction = (action: string) => {
    const activityType = activityTypes.find(type => type.value === action);
    if (activityType) {
      return activityType.label;
    }
    
    // Fallback to the original formatting if not found in activity types
    return action
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  
  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > pagination.pages) return;
    setPagination(prev => ({ ...prev, page: newPage }));
  };
  
  // Check if any filters are applied
  const hasActiveFilters = () => {
    const defaultFilters = defaultSettings.filters;
    return Object.keys(filters).some(key => {
      // @ts-ignore
      return filters[key] !== defaultFilters[key] && filters[key] !== '';
    });
  };
  
  // Get a summary of applied filters for display
  const getAppliedFiltersSummary = () => {
    const appliedFilters = [];
    
    if (filters.action) {
      const actionType = activityTypes.find(type => type.value === filters.action);
      appliedFilters.push(`Action: ${actionType?.label || filters.action}`);
    }
    
    if (filters.userId) {
      const user = users.find(user => user._id === filters.userId);
      appliedFilters.push(`User: ${formatUserName(user)}`);
    }
    
    if (filters.targetUserId) {
      const targetUser = users.find(user => user._id === filters.targetUserId);
      appliedFilters.push(`Target: ${formatUserName(targetUser)}`);
    }
    
    if (filters.startDate) {
      appliedFilters.push(`From: ${new Date(filters.startDate).toLocaleDateString()}`);
    }
    
    if (filters.endDate) {
      appliedFilters.push(`To: ${new Date(filters.endDate).toLocaleDateString()}`);
    }
    
    return appliedFilters.join(' | ');
  };
  
  // Toggle filter visibility
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };
  
  return (
    <ComponentWrapper config={config} onConfigChange={onConfigChange} onRemove={onRemove}>
      <div className="activity-log-component">
        {/* Filters */}
        {config.settings?.showFilters && (
          <div className="filter-container">
            <div className="filter-header" onClick={toggleFilterVisibility}>
              <h4>Filters {hasActiveFilters() && <span className="filter-badge">Active</span>}</h4>
              <button className="filter-toggle-button">
                {filterVisible ? '▲ Hide Filters' : '▼ Show Filters'}
              </button>
            </div>
            
            {/* Applied filters summary */}
            {!filterVisible && hasActiveFilters() && (
              <div className="applied-filters-summary">
                {getAppliedFiltersSummary()}
                <button 
                  className="reset-filters-button" 
                  onClick={(e) => {
                    e.stopPropagation();
                    resetFilters();
                  }}
                >
                  Clear
                </button>
              </div>
            )}
            
            {/* Filter section */}
            {filterVisible && (
              <div className="filter-section">
                <form onSubmit={applyFilters}>
                  <div className="filter-row">
                    <div className="filter-item">
                      <label htmlFor="action">Action Type</label>
                      <select 
                        id="action" 
                        name="action" 
                        value={filters.action}
                        onChange={handleFilterChange}
                        className="form-control"
                      >
                        <option value="">All Actions</option>
                        {activityTypes.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    
                    <div className="filter-item">
                      <label htmlFor="userId">User</label>
                      <select 
                        id="userId" 
                        name="userId" 
                        value={filters.userId}
                        onChange={handleFilterChange}
                        className="form-control"
                      >
                        <option value="">All Users</option>
                        {users.map(user => (
                          <option key={user._id} value={user._id}>
                            {user.firstName && user.lastName 
                              ? `${user.firstName} ${user.lastName} (${user.username})` 
                              : user.username}
                          </option>
                        ))}
                      </select>
                    </div>
                    
                    <div className="filter-item">
                      <label htmlFor="targetUserId">Target User</label>
                      <select 
                        id="targetUserId" 
                        name="targetUserId" 
                        value={filters.targetUserId}
                        onChange={handleFilterChange}
                        className="form-control"
                      >
                        <option value="">All Target Users</option>
                        {users.map(user => (
                          <option key={user._id} value={user._id}>
                            {user.firstName && user.lastName 
                              ? `${user.firstName} ${user.lastName} (${user.username})` 
                              : user.username}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  <div className="filter-row">
                    <div className="filter-item">
                      <label htmlFor="startDate">Start Date</label>
                      <input 
                        type="datetime-local" 
                        id="startDate" 
                        name="startDate" 
                        value={filters.startDate}
                        onChange={handleFilterChange}
                        className="form-control"
                      />
                    </div>
                    
                    <div className="filter-item">
                      <label htmlFor="endDate">End Date</label>
                      <input 
                        type="datetime-local" 
                        id="endDate" 
                        name="endDate" 
                        value={filters.endDate}
                        onChange={handleFilterChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  
                  <div className="filter-actions">
                    <button type="submit" className="btn btn-primary">Apply Filters</button>
                    <button type="button" className="btn btn-secondary" onClick={resetFilters}>Reset</button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
        
        {/* Error message */}
        {error && (
          <div className="error-message">{error}</div>
        )}
        
        {/* Activity table */}
        <div className="table-container">
          {loading ? (
            <div className="loading">Loading activity logs...</div>
          ) : (
            <>
              <table className="data-table">
                <thead>
                  <tr>
                    {config.settings?.columns.timestamp && <th>Timestamp</th>}
                    {config.settings?.columns.action && <th>Action</th>}
                    {config.settings?.columns.user && <th>User</th>}
                    {config.settings?.columns.targetUser && <th>Target User</th>}
                    {config.settings?.columns.ipAddress && <th>IP Address</th>}
                    {config.settings?.columns.details && <th>Details</th>}
                  </tr>
                </thead>
                <tbody>
                  {activities.length === 0 ? (
                    <tr>
                      <td colSpan={Object.values(config.settings?.columns || {}).filter(Boolean).length} className="no-data">
                        No activities found
                      </td>
                    </tr>
                  ) : (
                    activities.map(activity => (
                      <tr key={activity._id}>
                        {config.settings?.columns.timestamp && <td>{formatDate(activity.timestamp)}</td>}
                        {config.settings?.columns.action && <td>{formatAction(activity.action)}</td>}
                        {config.settings?.columns.user && (
                          <td>{formatUserName(activity.user)}</td>
                        )}
                        {config.settings?.columns.targetUser && (
                          <td>{activity.targetUser ? formatUserName(activity.targetUser) : '-'}</td>
                        )}
                        {config.settings?.columns.ipAddress && <td>{activity.ipAddress || '-'}</td>}
                        {config.settings?.columns.details && (
                          <td>
                            {activity.details && Object.keys(activity.details).length > 0 
                              ? <pre>{JSON.stringify(activity.details, null, 2)}</pre> 
                              : '-'}
                          </td>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              
              {/* Pagination */}
              {pagination.pages > 1 && (
                <div className="pagination">
                  <button 
                    onClick={() => handlePageChange(1)} 
                    disabled={pagination.page === 1}
                    className="btn btn-secondary"
                  >
                    First
                  </button>
                  <button 
                    onClick={() => handlePageChange(pagination.page - 1)} 
                    disabled={pagination.page === 1}
                    className="btn btn-secondary"
                  >
                    Previous
                  </button>
                  <span className="pagination-info">
                    Page {pagination.page} of {pagination.pages}
                  </span>
                  <button 
                    onClick={() => handlePageChange(pagination.page + 1)} 
                    disabled={pagination.page === pagination.pages}
                    className="btn btn-secondary"
                  >
                    Next
                  </button>
                  <button 
                    onClick={() => handlePageChange(pagination.pages)} 
                    disabled={pagination.page === pagination.pages}
                    className="btn btn-secondary"
                  >
                    Last
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </ComponentWrapper>
  );
};

export default ActivityLogComponent; 