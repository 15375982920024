import { ReactNode } from 'react';
import { ComponentConfig, ComponentProps, Position } from './types';

// Re-export types for backward compatibility
export type { ComponentConfig, ComponentProps } from './types';

// Component definition interface
export interface ComponentDefinition {
  type: string;
  name: string;
  description: string;
  icon: string;
  defaultConfig: Omit<ComponentConfig, 'id' | 'type'>;
  requiredPermissions: string[];
  component: React.ComponentType<ComponentProps>;
  configPanel: React.ComponentType<ConfigPanelProps>;
}

// Config panel props interface
export interface ConfigPanelProps {
  config: ComponentConfig;
  onConfigChange: (newConfig: ComponentConfig) => void;
  onClose: () => void;
}

// Page interface
export interface Page {
  id: string;
  name: string;
  description: string;
  components: ComponentConfig[];
  isDefault?: boolean;
  isLocked?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

// Component registry
class ComponentRegistryClass {
  private components: Map<string, ComponentDefinition> = new Map();

  // Register a component
  register(componentDef: ComponentDefinition): void {
    if (this.components.has(componentDef.type)) {
      console.warn(`Component type '${componentDef.type}' is already registered. Overwriting.`);
    }
    this.components.set(componentDef.type, componentDef);
  }

  // Get a component by type
  get(type: string): ComponentDefinition | undefined {
    return this.components.get(type);
  }

  // Get all registered components
  getAll(): ComponentDefinition[] {
    return Array.from(this.components.values());
  }

  // Check if a component type exists
  has(type: string): boolean {
    return this.components.has(type);
  }
}

// Create singleton instance
export const ComponentRegistry = new ComponentRegistryClass();

// Helper function to create a new component config
export const createComponentConfig = (
  type: string,
  id: string = crypto.randomUUID(),
  overrides: Partial<Omit<ComponentConfig, 'id' | 'type'>> = {}
): ComponentConfig => {
  const componentDef = ComponentRegistry.get(type);
  if (!componentDef) {
    throw new Error(`Component type '${type}' is not registered.`);
  }

  return {
    id,
    type,
    ...componentDef.defaultConfig,
    isLocked: false,
    ...overrides,
  };
};

// Helper function to create a new page
export const createPage = (
  name: string,
  description: string = '',
  components: ComponentConfig[] = [],
  isDefault: boolean = false
): Page => {
  return {
    id: crypto.randomUUID(),
    name,
    description,
    components,
    isDefault,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
}; 