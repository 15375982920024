import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.css';
import { AuthProvider } from './context/AuthContext';
import { PageProvider } from './context/PageContext';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import EditProfile from './pages/EditProfile';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import SplashPage from './components/SplashPage';
import AdminUsers from './pages/admin/Users';
import InviteUser from './pages/admin/InviteUser';
import ActivityLogs from './pages/admin/ActivityLogs';
import UserActivities from './pages/admin/UserActivities';
import AddUser from './pages/admin/AddUser';



function App() {
  return (
    <Router>
      <AuthProvider>
        <PageProvider>
          <div className="App">
            <Navbar />
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/splash" element={<SplashPage />} />
              
              {/* Protected routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile/edit" element={<EditProfile />} />
                {/* Admin routes */}
                <Route path="/admin/users" element={<AdminUsers />} />
                <Route path="/admin/users/add" element={<AddUser />} />
                <Route path="/admin/invite" element={<InviteUser />} />
                <Route path="/admin/activities" element={<ActivityLogs />} />
                <Route path="/admin/activities/user/:userId" element={<UserActivities />} />
              </Route>
              
              {/* Fallback route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </PageProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
