// Email validation regex
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Username validation - alphanumeric and at least 3 characters
const USERNAME_REGEX = /^[a-zA-Z0-9_]{3,30}$/;

// Password validation - at least 8 characters, include one uppercase, one lowercase and one number
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

export const validateEmail = (email: string): string | null => {
  if (!email.trim()) {
    return 'Email is required';
  }
  
  if (!EMAIL_REGEX.test(email)) {
    return 'Email format is invalid';
  }
  
  return null;
};

export const validateUsername = (username: string): string | null => {
  if (!username.trim()) {
    return 'Username is required';
  }
  
  if (!USERNAME_REGEX.test(username)) {
    return 'Username must be at least 3 characters and can only contain letters, numbers, and underscores';
  }
  
  return null;
};

export const validatePassword = (password: string): string | null => {
  if (!password) {
    return 'Password is required';
  }
  
  if (password.length < 8) {
    return 'Password must be at least 8 characters';
  }
  
  if (!PASSWORD_REGEX.test(password)) {
    return 'Password must include uppercase letter, lowercase letter and number';
  }
  
  return null;
};

export const validateName = (name: string, fieldName: string): string | null => {
  if (name && name.length > 50) {
    return `${fieldName} should be less than 50 characters`;
  }
  
  return null;
};

export const validatePasswordConfirmation = (password: string, confirmation: string): string | null => {
  if (password !== confirmation) {
    return 'Passwords do not match';
  }
  
  return null;
}; 