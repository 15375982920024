import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userApi } from '../../services/api';
import '../../App.css';
import usePermissions from '../../hooks/usePermissions';
import { USER_PERMISSIONS } from '../../constants/permissions';

interface UserFormData {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  password: string;
  confirmPassword: string;
}

const AddUser: React.FC = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const canCreateUsers = hasPermission(USER_PERMISSIONS.CREATE_USER);
  
  const [formData, setFormData] = useState<UserFormData>({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    role: 'user',
    password: '',
    confirmPassword: ''
  });
  
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Handle form field changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handle cancel
  const handleCancel = () => {
    navigate('/admin/users');
  };

  // Handle save
  const handleSave = async () => {
    // Verify user has create permission before saving
    if (!canCreateUsers) {
      setError('You do not have permission to add users');
      return;
    }
    
    // Validation
    if (!formData.username || !formData.email || !formData.password) {
      setError('Username, email, and password are required');
      return;
    }
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      // Create user data object
      const userData = {
        username: formData.username,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        role: formData.role,
        password: formData.password
      };
      
      // Call API to create new user
      await userApi.createUser(userData);
      
      setSuccessMessage('User created successfully!');
      
      // After a brief delay, navigate back to users list
      setTimeout(() => {
        navigate('/admin/users');
      }, 2000);
      
    } catch (err: any) {
      console.error('Failed to create user:', err);
      const errorMessage = err.response?.data?.message || 'Failed to create user. Please try again.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // If user doesn't have permission, redirect
  if (!canCreateUsers) {
    return (
      <div style={{ padding: '20px' }}>
        <div style={{ color: 'red', backgroundColor: 'rgba(255,0,0,0.1)', padding: '10px', borderRadius: '4px' }}>
          You do not have permission to add users.
        </div>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      {error && <div style={{ color: 'red', marginBottom: '20px', backgroundColor: 'rgba(255,0,0,0.1)', padding: '10px', borderRadius: '4px' }}>{error}</div>}
      {successMessage && <div style={{ color: 'green', marginBottom: '20px', backgroundColor: 'rgba(0,255,0,0.1)', padding: '10px', borderRadius: '4px' }}>{successMessage}</div>}
      
      <div style={{ 
        backgroundColor: '#1a1a1a',
        border: '1px solid #333',
        borderRadius: '8px',
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto'
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h2 style={{ color: '#e4c87e', margin: 0 }}>Add New User</h2>
        </div>
        
        <div>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
            <button 
              onClick={handleSave}
              disabled={loading}
              style={{
                background: '#e4c87e',
                border: 'none',
                borderRadius: '4px',
                padding: '0.4rem 0.8rem',
                color: '#1a1a1a',
                cursor: loading ? 'default' : 'pointer',
                fontWeight: '500',
                fontSize: '0.9rem',
                opacity: loading ? 0.7 : 1
              }}
            >
              {loading ? 'Creating...' : 'Create User'}
            </button>
            <button 
              onClick={handleCancel}
              disabled={loading}
              style={{
                background: 'transparent',
                border: '1px solid #999',
                borderRadius: '4px',
                padding: '0.4rem 0.8rem',
                color: '#999',
                cursor: loading ? 'default' : 'pointer',
                fontWeight: '500',
                fontSize: '0.9rem'
              }}
            >
              Cancel
            </button>
          </div>
          <div style={{ 
            backgroundColor: '#252525',
            padding: '20px',
            borderRadius: '6px',
            marginBottom: '20px'
          }}>
            <table style={{ 
              width: '100%',
              borderCollapse: 'collapse'
            }}>
              <tbody>
                {/* Username field */}
                <tr>
                  <td style={{ padding: '10px 0', width: '120px' }}>
                    <label htmlFor="username" style={{ fontWeight: 'bold' }}>Username</label>
                  </td>
                  <td>
                    <input
                      id="username"
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '8px',
                        backgroundColor: '#333',
                        border: '1px solid #444',
                        borderRadius: '4px',
                        color: '#ddd'
                      }}
                    />
                  </td>
                </tr>
                
                {/* Email field */}
                <tr>
                  <td style={{ padding: '10px 0' }}>
                    <label htmlFor="email" style={{ fontWeight: 'bold' }}>Email</label>
                  </td>
                  <td>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '8px',
                        backgroundColor: '#333',
                        border: '1px solid #444',
                        borderRadius: '4px',
                        color: '#ddd'
                      }}
                    />
                  </td>
                </tr>
                
                {/* Password field */}
                <tr>
                  <td style={{ padding: '10px 0' }}>
                    <label htmlFor="password" style={{ fontWeight: 'bold' }}>Password</label>
                  </td>
                  <td>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '8px',
                        backgroundColor: '#333',
                        border: '1px solid #444',
                        borderRadius: '4px',
                        color: '#ddd'
                      }}
                    />
                  </td>
                </tr>
                
                {/* Confirm Password field */}
                <tr>
                  <td style={{ padding: '10px 0' }}>
                    <label htmlFor="confirmPassword" style={{ fontWeight: 'bold' }}>Confirm Password</label>
                  </td>
                  <td>
                    <input
                      id="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '8px',
                        backgroundColor: '#333',
                        border: '1px solid #444',
                        borderRadius: '4px',
                        color: '#ddd'
                      }}
                    />
                  </td>
                </tr>
                
                {/* First Name field */}
                <tr>
                  <td style={{ padding: '10px 0' }}>
                    <label htmlFor="firstName" style={{ fontWeight: 'bold' }}>First Name</label>
                  </td>
                  <td>
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '8px',
                        backgroundColor: '#333',
                        border: '1px solid #444',
                        borderRadius: '4px',
                        color: '#ddd'
                      }}
                    />
                  </td>
                </tr>
                
                {/* Last Name field */}
                <tr>
                  <td style={{ padding: '10px 0' }}>
                    <label htmlFor="lastName" style={{ fontWeight: 'bold' }}>Last Name</label>
                  </td>
                  <td>
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '8px',
                        backgroundColor: '#333',
                        border: '1px solid #444',
                        borderRadius: '4px',
                        color: '#ddd'
                      }}
                    />
                  </td>
                </tr>
                
                {/* Role field */}
                <tr>
                  <td style={{ padding: '10px 0' }}>
                    <label htmlFor="role" style={{ fontWeight: 'bold' }}>Role</label>
                  </td>
                  <td>
                    <select
                      id="role"
                      name="role"
                      value={formData.role}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '8px',
                        backgroundColor: '#333',
                        border: '1px solid #444',
                        borderRadius: '4px',
                        color: '#ddd',
                        cursor: 'pointer'
                      }}
                    >
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser; 