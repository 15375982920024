import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Add utility to detect maximize/restore events including browser maximize button
const setupWindowSizeTracker = () => {
  let lastWidth = window.innerWidth;
  let lastHeight = window.innerHeight;
  let lastChangeTime = Date.now();
  
  // Track whether we detected a maximize event that might need extra handling
  let potentialMaximizeEvent = false;
  
  // Specifically handle browser maximize button
  const handleMaximizeEvents = () => {
    // Check if window dimensions match screen dimensions (a good indicator of maximize)
    const isMaximized = 
      window.innerWidth >= window.screen.availWidth - 20 &&
      window.innerHeight >= window.screen.availHeight - 60;
    
    if (isMaximized) {
      console.log('Browser maximize detected - firing additional resize events');
      
      // Fire multiple resize events with different delays to ensure components catch it
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 50);
      
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 200);
      
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    }
  };
  
  // Check for significant size changes that might indicate maximize/restore
  window.addEventListener('resize', () => {
    const now = Date.now();
    
    // Allow more frequent resize checks (reduced throttling)
    if (now - lastChangeTime < 20) {
      lastChangeTime = now;
      return;
    }
    
    const widthChange = Math.abs(window.innerWidth - lastWidth);
    const heightChange = Math.abs(window.innerHeight - lastHeight);
    
    // If there's a significant change (likely maximize/restore)
    if (widthChange > 50 || heightChange > 50) {
      console.log('Significant window size change detected:', {
        widthChange,
        heightChange,
        oldSize: { width: lastWidth, height: lastHeight },
        newSize: { width: window.innerWidth, height: window.innerHeight }
      });
      
      // Check if this might be a maximize event
      if (window.innerWidth > lastWidth && window.innerHeight > lastHeight) {
        potentialMaximizeEvent = true;
        handleMaximizeEvents();
      }
      
      // Force a second resize event after a delay to ensure components adjust
      setTimeout(() => {
        console.log('Dispatching additional resize event for maximize/restore');
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }
    
    lastWidth = window.innerWidth;
    lastHeight = window.innerHeight;
    lastChangeTime = now;
  });
  
  // Also listen for window load event to handle initial maximize state
  window.addEventListener('load', () => {
    // Fire resize events to ensure proper initial layout
    setTimeout(() => {
      handleMaximizeEvents();
      window.dispatchEvent(new Event('resize'));
    }, 100);
  });
};

// Initialize the window size tracker
setupWindowSizeTracker();

// Create a root using the new React 18 API
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
