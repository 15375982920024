import React, { useState, useEffect } from 'react';
import { activityApi, userApi } from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import { USER_PERMISSIONS } from '../../constants/permissions';
import '../../components/FormStyles.css';

// Activity type definition
interface Activity {
  _id: string;
  userId: string;
  targetUserId?: string;
  action: string;
  details: any;
  ipAddress?: string;
  userAgent?: string;
  timestamp: string;
  user?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
  };
  targetUser?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
  };
}

// User type definition
interface User {
  _id: string;
  username: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role: string;
}

// Pagination type
interface Pagination {
  total: number;
  page: number;
  limit: number;
  pages: number;
}

const ActivityLogs: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  
  // State for activities and pagination
  const [activities, setActivities] = useState<Activity[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    page: 1,
    limit: 20,
    pages: 0
  });
  
  // State for filters
  const [filters, setFilters] = useState({
    action: '',
    userId: '',
    targetUserId: '',
    startDate: '',
    endDate: ''
  });
  
  // State for users list
  const [users, setUsers] = useState<User[]>([]);
  
  // State for loading
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Activity types for filtering
  const activityTypes = activityApi.getActivityTypes();
  
  // Check permissions and fetch data
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    
    if (!hasPermission(USER_PERMISSIONS.VIEW_ACTIVITY_LOGS)) {
      navigate('/');
      return;
    }
    
    fetchActivities();
    fetchUsers();
  }, [isAuthenticated, navigate, pagination.page]);
  
  // Fetch activities
  const fetchActivities = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await activityApi.getActivities({
        page: pagination.page,
        limit: pagination.limit,
        action: filters.action || undefined,
        userId: filters.userId || undefined,
        targetUserId: filters.targetUserId || undefined,
        startDate: filters.startDate || undefined,
        endDate: filters.endDate || undefined
      });
      
      setActivities(response.data.activities);
      setPagination(response.data.pagination);
    } catch (err) {
      console.error('Error fetching activities:', err);
      setError('Failed to load activity logs. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch users for filter dropdown
  const fetchUsers = async () => {
    try {
      const response = await userApi.getAllUsers();
      setUsers(response.data.users);
    } catch (err) {
      console.error('Error fetching users:', err);
      // Don't set error state here to avoid blocking the activity logs display
    }
  };
  
  // Handle filter change
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };
  
  // Apply filters
  const applyFilters = (e: React.FormEvent) => {
    e.preventDefault();
    setPagination(prev => ({ ...prev, page: 1 })); // Reset to first page
    fetchActivities();
  };
  
  // Reset filters
  const resetFilters = () => {
    setFilters({
      action: '',
      userId: '',
      targetUserId: '',
      startDate: '',
      endDate: ''
    });
    setPagination(prev => ({ ...prev, page: 1 }));
    fetchActivities();
  };
  
  // Format date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Get user display name
  const formatUserName = (user?: { firstName?: string; lastName?: string; username: string }) => {
    if (!user) return 'System';
    return user.firstName && user.lastName 
      ? `${user.firstName} ${user.lastName} (${user.username})` 
      : user.username;
  };
  
  // Format action for display
  const formatAction = (action: string) => {
    const activityType = activityTypes.find(type => type.value === action);
    if (activityType) {
      return activityType.label;
    }
    
    // Fallback to the original formatting if not found in activity types
    return action
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  
  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > pagination.pages) return;
    setPagination(prev => ({ ...prev, page: newPage }));
  };
  
  // View user activities
  const viewUserActivities = (userId: string) => {
    navigate(`/admin/activities/user/${userId}`);
  };
  
  return (
    <div className="container">
      <h1>Activity Logs</h1>
      
      {/* Filters */}
      <div className="filter-section">
        <form onSubmit={applyFilters}>
          <div className="filter-row">
            <div className="filter-item">
              <label htmlFor="action">Action Type</label>
              <select 
                id="action" 
                name="action" 
                value={filters.action}
                onChange={handleFilterChange}
              >
                <option value="">All Actions</option>
                {activityTypes.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            
            <div className="filter-item">
              <label htmlFor="userId">User</label>
              <select 
                id="userId" 
                name="userId" 
                value={filters.userId}
                onChange={handleFilterChange}
              >
                <option value="">All Users</option>
                {users.map(user => (
                  <option key={user._id} value={user._id}>
                    {user.firstName && user.lastName 
                      ? `${user.firstName} ${user.lastName} (${user.username})` 
                      : user.username}
                  </option>
                ))}
              </select>
            </div>
            
            <div className="filter-item">
              <label htmlFor="targetUserId">Target User</label>
              <select 
                id="targetUserId" 
                name="targetUserId" 
                value={filters.targetUserId}
                onChange={handleFilterChange}
              >
                <option value="">All Target Users</option>
                {users.map(user => (
                  <option key={user._id} value={user._id}>
                    {user.firstName && user.lastName 
                      ? `${user.firstName} ${user.lastName} (${user.username})` 
                      : user.username}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="filter-row">
            <div className="filter-item">
              <label htmlFor="startDate">Start Date</label>
              <input 
                type="date" 
                id="startDate" 
                name="startDate" 
                value={filters.startDate}
                onChange={handleFilterChange}
              />
            </div>
            
            <div className="filter-item">
              <label htmlFor="endDate">End Date</label>
              <input 
                type="date" 
                id="endDate" 
                name="endDate" 
                value={filters.endDate}
                onChange={handleFilterChange}
              />
            </div>
            
            <div className="filter-item filter-buttons">
              <button type="submit" className="btn-primary">Apply Filters</button>
              <button type="button" className="btn-secondary" onClick={resetFilters}>Reset</button>
            </div>
          </div>
        </form>
      </div>
      
      {/* Error message */}
      {error && <div className="error-message">{error}</div>}
      
      {/* Loading indicator */}
      {loading ? (
        <div className="loading">Loading activity logs...</div>
      ) : (
        <>
          {/* Activity table */}
          <div className="table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Action</th>
                  <th>User</th>
                  <th>Target User</th>
                  <th>IP Address</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {activities.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="no-data">No activities found</td>
                  </tr>
                ) : (
                  activities.map(activity => (
                    <tr key={activity._id}>
                      <td>{formatDate(activity.timestamp)}</td>
                      <td>{formatAction(activity.action)}</td>
                      <td>
                        {activity.user ? (
                          <button 
                            className="link-button"
                            onClick={() => viewUserActivities(activity.userId)}
                          >
                            {formatUserName(activity.user)}
                          </button>
                        ) : (
                          'System'
                        )}
                      </td>
                      <td>
                        {activity.targetUser ? (
                          <button 
                            className="link-button"
                            onClick={() => viewUserActivities(activity.targetUserId!)}
                          >
                            {formatUserName(activity.targetUser)}
                          </button>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>{activity.ipAddress || '-'}</td>
                      <td>
                        {activity.details && Object.keys(activity.details).length > 0 ? (
                          <details>
                            <summary>View Details</summary>
                            <pre>{JSON.stringify(activity.details, null, 2)}</pre>
                          </details>
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          
          {/* Pagination */}
          {pagination.pages > 1 && (
            <div className="pagination">
              <button 
                onClick={() => handlePageChange(pagination.page - 1)}
                disabled={pagination.page === 1}
              >
                Previous
              </button>
              
              <span>
                Page {pagination.page} of {pagination.pages}
              </span>
              
              <button 
                onClick={() => handlePageChange(pagination.page + 1)}
                disabled={pagination.page === pagination.pages}
              >
                Next
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ActivityLogs; 