import React, { useEffect, useState } from 'react';
import PageView from '../components/ComponentSystem/PageView';
import { usePage } from '../context/PageContext';

// Import all component registrations
import '../components/ComponentSystem/Components/ActivityLog';
import '../components/ComponentSystem/Components/TimeChart';
import '../components/ComponentSystem/Components/NotificationMessages';
import '../components/ComponentSystem/Components/WaterFlowStats';

const Dashboard: React.FC = () => {
  const [showReorganizeButton, setShowReorganizeButton] = useState(false);
  const [notification, setNotification] = useState<{message: string, type: 'info' | 'warning' | 'error'} | null>(null);
  const { currentPage } = usePage();
  
  // Add viewport meta tag to ensure proper responsive behavior
  useEffect(() => {
    // Check if viewport meta tag exists
    let viewportMeta = document.querySelector('meta[name="viewport"]');
    
    // If it doesn't exist, create it
    if (!viewportMeta) {
      viewportMeta = document.createElement('meta');
      viewportMeta.setAttribute('name', 'viewport');
      document.head.appendChild(viewportMeta);
    }
    
    // Set the content attribute for proper responsive behavior
    viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    
    // Clean up on unmount
    return () => {
      // Reset to default if needed
      if (viewportMeta) {
        viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0');
      }
    };
  }, []);
  
  // Show reorganize button when window size changes
  useEffect(() => {
    const handleResize = () => {
      setShowReorganizeButton(true);
      
      // Hide button after 5 seconds
      setTimeout(() => {
        setShowReorganizeButton(false);
      }, 5000);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Handle reorganize button click
  const handleReorganize = () => {
    // Check if page is locked
    if (currentPage?.isLocked) {
      setNotification({
        message: 'Cannot reorganize components on a locked page',
        type: 'warning'
      });
      
      // Clear notification after 3 seconds
      setTimeout(() => {
        setNotification(null);
      }, 3000);
      
      return;
    }
    
    // Dispatch a custom event that PageView will listen for
    const event = new CustomEvent('reorganize-components', {
      detail: { trigger: 'manual' }
    });
    document.dispatchEvent(event);
    
    // Hide the button
    setShowReorganizeButton(false);
  };
  
  // Listen for page lock notifications
  useEffect(() => {
    const handlePageLockEvent = (event: CustomEvent) => {
      setNotification({
        message: event.detail.message,
        type: event.detail.type || 'info'
      });
      
      // Clear notification after 3 seconds
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    };
    
    // Add event listener
    document.addEventListener('page-lock-notification', handlePageLockEvent as EventListener);
    
    // Clean up
    return () => {
      document.removeEventListener('page-lock-notification', handlePageLockEvent as EventListener);
    };
  }, []);
  
  return (
    <div className="dashboard">
      <PageView />
      
      {/* For testing - button to open component library */}
      <button 
        className="test-component-library-button"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 9999,
          padding: '8px 16px',
          background: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
        onClick={() => {
          const event = new CustomEvent('toggle-component-library', { 
            detail: { show: true } 
          });
          document.dispatchEvent(event);
          console.log('Dispatched event to open component library');
        }}
      >
        Open Component Library
      </button>
      
      {/* Reorganize button */}
      {showReorganizeButton && (
        <button 
          className="reorganize-button"
          onClick={handleReorganize}
        >
          Reorganize Windows
        </button>
      )}
      
      {/* Notification */}
      {notification && (
        <div className={`dashboard-notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default Dashboard; 