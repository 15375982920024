import React, { useState, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { validateEmail } from '../utils/validation';
import '../components/FormStyles.css';

const ForgotPassword: React.FC = () => {
  const { forgotPassword, isLoading } = useAuth();
  
  // Form state
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  // Handle input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(null);
    setApiError(null);
  };
  
  // Validate form
  const validateForm = (): boolean => {
    const error = validateEmail(email);
    setEmailError(error);
    return !error;
  };
  
  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setApiError(null);
    
    // Validate form
    if (!validateForm()) {
      return;
    }
    
    try {
      // Call forgot password function from context
      await forgotPassword(email);
      setIsSubmitted(true);
    } catch (error: any) {
      // Display error message (although we usually don't reveal if the email exists or not)
      setApiError('An error occurred. Please try again later.');
    }
  };
  
  return (
    <div className="auth-container">
      <div className="auth-card">
        <h1 className="auth-title">Forgot Password</h1>
        
        {isSubmitted ? (
          <div className="success-message">
            <p>If an account exists with the email you provided, we've sent instructions to reset your password.</p>
            <p>Please check your email inbox and follow the instructions.</p>
            <div className="auth-links">
              <Link to="/login">Return to Login</Link>
            </div>
          </div>
        ) : (
          <>
            {apiError && <div className="error-message" style={{ marginBottom: '1rem' }}>{apiError}</div>}
            
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email address"
                  required
                  className={emailError ? 'error' : ''}
                />
                {emailError && <div className="input-error">{emailError}</div>}
              </div>
              
              <button 
                type="submit" 
                className="auth-button" 
                disabled={isLoading}
              >
                {isLoading ? 'Sending...' : 'Send Reset Link'}
              </button>
            </form>
            
            <div className="auth-links">
              <p>
                Remembered your password? <Link to="/login">Login here</Link>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword; 