import { ComponentRegistry } from '../../ComponentRegistry';
import NotificationMessagesComponent, { defaultSettings } from './NotificationMessagesComponent';
import NotificationMessagesConfig from './NotificationMessagesConfig';
import { USER_PERMISSIONS } from '../../../../constants/permissions';

// Register the NotificationMessages component
ComponentRegistry.register({
  type: 'notification-messages',
  name: 'Notification Messages',
  description: 'Displays notification messages sent through the notification system',
  icon: '📨',
  defaultConfig: {
    title: defaultSettings.title,
    position: {
      x: 100,
      y: 100,
      width: 800,
      height: 600,
      zIndex: 1
    },
    isMinimized: false,
    isLocked: false,
    settings: defaultSettings
  },
  requiredPermissions: [USER_PERMISSIONS.VIEW_NOTIFICATIONS],
  component: NotificationMessagesComponent,
  configPanel: NotificationMessagesConfig
});

export { NotificationMessagesComponent, NotificationMessagesConfig, defaultSettings }; 