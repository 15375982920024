import { ComponentRegistry } from '../../ComponentRegistry';
import ActivityLogComponent, { defaultSettings } from './ActivityLogComponent';
import ActivityLogConfig from './ActivityLogConfig';
import { USER_PERMISSIONS } from '../../../../constants/permissions';

// Register the ActivityLog component
ComponentRegistry.register({
  type: 'activity-log',
  name: 'Activity Log',
  description: 'Displays user activity logs with filtering and pagination',
  icon: '📋',
  defaultConfig: {
    title: defaultSettings.title,
    position: {
      x: 100,
      y: 100,
      width: 800,
      height: 600,
      zIndex: 1
    },
    isMinimized: false,
    isLocked: false,
    settings: defaultSettings
  },
  requiredPermissions: [USER_PERMISSIONS.VIEW_ACTIVITY_LOGS],
  component: ActivityLogComponent,
  configPanel: ActivityLogConfig
});

export { ActivityLogComponent, ActivityLogConfig, defaultSettings }; 