import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Select, Button, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { SensorType } from '../../../../services/timeSeriesService';
import moment from 'moment-timezone';

const { Option } = Select;

export interface SensorConfig {
  topic_pattern: string;
  description: string;
  event_type: SensorType;
  value_path: string;
  value_type: string;
  thresholds?: {
    warning: number;
    critical: number;
  };
}

export interface SeriesConfig {
  name: string;
  sensorType: SensorType;
  sensorId: string;
  valueField: string;
  chartType: 'line' | 'bar' | 'area';
  color?: string;
  yAxisPosition?: 'left' | 'right';
}

export interface DataSourceConfig {
  timeRange: {
    type: 'preset' | 'custom';
    preset?: 'hour' | 'day' | 'week' | 'month' | 'year' | '24hours' | '7days' | '30days';
    custom?: {
      start: Date;
      end: Date;
    };
  };
  aggregation: {
    level: 'none' | 'minute' | '5minutes' | '15minutes' | '30minutes' | 'hour' | 'day' | 'week';
    method: 'avg' | 'sum' | 'min' | 'max';
  };
  series: SeriesConfig[];
  refresh?: {
    autoRefresh: boolean;
    interval: number;
  };
}

interface DataSourceConfigModalProps {
  open: boolean;
  onClose: () => void;
  onApply: (config: DataSourceConfig) => void;
  initialConfig?: DataSourceConfig;
  sensors?: { [key in SensorType]: SensorConfig[] };
}

const DEFAULT_CONFIG: DataSourceConfig = {
  timeRange: {
    type: 'preset',
    preset: '24hours'
  },
  aggregation: {
    level: 'none',
    method: 'avg'
  },
  series: [],
  refresh: {
    autoRefresh: false,
    interval: 0
  }
};

const DataSourceConfigModal: React.FC<DataSourceConfigModalProps> = ({
  open,
  onClose,
  onApply,
  initialConfig,
  sensors
}) => {
  const [form] = Form.useForm();
  const pacificTimezone = 'America/Los_Angeles';
  const modalContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialConfig) {
      // Create a deep copy of the config for form display
      const formValues: any = JSON.parse(JSON.stringify(initialConfig));
      
      if (formValues.timeRange?.type === 'custom' && formValues.timeRange.custom) {
        const start = formValues.timeRange.custom.start;
        const end = formValues.timeRange.custom.end;
        
        if (start) {
          const pacificStart = moment.utc(start).tz(pacificTimezone);
          formValues.timeRange.custom.start = pacificStart.format('YYYY-MM-DDTHH:mm');
        }
        
        if (end) {
          const pacificEnd = moment.utc(end).tz(pacificTimezone);
          formValues.timeRange.custom.end = pacificEnd.format('YYYY-MM-DDTHH:mm');
        }
      }
      
      form.setFieldsValue(formValues);
    }
  }, [initialConfig, form, pacificTimezone]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      
      // Create a copy of the form values to convert string dates to Date objects
      const configValues: DataSourceConfig = JSON.parse(JSON.stringify(values));
      
      if (configValues.timeRange?.type === 'custom' && configValues.timeRange.custom) {
        const { start, end } = values.timeRange.custom;
        
        if (start) {
          configValues.timeRange.custom!.start = moment.tz(start, pacificTimezone).toDate();
        }
        
        if (end) {
          configValues.timeRange.custom!.end = moment.tz(end, pacificTimezone).toDate();
        }
      }
      
      onApply(configValues);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const getValueFieldOptions = (sensorType: SensorType, sensorId: string) => {
    if (!sensors) return [];

    const sensor = sensors[sensorType]?.find(s => s.topic_pattern === sensorId);
    if (!sensor) return [];

    switch (sensorType) {
      case 'WATER_DEPTH':
        return [
          { value: 'value', label: 'Depth (feet)' },
          { value: 'percent_full', label: 'Percent Full (%)' }
        ];
      case 'PRESSURE':
        return [
          { value: 'value', label: 'Pressure (PSI)' }
        ];
      case 'WATER_FLOW':
        return [
          { value: 'value', label: 'Flow Rate' },
          { value: 'gallons', label: 'Total Gallons' },
          { value: 'cuft', label: 'Cubic Feet' }
        ];
      default:
        return [];
    }
  };

  return (
    <Modal
      title="Data Source Configuration"
      open={open}
      onCancel={onClose}
      onOk={handleOk}
      width={800}
      maskClosable={false}
      className="data-source-config-modal"
      zIndex={9500001}
      maskStyle={{ zIndex: 9500000 }}
    >
      <div ref={modalContainerRef} style={{ position: 'relative' }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            timeRange: {
              type: 'preset',
              preset: '24hours'
            },
            aggregation: {
              level: 'none',
              method: 'avg'
            },
            series: []
          }}
        >
          <div className="section">
            <h4>Time Range</h4>
            <Form.Item name={['timeRange', 'type']} label="Range Type">
              <Select dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                <Select.Option value="preset">Preset</Select.Option>
                <Select.Option value="custom">Custom</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.timeRange?.type !== currentValues.timeRange?.type
              }
            >
              {({ getFieldValue }) =>
                getFieldValue(['timeRange', 'type']) === 'preset' ? (
                  <Form.Item name={['timeRange', 'preset']} label="Preset Range">
                    <Select dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                      <Select.Option value="24hours">Last 24 Hours</Select.Option>
                      <Select.Option value="7days">Last 7 Days</Select.Option>
                      <Select.Option value="30days">Last 30 Days</Select.Option>
                      <Select.Option value="hour">Previous Hour</Select.Option>
                      <Select.Option value="day">Previous Day</Select.Option>
                      <Select.Option value="week">Previous Week</Select.Option>
                      <Select.Option value="month">Previous Month</Select.Option>
                      <Select.Option value="year">Previous Year</Select.Option>
                    </Select>
                  </Form.Item>
                ) : (
                  <Space>
                    <Form.Item name={['timeRange', 'custom', 'start']} label="Start Date (Pacific Time)">
                      <Input type="datetime-local" />
                    </Form.Item>
                    <Form.Item name={['timeRange', 'custom', 'end']} label="End Date (Pacific Time)">
                      <Input type="datetime-local" />
                    </Form.Item>
                  </Space>
                )
              }
            </Form.Item>
          </div>

          <div className="section">
            <h4>Aggregation</h4>
            <Space>
              <Form.Item name={['aggregation', 'level']} label="Level">
                <Select dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                  <Select.Option value="none">None</Select.Option>
                  <Select.Option value="minute">Minute</Select.Option>
                  <Select.Option value="5minutes">5 Minutes</Select.Option>
                  <Select.Option value="15minutes">15 Minutes</Select.Option>
                  <Select.Option value="30minutes">30 Minutes</Select.Option>
                  <Select.Option value="hour">Hour</Select.Option>
                  <Select.Option value="day">Day</Select.Option>
                  <Select.Option value="week">Week</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name={['aggregation', 'method']} label="Method">
                <Select dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                  <Select.Option value="avg">Average</Select.Option>
                  <Select.Option value="sum">Sum</Select.Option>
                  <Select.Option value="min">Minimum</Select.Option>
                  <Select.Option value="max">Maximum</Select.Option>
                </Select>
              </Form.Item>
            </Space>
          </div>

          <div className="section">
            <div className="section-header">
              <h4>Series</h4>
            </div>

            <Form.List name="series">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key} className="series-item">
                      <div className="series-header">
                        <h5>Series {index + 1}</h5>
                        <Button
                          type="text"
                          onClick={() => remove(field.name)}
                          icon={<MinusCircleOutlined />}
                        />
                      </div>

                      <Form.Item
                        {...field}
                        label="Name"
                        name={[field.name, 'name']}
                        rules={[{ required: true, message: 'Please enter a name' }]}
                      >
                        <Input />
                      </Form.Item>

                      <Space>
                        <Form.Item
                          {...field}
                          label="Sensor Type"
                          name={[field.name, 'sensorType']}
                          rules={[{ required: true, message: 'Please select a sensor type' }]}
                        >
                          <Select style={{ width: 200 }} dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                            {Object.keys(sensors || {}).map((type) => (
                              <Select.Option key={type} value={type}>
                                {type.replace('_', ' ')}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) => {
                            const prevSensorType = prevValues.series?.[field.name]?.sensorType;
                            const currentSensorType = currentValues.series?.[field.name]?.sensorType;
                            return prevSensorType !== currentSensorType;
                          }}
                        >
                          {({ getFieldValue }) => {
                            const sensorType = getFieldValue(['series', field.name, 'sensorType']);
                            return sensorType ? (
                              <Form.Item
                                {...field}
                                label="Sensor"
                                name={[field.name, 'sensorId']}
                                rules={[{ required: true, message: 'Please select a sensor' }]}
                              >
                                <Select style={{ width: 200 }} dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                                  {(sensors?.[sensorType] || []).map((sensor) => (
                                    <Select.Option key={sensor.topic_pattern} value={sensor.topic_pattern}>
                                      {sensor.description || sensor.topic_pattern}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            ) : null;
                          }}
                        </Form.Item>

                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) => {
                            const prevSensorType = prevValues.series?.[field.name]?.sensorType;
                            const prevSensorId = prevValues.series?.[field.name]?.sensorId;
                            const currentSensorType = currentValues.series?.[field.name]?.sensorType;
                            const currentSensorId = currentValues.series?.[field.name]?.sensorId;
                            return prevSensorType !== currentSensorType || prevSensorId !== currentSensorId;
                          }}
                        >
                          {({ getFieldValue }) => {
                            const sensorType = getFieldValue(['series', field.name, 'sensorType']);
                            const sensorId = getFieldValue(['series', field.name, 'sensorId']);
                            return sensorType && sensorId ? (
                              <Form.Item
                                {...field}
                                label="Value Field"
                                name={[field.name, 'valueField']}
                                rules={[{ required: true, message: 'Please select a value field' }]}
                              >
                                <Select style={{ width: 200 }} dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                                  {getValueFieldOptions(sensorType, sensorId).map((option) => (
                                    <Select.Option key={option.value} value={option.value}>
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            ) : null;
                          }}
                        </Form.Item>
                      </Space>

                      <Space>
                        <Form.Item
                          {...field}
                          label="Chart Type"
                          name={[field.name, 'chartType']}
                          rules={[{ required: true, message: 'Please select a chart type' }]}
                        >
                          <Select style={{ width: 120 }} dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                            <Select.Option value="line">Line</Select.Option>
                            <Select.Option value="bar">Bar</Select.Option>
                            <Select.Option value="area">Area</Select.Option>
                          </Select>
                        </Form.Item>
                      </Space>

                      <Space>
                        <Form.Item
                          {...field}
                          label="Color"
                          name={[field.name, 'color']}
                        >
                          <Input type="color" className="color-input" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          label="Y-Axis Position"
                          name={[field.name, 'yAxisPosition']}
                        >
                          <Select style={{ width: 120 }} dropdownStyle={{ zIndex: 9999999 }} getPopupContainer={() => modalContainerRef.current || document.body}>
                            <Select.Option value="left">Left</Select.Option>
                            <Select.Option value="right">Right</Select.Option>
                          </Select>
                        </Form.Item>
                      </Space>
                    </div>
                  ))}

                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Series
                  </Button>
                </>
              )}
            </Form.List>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default DataSourceConfigModal; 