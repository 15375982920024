import { ComponentRegistry } from '../../ComponentRegistry';
import TimeChartComponent, { defaultSettings } from './TimeChartComponent';
import TimeChartConfig from './TimeChartConfig';
import { USER_PERMISSIONS } from '../../../../constants/permissions';

// Register the TimeChart component
ComponentRegistry.register({
  type: 'time-chart',
  name: 'Time Chart',
  description: 'Displays time-based charts and metrics using Recharts',
  icon: '📊',
  defaultConfig: {
    title: 'New Time Chart',
    position: {
      x: 100,
      y: 100,
      width: 800,
      height: 500,
      zIndex: 1
    },
    isMinimized: false,
    isLocked: false,
    settings: {
      ...defaultSettings,
      title: 'New Time Chart',
      dataSourceConfig: {
        timeRange: {
          type: 'preset',
          preset: '24hours'
        },
        aggregation: {
          level: 'none',
          method: 'avg'
        },
        series: [],
        refresh: {
          autoRefresh: false,
          interval: 0
        }
      }
    }
  },
  requiredPermissions: [], // No permissions required - allowing everyone to use this component
  component: TimeChartComponent,
  configPanel: TimeChartConfig
});

export { TimeChartComponent, TimeChartConfig, defaultSettings }; 