import { ComponentRegistry } from '../../ComponentRegistry';
import WaterFlowStatsComponent, { defaultSettings } from './WaterFlowStatsComponent';
import WaterFlowStatsConfig from './WaterFlowStatsConfig';
import { USER_PERMISSIONS } from '../../../../constants/permissions';

// Register the WaterFlowStats component
ComponentRegistry.register({
  type: 'water-flow-stats',
  name: 'Water Flow Stats',
  description: 'Displays water flow statistics for barn and well over different time periods',
  icon: '💧',
  defaultConfig: {
    title: defaultSettings.title,
    position: {
      x: 100,
      y: 100,
      width: 400,
      height: 300,
      zIndex: 1
    },
    isMinimized: false,
    isLocked: false,
    settings: defaultSettings
  },
  requiredPermissions: [USER_PERMISSIONS.VIEW_WATER_DATA],
  component: WaterFlowStatsComponent,
  configPanel: WaterFlowStatsConfig
});

export { WaterFlowStatsComponent, WaterFlowStatsConfig, defaultSettings }; 