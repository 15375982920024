/**
 * Auth utility functions for token management
 */

/**
 * Get the authentication token from localStorage
 * @returns {string|null} The JWT token or null if not found
 */
export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

/**
 * Save authentication token to localStorage
 * @param {string} token - JWT token to save
 */
export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

/**
 * Remove the authentication token from localStorage
 */
export const removeToken = (): void => {
  localStorage.removeItem('token');
};

/**
 * Check if the user is authenticated (has a token)
 * @returns {boolean} True if the user has a token
 */
export const isAuthenticated = (): boolean => {
  return !!getToken();
};

/**
 * Decode the JWT token to extract user information
 * (Basic implementation - for production, use a library like jwt-decode)
 * @param {string} token - JWT token to decode
 * @returns {any} Decoded token payload or null if invalid
 */
export const decodeToken = (token: string): any => {
  try {
    // Split the token and get the payload part (second part)
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // Decode the base64 string
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export default {
  getToken,
  setToken,
  removeToken,
  isAuthenticated,
  decodeToken
}; 