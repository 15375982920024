import React, { useState, useEffect } from 'react';
import { invitationApi } from '../../services/api';
import '../../components/FormStyles.css';

interface Invitation {
  id: string;
  email: string;
  status: 'pending' | 'accepted' | 'expired' | 'cancelled';
  expiresAt: string;
  createdAt: string;
  invitedBy?: {
    username: string;
    email: string;
  };
}

const InviteUser: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    fetchInvitations();
  }, [refreshKey]);

  const fetchInvitations = async () => {
    try {
      setLoading(true);
      const response = await invitationApi.getAllInvitations();
      setInvitations(response.data.invitations);
    } catch (error) {
      console.error('Failed to fetch invitations:', error);
      setMessage({ 
        text: 'Failed to fetch invitations. Please try again.', 
        type: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      setMessage({ text: 'Email is required', type: 'error' });
      return;
    }

    try {
      setLoading(true);
      await invitationApi.sendInvitation({ email, role: 'user' });
      setMessage({ text: 'Invitation sent successfully!', type: 'success' });
      setEmail('');
      fetchInvitations(); // Refresh the list
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Failed to send invitation';
      setMessage({ text: errorMessage, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleResendInvitation = async (id: string) => {
    try {
      setLoading(true);
      await invitationApi.resendInvitation(id);
      setMessage({ text: 'Invitation resent successfully!', type: 'success' });
      fetchInvitations(); // Refresh the list
    } catch (error) {
      console.error('Failed to resend invitation:', error);
      setMessage({ 
        text: 'Failed to resend invitation. Please try again.', 
        type: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelInvitation = async (id: string) => {
    try {
      setLoading(true);
      await invitationApi.cancelInvitation(id);
      setMessage({ text: 'Invitation cancelled successfully!', type: 'success' });
      fetchInvitations(); // Refresh the list
    } catch (error) {
      console.error('Failed to cancel invitation:', error);
      setMessage({ 
        text: 'Failed to cancel invitation. Please try again.', 
        type: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  // Format date to readable format
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <div className="form-container">
      <h1>Invite User</h1>
      
      {message.text && (
        <div className={`alert ${message.type === 'error' ? 'alert-error' : 'alert-success'}`}>
          {message.text}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            required
          />
        </div>
        
        <div className="form-info">
          <p style={{ color: '#999', marginBottom: '15px', fontSize: '0.9rem' }}>
            All invitations are for regular users. Admin privileges can be granted later if needed.
          </p>
        </div>
        
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Sending...' : 'Send Invitation'}
        </button>
      </form>
      
      <div className="invitation-list">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Invitations</h2>
          <button 
            onClick={handleRefresh} 
            className="btn-small"
            disabled={loading}
            style={{ marginBottom: '1rem' }}
          >
            Refresh List
          </button>
        </div>
        
        {loading && <p>Loading...</p>}
        
        {invitations.length === 0 ? (
          <p>No invitations found.</p>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Status</th>
                <th>Expires At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {invitations.map((invitation) => (
                <tr key={invitation.id}>
                  <td>{invitation.email}</td>
                  <td>
                    <span 
                      className={`status-${invitation.status.toLowerCase()}`}
                      style={{
                        padding: '3px 8px',
                        borderRadius: '4px',
                        backgroundColor: 
                          invitation.status === 'pending' ? 'rgba(255, 165, 0, 0.2)' :
                          invitation.status === 'accepted' ? 'rgba(0, 128, 0, 0.2)' :
                          invitation.status === 'expired' ? 'rgba(128, 128, 128, 0.2)' : 
                          'rgba(255, 0, 0, 0.2)',
                        color:
                          invitation.status === 'pending' ? 'orange' :
                          invitation.status === 'accepted' ? 'green' :
                          invitation.status === 'expired' ? 'gray' : 
                          'red',
                      }}
                    >
                      {invitation.status}
                    </span>
                  </td>
                  <td>{formatDate(invitation.expiresAt)}</td>
                  <td>
                    {invitation.status === 'pending' && (
                      <>
                        <button
                          onClick={() => handleResendInvitation(invitation.id)}
                          className="btn-small"
                          disabled={loading}
                          style={{ marginRight: '5px' }}
                        >
                          Resend
                        </button>
                        <button
                          onClick={() => handleCancelInvitation(invitation.id)}
                          className="btn-small btn-danger"
                          disabled={loading}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default InviteUser; 