import React from 'react';
import './NotificationMessages.css';

interface SentMessage {
  _id: string;
  channel: string;
  recipient: {
    user_id: string;
    phone?: string;
    email?: string;
  };
  message_text: string;
  html_content?: string | null;
  subject?: string;
  sent_at: string;
  created_at: string;
  recipientDetails?: {
    _id: string;
    user_id: string;
    name: string;
    email: string;
    phone: string;
  };
}

interface MessageDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: SentMessage | null;
}

const MessageDetailModal: React.FC<MessageDetailModalProps> = ({ isOpen, onClose, message }) => {
  if (!isOpen || !message) return null;

  // Format date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Get recipient display
  const getRecipientDisplay = () => {
    if (message.channel === 'email' && message.recipient.email) {
      return message.recipient.email;
    }
    if (message.channel === 'sms' && message.recipient.phone) {
      return message.recipient.phone;
    }
    return message.recipient.user_id;
  };

  return (
    <>
      <div className="message-modal-overlay" onClick={onClose}></div>
      <div className="message-modal">
        <div className="message-modal-header">
          <h3>Message Details</h3>
          <button className="modal-close-btn" onClick={onClose}>×</button>
        </div>
        
        <div className="message-modal-content">
          <div className="message-metadata">
            <div className="metadata-row">
              <div className="metadata-group">
                <span className="metadata-label">Sent:</span>
                <span className="metadata-value">{formatDate(message.sent_at)}</span>
              </div>
              <div className="metadata-group">
                <span className="metadata-label">Channel:</span>
                <span className="metadata-value">{message.channel}</span>
              </div>
            </div>
            
            <div className="metadata-row">
              <div className="metadata-group">
                <span className="metadata-label">Recipient:</span>
                <span className="metadata-value">
                  {message.recipientDetails?.name || message.recipient.user_id} 
                  <span className="metadata-secondary">({getRecipientDisplay()})</span>
                </span>
              </div>
              
              {message.subject && (
                <div className="metadata-group">
                  <span className="metadata-label">Subject:</span>
                  <span className="metadata-value">{message.subject}</span>
                </div>
              )}
            </div>
          </div>
          
          <div className="message-detail-item message-main-content">
            <div className="message-detail-label">Message:</div>
            <div className="message-detail-value message-content">
              {message.html_content ? (
                <div className="html-content-wrapper" dangerouslySetInnerHTML={{ __html: message.html_content }} />
              ) : (
                message.message_text
              )}
            </div>
          </div>
          
          <div className="message-footer-info">
            <div className="metadata-group">
              <span className="metadata-label">Created:</span>
              <span className="metadata-value">{formatDate(message.created_at)}</span>
            </div>
            <div className="metadata-group">
              <span className="metadata-label">ID:</span>
              <span className="metadata-value message-id-small">{message._id}</span>
            </div>
          </div>
        </div>
        
        <div className="message-modal-footer">
          <button className="close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default MessageDetailModal; 