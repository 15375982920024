import axios from 'axios';
import { getToken } from '../utils/auth';
import { API_PREFIX } from './api';

// Define SensorType directly in this file
export type SensorType = string;

// Determine if we're in production based on hostname
// Replace this with proper env var loading when environment variables are working
const isProduction = typeof window !== 'undefined' && 
  (window.location.hostname !== 'localhost' && 
   !window.location.hostname.includes('127.0.0.1'));

const API_BASE_URL = isProduction ? 'https://api.georgectrl.com' : 'http://localhost:5200';
// const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.georgectrl.com';

// Constants for main sensor types for type checking
export const SENSOR_TYPES = {
  WATER_DEPTH: 'WATER_DEPTH',
  PRESSURE: 'PRESSURE',
  WATER_FLOW: 'WATER_FLOW'
} as const;

export interface TopicConfig {
  topic_pattern: string;
  description: string;
  event_type: SensorType;
  value_path: string;
}

export interface MqttMessage {
  topic: string;
  timestamp: string;
  payload: {
    value: number;
    unit?: string;
    percent_full?: number;
    gallons?: number;
    cuft?: number;
    pulses?: number;
    [key: string]: any;
  };
}

// Add TimeSeriesData interface that represents transformed data
export interface TimeSeriesData {
  timestamp: string;
  value: number;
  percent_full?: number;
  gallons?: number;
  cuft?: number;
  pulses?: number;
  unit?: string;
}

export interface TimeSeriesParams {
  sensorType: SensorType;
  sensorId: string;
  startTime: Date;
  endTime?: Date;
  aggregation?: {
    level: 'none' | 'minute' | '5minutes' | '15minutes' | '30minutes' | 'hour' | 'day' | 'week';
    method: 'avg' | 'sum' | 'min' | 'max';
  };
}

export interface Sensor {
  id: string;
  name: string;
  type: SensorType;
}

export interface SensorsResponse {
  success: boolean;
  data?: { [key: string]: Sensor[] };
  error?: string;
}

export interface TimeSeriesResponse {
  success: boolean;
  data?: TimeSeriesData[];
  error?: string;
}

// Create axios instance with auth headers
const api = axios.create({
  baseURL: API_BASE_URL
});

// Add request interceptor to include auth token
api.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

/**
 * Service for interacting with the time series API
 */
const timeSeriesService = {
  /**
   * Get all available sensors grouped by type
   * @returns {Promise<SensorsResponse>} - Sensors grouped by type
   */
  async getSensors(): Promise<SensorsResponse> {
    try {
      console.log('Fetching sensors from:', `${API_BASE_URL}${API_PREFIX}/time-series/sensors`);
      
      // Return mock data for development if there's no authentication
      if (!getToken()) {
        console.log('No authentication token found, using mock data');
        return {
          success: true,
          data: MOCK_SENSORS
        };
      }
      
      const response = await api.get(`${API_PREFIX}/time-series/sensors`);
      
      // Check if the request was successful
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch sensors');
      }

      // Transform the response data to match our interface
      const transformedData: { [key: string]: Sensor[] } = {};

      if (response.data.success && response.data.data) {
        // Dynamic sensor types from backend
        Object.entries(response.data.data).forEach(([type, sensors]) => {
          transformedData[type] = (sensors as any[]).map(sensor => ({
            id: sensor.id,
            name: sensor.name || sensor.id,
            type: type
          }));
        });
      }

      console.log('Transformed sensor data:', transformedData);
      return {
        success: true,
        data: transformedData
      };
    } catch (error) {
      console.error('Error fetching sensors:', error);
      
      // If there's an authentication error, use mock data
      if (axios.isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 403)) {
        console.log('Authentication error, using mock data');
        return {
          success: true,
          data: MOCK_SENSORS
        };
      }
      
      return {
        success: false,
        error: error instanceof Error ? error.message : 'Failed to fetch sensors'
      };
    }
  },

  /**
   * Perform aggregation on time series data
   * @param {TimeSeriesData[]} data - Raw time series data
   * @param {TimeSeriesParams} params - Query parameters with aggregation settings
   * @returns {TimeSeriesData[]} - Aggregated time series data
   */
  performAggregation(data: TimeSeriesData[], params: TimeSeriesParams): TimeSeriesData[] {
    const { aggregation, startTime } = params;
    const endTime = params.endTime || new Date();
    
    // If no aggregation is specified or level is 'none', return the raw data
    if (!aggregation || aggregation.level === 'none') {
      return data;
    }

    console.log('Performing frontend aggregation with level:', aggregation.level, 'method:', aggregation.method);

    // Import moment-timezone
    const moment = require('moment-timezone');
    const pacificTz = 'America/Los_Angeles';

    // Create a map to hold the aggregated data by time bucket
    const buckets: { [bucket: string]: TimeSeriesData[] } = {};
    
    // Process each data point
    data.forEach(point => {
      // Convert timestamp to Pacific time
      const pointTime = moment.utc(point.timestamp).tz(pacificTz);
      let bucketTime: string;
      
      // Determine bucket key based on aggregation level
      switch (aggregation.level) {
        case 'day':
          // Day level - use YYYY-MM-DD format
          bucketTime = pointTime.format('YYYY-MM-DD');
          break;
        case 'hour':
          // Hour level - use YYYY-MM-DD HH format (hour precision)
          bucketTime = pointTime.format('YYYY-MM-DD HH');
          break;
        case 'week':
          // Week level - use YYYY-[W]WW format (week number)
          bucketTime = pointTime.format('YYYY-[W]WW');
          break;
        case 'minute':
          // Minute level - use YYYY-MM-DD HH:mm format
          bucketTime = pointTime.format('YYYY-MM-DD HH:mm');
          break;
        case '5minutes':
          // Round down to nearest 5 minutes
          const minute5 = Math.floor(pointTime.minute() / 5) * 5;
          bucketTime = pointTime.clone().minute(minute5).second(0).format('YYYY-MM-DD HH:mm');
          break;
        case '15minutes':
          // Round down to nearest 15 minutes
          const minute15 = Math.floor(pointTime.minute() / 15) * 15;
          bucketTime = pointTime.clone().minute(minute15).second(0).format('YYYY-MM-DD HH:mm');
          break;
        case '30minutes':
          // Round down to nearest 30 minutes
          const minute30 = Math.floor(pointTime.minute() / 30) * 30;
          bucketTime = pointTime.clone().minute(minute30).second(0).format('YYYY-MM-DD HH:mm');
          break;
        default:
          // For any other level, just use the default moment format
          bucketTime = pointTime.format();
      }
      
      // Create bucket if it doesn't exist
      if (!buckets[bucketTime]) {
        buckets[bucketTime] = [];
      }
      
      // Add point to bucket
      buckets[bucketTime].push(point);
    });
    
    console.log(`Created ${Object.keys(buckets).length} buckets for aggregation level ${aggregation.level}`);
    
    // Special handling for flow data - ensure all buckets in range exist
    const isFlowData = data.length > 0 && 
      (data[0].gallons !== undefined || data[0].cuft !== undefined || data[0].pulses !== undefined);
      
    if (isFlowData && (aggregation.level === 'day' || aggregation.level === 'hour')) {
      console.log(`Ensuring all buckets exist for flow data with ${aggregation.level} aggregation`);
      
      const startMoment = moment.utc(startTime).tz(pacificTz);
      const endMoment = moment.utc(endTime).tz(pacificTz);
      
      if (aggregation.level === 'day') {
        // Fill in missing days
        for (let day = startMoment.clone().startOf('day'); 
             day.isSameOrBefore(endMoment, 'day'); 
             day.add(1, 'day')) {
            
          const bucketTime = day.format('YYYY-MM-DD');
          if (!buckets[bucketTime]) {
            console.log(`Creating missing day bucket for ${bucketTime}`);
            buckets[bucketTime] = [];
          }
        }
      } else if (aggregation.level === 'hour') {
        // Fill in missing hours
        for (let hour = startMoment.clone().startOf('hour'); 
             hour.isSameOrBefore(endMoment, 'hour'); 
             hour.add(1, 'hour')) {
            
          const bucketTime = hour.format('YYYY-MM-DD HH');
          if (!buckets[bucketTime]) {
            console.log(`Creating missing hour bucket for ${bucketTime}`);
            buckets[bucketTime] = [];
          }
        }
      }
    }

    // Aggregate data in each bucket
    const result: TimeSeriesData[] = [];
    
    // Process each bucket
    Object.entries(buckets).forEach(([bucketTime, points]) => {
      if (points.length === 0) {
        // For flow data, create a default point for empty buckets
        if (isFlowData) {
          let timestamp: string;
          
          // Create a proper ISO timestamp based on the bucket
          if (aggregation.level === 'day') {
            // For day level, use midnight
            timestamp = moment.tz(bucketTime, 'YYYY-MM-DD', pacificTz).toISOString();
          } else if (aggregation.level === 'hour') {
            // For hour level, use the start of the hour
            timestamp = moment.tz(`${bucketTime}:00:00`, 'YYYY-MM-DD HH:mm:ss', pacificTz).toISOString();
          } else {
            // Default case
            timestamp = moment.tz(bucketTime, pacificTz).toISOString();
          }
          
          // Create default point
          result.push({
            timestamp,
            value: 0,
            gallons: 0,
            cuft: 0,
            pulses: 0,
            unit: data[0].unit || 'gpm'
          });
        }
        return;
      }
      
      // Create aggregated data point with the correct timestamp
      let timestamp: string;
      
      // Set the timestamp based on aggregation level
      if (aggregation.level === 'day') {
        // For day level, use midnight
        timestamp = moment.tz(bucketTime, 'YYYY-MM-DD', pacificTz).toISOString();
      } else if (aggregation.level === 'hour') {
        // For hour level, use the start of the hour
        timestamp = moment.tz(`${bucketTime}:00:00`, 'YYYY-MM-DD HH:mm:ss', pacificTz).toISOString();
      } else {
        // Default case - use the bucket time as is
        timestamp = moment.tz(bucketTime, pacificTz).toISOString();
      }
      
      // Initialize aggregated point
      const aggregatedPoint: TimeSeriesData = {
        timestamp,
        value: 0
      };
      
      // Use the unit from the first point (if available)
      if (points[0].unit) {
        aggregatedPoint.unit = points[0].unit;
      }
      
      // Aggregate all numeric fields (value, percent_full, gallons, cuft, pulses)
      const numericFields = ['value', 'percent_full', 'gallons', 'cuft', 'pulses'];
      
      numericFields.forEach(field => {
        // Skip fields that don't exist in any point
        if (!points.some(p => p[field as keyof TimeSeriesData] !== undefined)) {
          return;
        }
        
        // Get all values for this field
        const values = points
          .map(p => p[field as keyof TimeSeriesData] as number)
          .filter(v => v !== undefined && !isNaN(v));
        
        if (values.length === 0) return;
        
        // Calculate aggregated value based on method
        let result: number;
        switch (aggregation.method) {
          case 'avg':
            result = values.reduce((sum, val) => sum + val, 0) / values.length;
            break;
          case 'sum':
            result = values.reduce((sum, val) => sum + val, 0);
            break;
          case 'min':
            result = Math.min(...values);
            break;
          case 'max':
            result = Math.max(...values);
            break;
          default:
            result = values[0]; // Default to first value
        }
        
        // Assign the result to the correct field
        switch (field) {
          case 'value':
            aggregatedPoint.value = result;
            break;
          case 'percent_full':
            aggregatedPoint.percent_full = result;
            break;
          case 'gallons':
            aggregatedPoint.gallons = result;
            break;
          case 'cuft':
            aggregatedPoint.cuft = result;
            break;
          case 'pulses':
            aggregatedPoint.pulses = result;
            break;
        }
      });
      
      // Add aggregated point to result
      result.push(aggregatedPoint);
    });
    
    console.log(`Aggregated ${data.length} points into ${result.length} points for level ${aggregation.level}`);
    
    // If we ended up with no aggregated points but have flow data, create a default point
    if (result.length === 0 && isFlowData) {
      console.warn('No data points after aggregation - creating default point');
      
      // Create a default timestamp at start time
      const timestamp = moment.utc(startTime).tz(pacificTz).toISOString();
      
      // Add a default point
      result.push({
        timestamp,
        value: 0,
        gallons: 0,
        cuft: 0,
        pulses: 0,
        unit: data[0].unit || 'gpm'
      });
    }
    
    // Sort by timestamp and return
    return result.sort((a, b) => 
      moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
    );
  },

  /**
   * Get time series data for a specific sensor
   * @param {TimeSeriesParams} params - Query parameters
   * @returns {Promise<TimeSeriesResponse>} - Time series data
   */
  async getTimeSeriesData(params: TimeSeriesParams): Promise<TimeSeriesResponse> {
    try {
      console.log('Fetching time series data with params:', params);
      console.log('🔷 API BASE URL:', API_BASE_URL);
      
      // Generate mock data if no authentication or for development
      if (!getToken()) {
        console.log('No authentication token found, using mock data');
        return this.generateMockTimeSeriesData(params);
      }
      
      let response;
      let fullUrl = '';
      
      // Choose endpoint based on whether endTime is provided
      if (params.endTime) {
        // Use original endpoint if endTime is provided
        fullUrl = `${API_BASE_URL}${API_PREFIX}/time-series/data`;
        console.log('🔷 Connecting to:', fullUrl);
        
        response = await api.get(`${API_PREFIX}/time-series/data`, {
          params: {
            sensorType: params.sensorType,
            sensorId: params.sensorId,
            startTime: params.startTime.toISOString(),
            endTime: params.endTime.toISOString(),
            aggregationLevel: params.aggregation?.level || 'none',
            aggregationMethod: params.aggregation?.method || 'avg'
          }
        });
      } else {
        // Use data-since endpoint if endTime is not provided
        fullUrl = `${API_BASE_URL}${API_PREFIX}/time-series/data-since`;
        console.log('🔷 Connecting to:', fullUrl);
        
        response = await api.get(`${API_PREFIX}/time-series/data-since`, {
          params: {
            sensorType: params.sensorType,
            sensorId: params.sensorId,
            startTime: params.startTime.toISOString(),
            aggregationLevel: params.aggregation?.level || 'none',
            aggregationMethod: params.aggregation?.method || 'avg'
          }
        });
      }

      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch time series data');
      }

      // Transform the response data to match our interface
      console.log('Raw response data:', response.data.data);
      
      const transformedData: TimeSeriesData[] = response.data.data.map((item: any) => {
        // Check if we need to access values from payload (backend MongoDB data structure)
        // or if the backend already projected the values at the top level
        const rawValue = item.payload?.value !== undefined ? item.payload.value : item.value;
        const rawUnit = item.payload?.unit !== undefined ? item.payload.unit : item.unit;
        const rawPercentFull = item.payload?.percent_full !== undefined ? item.payload.percent_full : item.percent_full;
        const rawGallons = item.payload?.gallons !== undefined ? item.payload.gallons : item.gallons;
        const rawCuft = item.payload?.cuft !== undefined ? item.payload.cuft : item.cuft;
        const rawPulses = item.payload?.pulses !== undefined ? item.payload.pulses : item.pulses;
        
        // Base properties that should always exist
        const result: TimeSeriesData = {
          timestamp: item.timestamp,
          value: rawValue ?? 0
        };
        
        // Add additional properties if they exist
        if (rawUnit !== undefined) result.unit = rawUnit;
        if (rawPercentFull !== undefined) result.percent_full = rawPercentFull;
        if (rawGallons !== undefined) result.gallons = rawGallons;
        if (rawCuft !== undefined) result.cuft = rawCuft;
        if (rawPulses !== undefined) result.pulses = rawPulses;
        
        return result;
      });

      console.log('Transformed data before aggregation:', transformedData);
      
      // Apply frontend aggregation
      const aggregatedData = this.performAggregation(transformedData, params);
      console.log('Data after aggregation:', aggregatedData);
      
      return {
        success: true,
        data: aggregatedData
      };
    } catch (error) {
      console.error('Error fetching time series data:', error);
      
      // If there's an authentication error, use mock data
      if (axios.isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 403)) {
        console.log('Authentication error, using mock data');
        return this.generateMockTimeSeriesData(params);
      }
      
      return {
        success: false,
        error: error instanceof Error ? error.message : 'Failed to fetch time series data'
      };
    }
  },

  /**
   * Generate mock time series data for development
   * @param {TimeSeriesParams} params - Query parameters
   * @returns {TimeSeriesResponse} - Generated mock time series data
   */
  generateMockTimeSeriesData(params: TimeSeriesParams): TimeSeriesResponse {
    const { startTime, sensorType, sensorId } = params;
    const endTime = params.endTime || new Date(); // Use current time if endTime is not provided
    
    console.log('Generating mock data with params:', {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      sensorType,
      sensorId,
      aggregation: params.aggregation
    });
    
    const mockData: TimeSeriesData[] = [];
    
    // Calculate time range in hours
    const timeRangeHours = Math.ceil((endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60));
    console.log(`Generating data for ${timeRangeHours} hours`);
    
    // Number of data points - one per hour or every 5 minutes for high-resolution
    // For demo purposes, we should generate sufficient data points to show the aggregation working correctly
    const isHighResolution = timeRangeHours < 24; // If less than a day, use high resolution
    
    // Special handling for day-level aggregation to ensure enough data points throughout the day
    const isDayAggregation = params.aggregation?.level === 'day';
    
    // Adjust data points and time step based on aggregation level
    let dataPoints;
    let timeStep;
    
    if (isDayAggregation) {
      // For day-level aggregation, create more points per day (24 points per day)
      const daysInRange = Math.ceil(timeRangeHours / 24);
      dataPoints = daysInRange * 24; // 24 points per day
      timeStep = 60 * 60 * 1000; // 1 hour in ms
      console.log(`Day-level aggregation: creating ${dataPoints} points across ${daysInRange} days`);
    } else if (isHighResolution) {
      dataPoints = timeRangeHours * 12; // 5 min intervals for high res
      timeStep = 5 * 60 * 1000; // 5 min
    } else {
      dataPoints = timeRangeHours; // 1 hour intervals
      timeStep = 60 * 60 * 1000; // 1 hour
    }
    
    // Base value and amplitude for random data generation
    let baseValue = 50;
    let amplitude = 10;
    
    // Create more realistic data based on sensor type and ID
    if (sensorId === 'WaterMonitor/BB/water_depth_1') {
      console.log('Generating specific data for WaterMonitor/BB/water_depth_1');
      baseValue = 75; // 75% full for the Barn Booster tank
      amplitude = 15; // 15% fluctuation
    } else if (sensorId.includes('water_depth')) {
      baseValue = 60; // 60% full for other water depth sensors
      amplitude = 20; // 20% fluctuation for water levels
    } else if (sensorId.includes('pressure')) {
      baseValue = 45; // 45 PSI for pressure sensors
      amplitude = 5; // 5 PSI fluctuation
    } else if (sensorId.includes('flow')) {
      baseValue = 20; // 20 GPM for flow sensors
      amplitude = 8; // 8 GPM fluctuation
    }
    
    console.log(`Using baseValue: ${baseValue}, amplitude: ${amplitude} for ${sensorId}`);
    
    // Generate random data points with realistic patterns
    for (let i = 0; i < dataPoints; i++) {
      const timestamp = new Date(startTime.getTime() + i * timeStep).toISOString();
      
      // Create a sine wave pattern with some randomness
      const time = i / (isHighResolution ? (12 * 24) : 24); // Adjust cycle based on resolution
      const sinValue = Math.sin(time * Math.PI * 2);
      const randomness = Math.random() * 0.3 - 0.15; // ±15% randomness
      
      // Calculate value with sine wave pattern + randomness
      const value = baseValue + sinValue * amplitude + randomness * amplitude;
      
      // Base data point with value property
      const dataPoint: TimeSeriesData = {
        timestamp,
        value: Number(value.toFixed(2))
      };
      
      // Add sensor-specific properties
      if (sensorType === SENSOR_TYPES.WATER_DEPTH) {
        dataPoint.unit = 'feet';
        dataPoint.percent_full = Number((value * 1.33).toFixed(2)); // 100% = 75 units
      } else if (sensorType === SENSOR_TYPES.PRESSURE) {
        dataPoint.unit = 'psi';
      } else if (sensorType === SENSOR_TYPES.WATER_FLOW) {
        dataPoint.unit = 'gpm';
        dataPoint.gallons = Number((value * i * 10).toFixed(2)); // Accumulated gallons
        dataPoint.cuft = Number((value * i * 1.3).toFixed(2)); // Accumulated cubic feet
        dataPoint.pulses = Math.floor(value * i * 5); // Accumulated pulses
        
        // Make sure these fields are always populated for flow sensors
        // as charts may be configured to display them
        if (dataPoint.gallons === 0) dataPoint.gallons = 0.01;
        if (dataPoint.cuft === 0) dataPoint.cuft = 0.01;
        if (dataPoint.pulses === 0) dataPoint.pulses = 1;
      }
      
      mockData.push(dataPoint);
    }

    console.log(`Generated ${mockData.length} mock data points`);
    
    // Special handling for flow data with day-level aggregation
    if (sensorType === SENSOR_TYPES.WATER_FLOW && (params.aggregation?.level === 'day' || params.aggregation?.level === 'hour')) {
      console.log(`Applying special handling for flow data with ${params.aggregation.level}-level aggregation`);
      
      // Ensure we have data points aligned to the appropriate boundaries
      const moment = require('moment-timezone');
      const pacificTz = 'America/Los_Angeles';
      
      if (params.aggregation.level === 'day') {
        // Get unique days in the time range
        const startDay = moment(startTime).tz(pacificTz).startOf('day');
        const endDay = moment(endTime).tz(pacificTz).endOf('day');
        
        // Make sure each day has at least one data point
        for (let day = startDay.clone(); day.isSameOrBefore(endDay); day.add(1, 'day')) {
          const dayStr = day.format('YYYY-MM-DD');
          const hasDataForDay = mockData.some(point => {
            const pointDay = moment(point.timestamp).tz(pacificTz).format('YYYY-MM-DD');
            return pointDay === dayStr;
          });
          
          // If no data for this day, add a data point at noon
          if (!hasDataForDay) {
            console.log(`Adding missing data point for day ${dayStr}`);
            const noonTimestamp = day.clone().add(12, 'hours').toISOString();
            
            // Create data point with reasonable values
            const value = baseValue + (Math.random() * amplitude * 0.5);
            mockData.push({
              timestamp: noonTimestamp,
              value: Number(value.toFixed(2)),
              unit: 'gpm',
              gallons: Number((value * 1000).toFixed(2)),
              cuft: Number((value * 130).toFixed(2)),
              pulses: Math.floor(value * 50)
            });
          }
        }
        
        console.log(`After ensuring data for each day: ${mockData.length} mock data points`);
      } 
      else if (params.aggregation.level === 'hour') {
        // Get hour boundaries in the time range
        const startHour = moment(startTime).tz(pacificTz).startOf('hour');
        const endHour = moment(endTime).tz(pacificTz).endOf('hour');
        
        // Make sure each hour has at least one data point
        for (let hour = startHour.clone(); hour.isSameOrBefore(endHour); hour.add(1, 'hour')) {
          const hourStr = hour.format('YYYY-MM-DD HH:00:00');
          const hasDataForHour = mockData.some(point => {
            const pointHour = moment(point.timestamp).tz(pacificTz).format('YYYY-MM-DD HH');
            const compareHour = hour.format('YYYY-MM-DD HH');
            return pointHour === compareHour;
          });
          
          // If no data for this hour, add a data point at the half-hour mark
          if (!hasDataForHour) {
            console.log(`Adding missing data point for hour ${hourStr}`);
            const halfHourTimestamp = hour.clone().add(30, 'minutes').toISOString();
            
            // Create data point with reasonable values
            const value = baseValue + (Math.random() * amplitude * 0.5);
            mockData.push({
              timestamp: halfHourTimestamp,
              value: Number(value.toFixed(2)),
              unit: 'gpm',
              gallons: Number((value * 100).toFixed(2)),
              cuft: Number((value * 13).toFixed(2)),
              pulses: Math.floor(value * 20)
            });
          }
        }
        
        console.log(`After ensuring data for each hour: ${mockData.length} mock data points`);
      }
    }
    
    // Apply the same aggregation logic to mock data as real data
    const aggregatedData = this.performAggregation(mockData, params);
    console.log(`After aggregation: ${aggregatedData.length} data points`);
    
    if (aggregatedData.length === 0) {
      console.warn('No data points after aggregation! Check the aggregation logic.');
      
      // Return raw mock data if aggregation produced no results
      return {
        success: true,
        data: mockData
      };
    }
    
    return {
      success: true,
      data: aggregatedData
    };
  }
};

export default timeSeriesService;

// Add MOCK_SENSORS definition to support the exact sensors being used
const MOCK_SENSORS = {
  WATER_DEPTH: [
    { id: 'WaterMonitor/BB/water_depth_1', name: 'Water Level Barn Booster', type: 'WATER_DEPTH' },
    { id: 'WaterMonitor/MT/water_depth_1', name: 'Main Tank Level', type: 'WATER_DEPTH' },
    { id: 'WaterMonitor/WL/water_depth_1', name: 'Water Level Sensor 1', type: 'WATER_DEPTH' }
  ],
  PRESSURE: [
    { id: 'WaterMonitor/BB/pressure_1', name: 'Pressure Barn Booster', type: 'PRESSURE' },
    { id: 'WaterMonitor/ML/pressure_1', name: 'Main Line Pressure', type: 'PRESSURE' }
  ],
  WATER_FLOW: [
    { id: 'WaterMonitor/BB/flow_1', name: 'Flow Barn Booster', type: 'WATER_FLOW' },
    { id: 'WaterMonitor/ML/flow_1', name: 'Main Line Flow', type: 'WATER_FLOW' }
  ]
}; 