import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { useAuth } from '../context/AuthContext';

const SplashPage: React.FC = () => {
  // Get authentication state from context
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();

  // Redirect to dashboard if already authenticated
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, isLoading, navigate]);

  return (
    <div className="splash-container unauthenticated">
      {/* Center Logo - always visible */}
      <div className="center-logo">
        <img 
          src="/gr_logo.png" 
          alt="GR Logo" 
          className="gr-logo-image" 
        />
      </div>
      
      {/* Login/Register buttons */}
      <div className="splash-actions">
        <button 
          className="splash-button login-button"
          onClick={() => navigate('/login')}
        >
          Login
        </button>
        <button 
          className="splash-button register-button"
          onClick={() => navigate('/register')}
        >
          Register
        </button>
      </div>
    </div>
  );
};

export default SplashPage; 