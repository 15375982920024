import React, { useState, useEffect } from 'react';
import { userApi } from '../../services/api';
import { activityApi } from '../../services/api';
import '../../App.css';
import PermissionsManager from '../../components/PermissionsManager';
import usePermissions from '../../hooks/usePermissions';
import { USER_PERMISSIONS } from '../../constants/permissions';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';

// User interface (matches the User interface in AuthContext)
interface User {
  _id: string;
  username: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role: string;
  permissions?: string[];
}

// Activity interface
interface Activity {
  _id: string;
  userId: string;
  targetUserId?: string;
  action: string;
  details: any;
  ipAddress?: string;
  userAgent?: string;
  timestamp: string;
  user?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
  };
  targetUser?: {
    _id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    username: string;
  };
}

// Form data interface for editing
interface UserFormData {
  username: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role: string;
}

const AdminUsers: React.FC = () => {
  const { user: currentUser } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formData, setFormData] = useState<UserFormData | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'profile' | 'permissions' | 'activities'>('profile');
  const { hasPermission } = usePermissions();
  const canEditUsers = hasPermission(USER_PERMISSIONS.EDIT_USER);
  const canViewActivities = hasPermission(USER_PERMISSIONS.VIEW_ACTIVITY_LOGS);
  const canCreateUsers = hasPermission(USER_PERMISSIONS.CREATE_USER);

  // Activities state
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activitiesLoading, setActivitiesLoading] = useState<boolean>(false);
  const [activitiesError, setActivitiesError] = useState<string | null>(null);

  // Check if user has permission to edit permissions
  const canManagePermissions = hasPermission(USER_PERMISSIONS.MANAGE_PERMISSIONS);

  // Fetch all users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await userApi.getAllUsers();
        setUsers(response.data.users);
        
        // Select the first user by default if available
        if (response.data.users.length > 0) {
          setSelectedUser(response.data.users[0]);
        }
      } catch (err) {
        console.error('Failed to fetch users:', err);
        setError('Failed to load users. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Handle user selection
  const handleUserSelect = (user: User) => {
    // If editing, prompt to save or discard changes
    if (isEditing && formData && selectedUser) {
      if (window.confirm('You have unsaved changes. Do you want to discard them?')) {
        setSelectedUser(user);
        setIsEditing(false);
        setFormData(null);
      }
    } else {
      setSelectedUser(user);
      // Reset edit state when selecting a different user
      setIsEditing(false);
      setFormData(null);
      setSuccessMessage(null);
    }
  };

  // Handle clicking edit button
  const handleEditClick = () => {
    // Only allow editing if user has permission
    if (!canEditUsers) return;
    
    if (selectedUser) {
      setFormData({
        username: selectedUser.username,
        email: selectedUser.email,
        firstName: selectedUser.firstName || '',
        lastName: selectedUser.lastName || '',
        role: selectedUser.role
      });
      setIsEditing(true);
      setSuccessMessage(null);
    }
  };

  // Handle form field changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (formData) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  // Handle save
  const handleSave = async () => {
    // Verify user has edit permission before saving
    if (!canEditUsers) {
      setError('You do not have permission to edit users');
      return;
    }

    if (!selectedUser || !formData) return;
    
    try {
      setSaveLoading(true);
      setError(null);
      
      // Create update data object with all fields
      const updateData = {
        username: formData.username,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        // Only include role if current user is admin
        ...(currentUser?.role === 'admin' && { role: formData.role })
      };
      
      // Log values for debugging
      console.log('About to update user:', selectedUser._id);
      console.log('Current user data:', selectedUser);
      console.log('Update payload:', JSON.stringify(updateData, null, 2));
      
      // Directly update all user fields including role in a single call
      const response = await userApi.updateUser(selectedUser._id, updateData);
      
      console.log('User update response:', response);
      console.log('Response data:', response.data);
      
      // Fetch the updated user to verify changes
      const userResponse = await userApi.getUserById(selectedUser._id);
      const updatedUser = userResponse.data.user;
      console.log('Get user after update:', updatedUser);
      
      // Check if role was actually updated (only applies to admin users)
      if (currentUser?.role === 'admin' && formData.role !== updatedUser.role) {
        console.warn('Role was not updated in the response!', {
          requested: formData.role,
          received: updatedUser.role
        });
        setError(`Warning: Role change to ${formData.role} was not saved. Current role is ${updatedUser.role}. This might indicate the backend is not processing the role field.`);
      } else {
        setSuccessMessage('User profile updated successfully!');
        // Hide success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      }
      
      // Update the user in the users list
      setUsers(users.map(u => u._id === updatedUser._id ? updatedUser : u));
      setSelectedUser(updatedUser);
      setIsEditing(false);
      setFormData(null);
    } catch (err) {
      console.error('Failed to update user:', err);
      setError('Failed to update user. Please try again.');
    } finally {
      setSaveLoading(false);
    }
  };

  // Handle cancel
  const handleCancel = () => {
    setIsEditing(false);
    setFormData(null);
    setError(null);
    setSuccessMessage(null);
  };

  // Handle permissions updated
  const handlePermissionsUpdated = (newPermissions: string[]) => {
    if (selectedUser) {
      // Update the selected user with new permissions
      const updatedUser = { ...selectedUser, permissions: newPermissions };
      setSelectedUser(updatedUser);
      
      // Update the user in the users array
      setUsers(users.map(u => u._id === updatedUser._id ? updatedUser : u));
    }
  };

  // Fetch user activities
  const fetchUserActivities = async (userId: string) => {
    if (!canViewActivities) return;
    
    try {
      setActivitiesLoading(true);
      setActivitiesError(null);
      
      const response = await activityApi.getUserActivities(userId, {
        page: 1,
        limit: 10
      });
      
      setActivities(response.data.activities);
    } catch (err) {
      console.error('Failed to fetch user activities:', err);
      setActivitiesError('Failed to load activities. Please try again later.');
    } finally {
      setActivitiesLoading(false);
    }
  };

  // When user is selected or tab changes to activities, fetch activities
  useEffect(() => {
    if (selectedUser && activeTab === 'activities') {
      fetchUserActivities(selectedUser._id);
    }
  }, [selectedUser, activeTab, canViewActivities]);

  // Format date for display
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Format action for display
  const formatAction = (action: string) => {
    // Get activity types from API
    const activityTypes = activityApi.getActivityTypes();
    
    // Find matching activity type
    const activityType = activityTypes.find(type => type.value === action);
    if (activityType) {
      return activityType.label;
    }
    
    // Fallback to the original formatting if not found in activity types
    return action
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div style={{ padding: '20px' }}>
      {error && <div style={{ color: 'red', marginBottom: '20px', backgroundColor: 'rgba(255,0,0,0.1)', padding: '10px', borderRadius: '4px' }}>{error}</div>}
      {successMessage && <div style={{ color: 'green', marginBottom: '20px', backgroundColor: 'rgba(0,255,0,0.1)', padding: '10px', borderRadius: '4px' }}>{successMessage}</div>}
      
      <div style={{ 
        display: 'flex', 
        backgroundColor: '#1a1a1a',
        border: '1px solid #333',
        borderRadius: '8px',
        overflow: 'hidden',
        minHeight: 'calc(100vh - 160px)'
      }}>
        {/* Left column - User list */}
        <div style={{ 
          width: '250px', 
          borderRight: '1px solid #333',
          overflow: 'auto',
          padding: '10px 0'
        }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            padding: '0 16px 12px 16px',
            borderBottom: '1px solid #333'
          }}>
            <h3 style={{ margin: 0, color: '#e4c87e' }}>Users</h3>
            {canCreateUsers && (
              <Link to="/admin/users/add" style={{
                background: '#e4c87e',
                border: 'none',
                borderRadius: '4px',
                padding: '0.3rem 0.6rem',
                color: '#1a1a1a',
                cursor: 'pointer',
                fontWeight: '500',
                fontSize: '0.8rem',
                textDecoration: 'none',
                display: 'inline-block'
              }}>
                Add User
              </Link>
            )}
          </div>
          {loading ? (
            <div style={{ padding: '20px', textAlign: 'center' }}>Loading users...</div>
          ) : (
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {users.map(user => (
                <li 
                  key={user._id}
                  onClick={() => handleUserSelect(user)}
                  style={{
                    padding: '12px 16px',
                    cursor: 'pointer',
                    backgroundColor: selectedUser?._id === user._id ? '#333' : 'transparent',
                    borderLeft: selectedUser?._id === user._id ? '3px solid #e4c87e' : '3px solid transparent',
                    color: selectedUser?._id === user._id ? '#e4c87e' : '#ddd',
                    transition: 'background-color 0.2s ease',
                  }}
                >
                  <div style={{ fontWeight: 'bold' }}>{user.username}</div>
                  <div style={{ fontSize: '0.8rem', color: '#999' }}>{user.role}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
        
        {/* Right section - User details */}
        <div style={{ flex: 1, padding: '20px' }}>
          {selectedUser ? (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <h2 style={{ color: '#e4c87e', margin: 0 }}>
                  {isEditing ? 'Edit Profile' : `${selectedUser.firstName || selectedUser.username}'s Profile`}
                </h2>
                
                {!isEditing && activeTab === 'profile' && canEditUsers && (
                  <button 
                    onClick={handleEditClick}
                    style={{
                      background: 'transparent',
                      border: '1px solid #e4c87e',
                      borderRadius: '4px',
                      padding: '0.4rem 0.8rem',
                      color: '#e4c87e',
                      cursor: 'pointer',
                      fontWeight: '500',
                      fontSize: '0.9rem'
                    }}
                  >
                    Edit Profile
                  </button>
                )}
              </div>
              
              {/* Tabs for profile, permissions, and activities */}
              <div style={{ marginBottom: '20px', borderBottom: '1px solid #333' }}>
                <button
                  onClick={() => setActiveTab('profile')}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    padding: '10px 15px',
                    cursor: 'pointer',
                    color: activeTab === 'profile' ? '#e4c87e' : '#999',
                    borderBottom: activeTab === 'profile' ? '2px solid #e4c87e' : 'none',
                    marginBottom: '-1px'
                  }}
                >
                  Profile
                </button>
                
                {canManagePermissions && (
                  <button
                    onClick={() => setActiveTab('permissions')}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      padding: '10px 15px',
                      cursor: 'pointer',
                      color: activeTab === 'permissions' ? '#e4c87e' : '#999',
                      borderBottom: activeTab === 'permissions' ? '2px solid #e4c87e' : 'none',
                      marginBottom: '-1px'
                    }}
                  >
                    Permissions
                  </button>
                )}
                
                {canViewActivities && (
                  <button
                    onClick={() => setActiveTab('activities')}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      padding: '10px 15px',
                      cursor: 'pointer',
                      color: activeTab === 'activities' ? '#e4c87e' : '#999',
                      borderBottom: activeTab === 'activities' ? '2px solid #e4c87e' : 'none',
                      marginBottom: '-1px'
                    }}
                  >
                    Activities
                  </button>
                )}
              </div>
              
              {/* Tab content */}
              {activeTab === 'profile' && (
                // Profile content
                isEditing ? (
                  // Editing form
                  <div>
                    <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                      <button 
                        onClick={handleSave}
                        disabled={saveLoading}
                        style={{
                          background: '#e4c87e',
                          border: 'none',
                          borderRadius: '4px',
                          padding: '0.4rem 0.8rem',
                          color: '#1a1a1a',
                          cursor: saveLoading ? 'default' : 'pointer',
                          fontWeight: '500',
                          fontSize: '0.9rem',
                          opacity: saveLoading ? 0.7 : 1
                        }}
                      >
                        {saveLoading ? 'Saving...' : 'Save Changes'}
                      </button>
                      <button 
                        onClick={handleCancel}
                        disabled={saveLoading}
                        style={{
                          background: 'transparent',
                          border: '1px solid #999',
                          borderRadius: '4px',
                          padding: '0.4rem 0.8rem',
                          color: '#999',
                          cursor: saveLoading ? 'default' : 'pointer',
                          fontWeight: '500',
                          fontSize: '0.9rem'
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div style={{ 
                      backgroundColor: '#252525',
                      padding: '20px',
                      borderRadius: '6px',
                      marginBottom: '20px'
                    }}>
                      <table style={{ 
                        width: '100%',
                        borderCollapse: 'collapse'
                      }}>
                        <tbody>
                          {/* Username field */}
                          <tr>
                            <td style={{ padding: '10px 0', width: '120px' }}>
                              <label htmlFor="username" style={{ fontWeight: 'bold' }}>Username</label>
                            </td>
                            <td>
                              <input
                                id="username"
                                type="text"
                                name="username"
                                value={formData?.username || ''}
                                onChange={handleInputChange}
                                style={{
                                  width: '100%',
                                  padding: '8px',
                                  backgroundColor: '#333',
                                  border: '1px solid #444',
                                  borderRadius: '4px',
                                  color: '#ddd'
                                }}
                              />
                            </td>
                          </tr>
                          
                          {/* Email field */}
                          <tr>
                            <td style={{ padding: '10px 0' }}>
                              <label htmlFor="email" style={{ fontWeight: 'bold' }}>Email</label>
                            </td>
                            <td>
                              <input
                                id="email"
                                type="email"
                                name="email"
                                value={formData?.email || ''}
                                onChange={handleInputChange}
                                style={{
                                  width: '100%',
                                  padding: '8px',
                                  backgroundColor: '#333',
                                  border: '1px solid #444',
                                  borderRadius: '4px',
                                  color: '#ddd'
                                }}
                              />
                            </td>
                          </tr>
                          
                          {/* First Name field */}
                          <tr>
                            <td style={{ padding: '10px 0' }}>
                              <label htmlFor="firstName" style={{ fontWeight: 'bold' }}>First Name</label>
                            </td>
                            <td>
                              <input
                                id="firstName"
                                type="text"
                                name="firstName"
                                value={formData?.firstName || ''}
                                onChange={handleInputChange}
                                style={{
                                  width: '100%',
                                  padding: '8px',
                                  backgroundColor: '#333',
                                  border: '1px solid #444',
                                  borderRadius: '4px',
                                  color: '#ddd'
                                }}
                              />
                            </td>
                          </tr>
                          
                          {/* Last Name field */}
                          <tr>
                            <td style={{ padding: '10px 0' }}>
                              <label htmlFor="lastName" style={{ fontWeight: 'bold' }}>Last Name</label>
                            </td>
                            <td>
                              <input
                                id="lastName"
                                type="text"
                                name="lastName"
                                value={formData?.lastName || ''}
                                onChange={handleInputChange}
                                style={{
                                  width: '100%',
                                  padding: '8px',
                                  backgroundColor: '#333',
                                  border: '1px solid #444',
                                  borderRadius: '4px',
                                  color: '#ddd'
                                }}
                              />
                            </td>
                          </tr>
                          
                          {/* Role field */}
                          <tr>
                            <td style={{ padding: '10px 0' }}>
                              <label htmlFor="role" style={{ fontWeight: 'bold' }}>Role</label>
                              {currentUser?.role !== 'admin' && (
                                <span style={{
                                  marginLeft: '6px',
                                  fontSize: '0.7rem',
                                  color: '#999',
                                  fontStyle: 'italic'
                                }}>
                                  (Only admins can change roles)
                                </span>
                              )}
                            </td>
                            <td>
                              <select
                                id="role"
                                name="role"
                                value={formData?.role || 'user'}
                                onChange={handleInputChange}
                                disabled={currentUser?.role !== 'admin'}
                                style={{
                                  width: '100%',
                                  padding: '8px',
                                  backgroundColor: currentUser?.role === 'admin' ? '#333' : '#222',
                                  border: '1px solid #444',
                                  borderRadius: '4px',
                                  color: currentUser?.role === 'admin' ? '#ddd' : '#777',
                                  cursor: currentUser?.role === 'admin' ? 'pointer' : 'not-allowed',
                                  opacity: currentUser?.role === 'admin' ? 1 : 0.8
                                }}
                              >
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  // View profile
                  <div>
                    {!canEditUsers && (
                      <div style={{ 
                        backgroundColor: 'rgba(228, 200, 126, 0.1)', 
                        padding: '10px 15px', 
                        marginBottom: '15px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                      }}>
                        <span style={{ color: '#e4c87e' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>
                        </span>
                        <span style={{ color: '#ddd' }}>
                          You are viewing user details in read-only mode.
                        </span>
                      </div>
                    )}
                    <div style={{ 
                      backgroundColor: '#252525',
                      padding: '20px',
                      borderRadius: '6px',
                      marginBottom: '20px'
                    }}>
                      <table style={{ 
                        width: '100%',
                        borderCollapse: 'collapse'
                      }}>
                        <tbody>
                          <tr>
                            <td style={{ padding: '10px 0', width: '120px', color: '#999' }}>Username</td>
                            <td style={{ padding: '10px 0' }}>{selectedUser.username}</td>
                          </tr>
                          <tr>
                            <td style={{ padding: '10px 0', color: '#999' }}>Email</td>
                            <td style={{ padding: '10px 0' }}>{selectedUser.email}</td>
                          </tr>
                          <tr>
                            <td style={{ padding: '10px 0', color: '#999' }}>First Name</td>
                            <td style={{ padding: '10px 0' }}>{selectedUser.firstName || '-'}</td>
                          </tr>
                          <tr>
                            <td style={{ padding: '10px 0', color: '#999' }}>Last Name</td>
                            <td style={{ padding: '10px 0' }}>{selectedUser.lastName || '-'}</td>
                          </tr>
                          <tr>
                            <td style={{ padding: '10px 0', color: '#999' }}>Role</td>
                            <td style={{ padding: '10px 0' }}>
                              <span style={{ 
                                display: 'inline-block',
                                padding: '4px 8px',
                                backgroundColor: selectedUser.role === 'admin' ? '#e4c87e22' : '#44444422',
                                color: selectedUser.role === 'admin' ? '#e4c87e' : '#ddd',
                                borderRadius: '4px',
                                fontSize: '0.8rem',
                                textTransform: 'uppercase'
                              }}>
                                {selectedUser.role.toUpperCase()}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              )}
              
              {activeTab === 'permissions' && (
                // Permissions content
                <div>
                  <PermissionsManager 
                    userId={selectedUser._id}
                    userPermissions={selectedUser.permissions || []}
                    onPermissionsUpdated={handlePermissionsUpdated}
                    isAdmin={selectedUser.role === 'admin'}
                  />
                </div>
              )}
              
              {activeTab === 'activities' && (
                // Activities content
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <h3 style={{ color: '#e4c87e', margin: 0 }}>Recent Activities</h3>
                    <Link 
                      to={`/admin/activities/user/${selectedUser._id}`}
                      style={{
                        background: 'transparent',
                        border: '1px solid #e4c87e',
                        borderRadius: '4px',
                        padding: '0.4rem 0.8rem',
                        color: '#e4c87e',
                        cursor: 'pointer',
                        fontWeight: '500',
                        fontSize: '0.9rem',
                        textDecoration: 'none',
                        display: 'inline-block'
                      }}
                    >
                      View All Activities
                    </Link>
                  </div>
                  
                  {activitiesLoading ? (
                    <div style={{ padding: '20px', textAlign: 'center' }}>Loading activities...</div>
                  ) : activitiesError ? (
                    <div style={{ color: 'red', marginBottom: '20px', backgroundColor: 'rgba(255,0,0,0.1)', padding: '10px', borderRadius: '4px' }}>
                      {activitiesError}
                    </div>
                  ) : activities.length === 0 ? (
                    <div style={{ padding: '20px', textAlign: 'center', color: '#999' }}>No activities found for this user.</div>
                  ) : (
                    <div className="table-container">
                      <table className="data-table">
                        <thead>
                          <tr>
                            <th>Timestamp</th>
                            <th>Action</th>
                            <th>IP Address</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activities.map(activity => (
                            <tr key={activity._id}>
                              <td>{formatDate(activity.timestamp)}</td>
                              <td>{formatAction(activity.action)}</td>
                              <td>{activity.ipAddress || '-'}</td>
                              <td>
                                {activity.details && Object.keys(activity.details).length > 0 ? (
                                  <details>
                                    <summary>View Details</summary>
                                    <pre>{JSON.stringify(activity.details, null, 2)}</pre>
                                  </details>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: '#999' }}>
              {loading ? 'Loading...' : 'Select a user to view details'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminUsers; 