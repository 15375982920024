import React, { useState, FormEvent, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import api, { invitationApi } from '../services/api';
import {
  validateEmail,
  validateUsername,
  validatePassword,
  validateName,
  validatePasswordConfirmation
} from '../utils/validation';
import '../components/FormStyles.css';

interface InvitationData {
  email: string;
  role: string;
  expiresAt: string;
}

const Register: React.FC = () => {
  const { register, isLoading, setTokenAndLoadUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get invitation token from URL if present
  const queryParams = new URLSearchParams(location.search);
  const invitationToken = queryParams.get('token');
  
  // State for invitation data
  const [invitationData, setInvitationData] = useState<InvitationData | null>(null);
  const [verifyingToken, setVerifyingToken] = useState(invitationToken ? true : false);

  // Form state
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: ''
  });

  // Error messages
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
  const [apiError, setApiError] = useState<string | null>(null);

  // Verify invitation token on component mount if present
  useEffect(() => {
    const verifyToken = async () => {
      if (!invitationToken) return;
      
      try {
        setVerifyingToken(true);
        const response = await api.get(`/api/v1/invitations/verify/${invitationToken}`);
        
        if (response.data.valid) {
          setInvitationData(response.data.invitation);
          // Pre-fill email from invitation
          setFormData(prev => ({ ...prev, email: response.data.invitation.email }));
        } else {
          setApiError('Invalid or expired invitation token.');
        }
      } catch (error: any) {
        setApiError(error.response?.data?.message || 'Invalid invitation token.');
      } finally {
        setVerifyingToken(false);
      }
    };
    
    verifyToken();
  }, [invitationToken]);

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Clear error for this field
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    
    // Clear API error when user starts typing again
    if (apiError) {
      setApiError(null);
    }
  };

  // Validate form
  const validateForm = (): boolean => {
    const newErrors = {
      username: validateUsername(formData.username),
      email: validateEmail(formData.email),
      password: validatePassword(formData.password),
      confirmPassword: validatePasswordConfirmation(formData.password, formData.confirmPassword),
      firstName: validateName(formData.firstName, 'First name'),
      lastName: validateName(formData.lastName, 'Last name'),
    };

    setErrors(newErrors);

    // Check if there are any errors
    return !Object.values(newErrors).some(error => error !== null);
  };

  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setApiError(null);

    // Validate form
    if (!validateForm()) {
      return;
    }

    try {
      if (invitationToken && invitationData) {
        // Register with invitation token
        const response = await api.post('/api/v1/invitations/register', {
          token: invitationToken,
          username: formData.username,
          password: formData.password,
          firstName: formData.firstName || undefined,
          lastName: formData.lastName || undefined
        });
        
        // After successful registration with invitation, manually log the user in
        try {
          const loginResponse = await api.post('/api/v1/users/login', {
            email: invitationData.email,
            password: formData.password
          });
          
          // Use the AuthContext helper to set token and load user
          await setTokenAndLoadUser(loginResponse.data.token);
          
          // Redirect to home page after successful registration and login
          navigate('/');
        } catch (loginError: any) {
          // If login fails after successful registration
          setApiError('Registration successful, but automatic login failed. Please try logging in manually.');
          navigate('/login');
        }
      } else {
        // Regular registration
        await register({
          username: formData.username,
          email: formData.email,
          password: formData.password,
          firstName: formData.firstName || undefined,
          lastName: formData.lastName || undefined
        });
        
        // Redirect to home page after successful registration
        navigate('/');
      }
    } catch (error: any) {
      // Display error message
      setApiError(error.response?.data?.message || 'Registration failed. Please try again.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h1 className="auth-title">Create an Account</h1>
        
        {verifyingToken && <div className="loading-message">Verifying invitation...</div>}
        
        {invitationData && (
          <div className="invitation-info" style={{ marginBottom: '1rem', padding: '0.5rem', backgroundColor: 'rgba(228, 200, 126, 0.1)', borderRadius: '4px' }}>
            <p>You've been invited to join with role: <strong>{invitationData.role}</strong></p>
            <p style={{ color: '#999', fontSize: '0.9rem' }}>
              All invited users start with regular user permissions. Admin privileges can be granted later if needed.
            </p>
            <p>Email: <strong>{invitationData.email}</strong></p>
            <p>This invitation expires on: <strong>{new Date(invitationData.expiresAt).toLocaleString()}</strong></p>
          </div>
        )}
        
        {apiError && <div className="error-message" style={{ marginBottom: '1rem' }}>{apiError}</div>}
        
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label className="form-label" htmlFor="firstName">First Name</label>
              <input
                className="form-input"
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <div className="error-message">{errors.firstName}</div>}
            </div>
            
            <div className="form-group">
              <label className="form-label" htmlFor="lastName">Last Name</label>
              <input
                className="form-input"
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <div className="error-message">{errors.lastName}</div>}
            </div>
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="username">Username*</label>
            <input
              className="form-input"
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
            {errors.username && <div className="error-message">{errors.username}</div>}
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="email">Email*</label>
            <input
              className="form-input"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={!!invitationData} // Disable email field if invited
            />
            {errors.email && <div className="error-message">{errors.email}</div>}
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="password">Password*</label>
            <input
              className="form-input"
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {errors.password && <div className="error-message">{errors.password}</div>}
          </div>
          
          <div className="form-group">
            <label className="form-label" htmlFor="confirmPassword">Confirm Password*</label>
            <input
              className="form-input"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            {errors.confirmPassword && <div className="error-message">{errors.confirmPassword}</div>}
          </div>
          
          <button className="form-button" type="submit" disabled={isLoading || verifyingToken}>
            {isLoading ? 'Registering...' : 'Register'}
          </button>
        </form>
        
        <Link to="/login" className="auth-link">Already have an account? Log in</Link>
      </div>
    </div>
  );
};

export default Register; 